import {
	POSTCARD_BORDER_RADIUS,
	postcardImageUrls,
	PostcardSizeEnum,
	PostcardVariableNames,
} from '@app/constants/modules/postcard';
import { CreativeEditorProps } from '@common/design-system/components/organisms/CreativeEditor/CreativeEditor';
import { getPostcardOverlay } from '@common/design-system/components/organisms/CreativeEditor/utils';
import { StoreType } from 'polotno/model/store';
import { getImageSize } from 'polotno/utils/image';

export function getPostcardStoreJSON(store: StoreType) {
	// We need to only remove custom fonts without url
	// because they'd cause an error when sending the postcard

	const { fonts, ...rest } = store.toJSON();

	const validFonts = fonts.filter((font) => !!font?.url);

	return {
		...rest,
		fonts: validFonts,
	};
}

function resetMergeImageUrlInChild(child: any) {
	const mergeTagHasCloudinarySrc =
		child.src.includes('cloudinary') &&
		child.src.includes('/post-templates/merge-images/');

	if (!mergeTagHasCloudinarySrc) return child;

	if (child?.custom?.variable === PostcardVariableNames.WORKSPACE_LOGO) {
		child.type = 'image';
		child.contentEditable = true;
		child.src = postcardImageUrls.workspaceLogo;
	}

	if (child?.custom?.variable === PostcardVariableNames.VIDEO_THUMBNAIL) {
		child.type = 'image';
		child.contentEditable = true;
		child.src = postcardImageUrls.videoThumbnail;
	}

	if (child?.custom?.variable === PostcardVariableNames.WORKSPACE_BG) {
		child.type = 'image';
		child.contentEditable = true;
		child.src = postcardImageUrls.workspaceBg;
	}

	if (child?.custom?.variable === PostcardVariableNames.QR_CODE) {
		child.type = 'image';
		child.contentEditable = true;
		child.src = postcardImageUrls.qrCode;
	}
}
function transformTemplateMergeTags(
	templateJSON: any,
	format: '{} to []' | '[] to {}',
) {
	const templateDuplicate = JSON.parse(JSON.stringify(templateJSON));

	const pages = templateDuplicate?.pages;

	if (!pages) {
		return templateDuplicate;
	}

	const transform = (child: any) => {
		const allVariableNames = [
			PostcardVariableNames.WORKSPACE_LOGO,
			PostcardVariableNames.VIDEO_THUMBNAIL,
			PostcardVariableNames.WORKSPACE_BG,
			PostcardVariableNames.QR_CODE,
		];

		const text = child?.text;
		const isAText = child?.type === 'text';
		const isAgroup = child?.type === 'group';
		const isChildAMergeTag = allVariableNames.includes(child?.custom?.variable);

		if (!child || ((!text || !isAText) && !isChildAMergeTag)) {
			return child;
		}

		if (isAgroup) {
			const children = child?.children;

			if (!children) {
				return child;
			}

			for (const child of children) {
				transform(child);
			}

			return child;
		}

		// This is to cater for the change in the merge images placeholder urls
		if (isChildAMergeTag) {
			return resetMergeImageUrlInChild(child);
		}

		let resultingText = text;
		if (format === '{} to []') {
			resultingText = text.replace(/\{(.*?)\}/g, '[$1]');
		}

		if (format === '[] to {}') {
			resultingText = text.replace(/\[(.*?)\]/g, '{$1}');
		}
		child.text = resultingText;
	};

	for (const page of pages) {
		const children = page?.children;

		if (!children || !children.length) {
			continue;
		}

		for (const child of children) {
			transform(child);
		}
	}

	return templateDuplicate;
}
export function handleLoadTemplateToStore(store: StoreType, templateJSON: any) {
	const transformedJSON = transformTemplateMergeTags(templateJSON, '{} to []');

	store.loadJSON(transformedJSON);
}

export function handleGetTemplateFromStore(store: StoreType) {
	const storeJSON = getPostcardStoreJSON(store);

	return transformTemplateMergeTags(storeJSON, '[] to {}');
}

function checkChildIsAnOverlay(child: any) {
	return (
		child?.custom?.name === PostcardVariableNames.FRONT_OVERLAY ||
		child?.custom?.name === PostcardVariableNames.BACK_OVERLAY
	);
}
export function checkIsPostcardTemplateEmpty(templateJSON: any) {
	const pages = templateJSON?.pages;

	if (!pages) {
		return true;
	}

	for (const page of pages) {
		const children = page?.children;
		const hasOnlyOverlay =
			children.length === 1 && checkChildIsAnOverlay(children[0]);

		if (!children || !children.length || hasOnlyOverlay) {
			continue;
		}

		return false;
	}

	return true;
}

export function getMergeImageUrl(
	variableName: PostcardVariableNames,
	customMergeImages?: CreativeEditorProps['customMergeImages'],
) {
	let imageUrl = '';

	const customImage =
		customMergeImages && customMergeImages[variableName]
			? customMergeImages[variableName]
			: '';

	if (variableName === PostcardVariableNames.QR_CODE) {
		imageUrl = customImage || postcardImageUrls.qrCode;
	}
	if (variableName === PostcardVariableNames.WORKSPACE_LOGO) {
		imageUrl = customImage || postcardImageUrls.workspaceLogo;
	}
	if (variableName === PostcardVariableNames.VIDEO_THUMBNAIL) {
		imageUrl = customImage || postcardImageUrls.videoThumbnail;
	}
	if (variableName === PostcardVariableNames.WORKSPACE_BG) {
		imageUrl = customImage || postcardImageUrls.workspaceBg;
	}

	return imageUrl;
}
export async function handleAddMergeImageToStore(
	store: StoreType,
	variableName: PostcardVariableNames,
	borderRadiusField: 'round' | 'square',
	customMergeImages?: CreativeEditorProps['customMergeImages'],
) {
	const imageUrl = getMergeImageUrl(variableName, customMergeImages);

	const borderRadius = POSTCARD_BORDER_RADIUS[variableName][borderRadiusField];

	// get image size
	let { width, height } = await getImageSize(imageUrl);
	const aspectRatio = width / height;

	// Scale down the image to fit the canvas
	width = 500;
	height = width / aspectRatio;

	store.activePage.addElement({
		type: 'image',
		src: imageUrl,
		// Place at the center of the canvas
		x: store.width / 2 - width / 2,
		y: store.height / 2 - height / 2,
		custom: {
			variable: variableName,
		},
		width: width,
		height: height,
		cornerRadius: borderRadius,
		// can element be moved and rotated
		draggable: true,
		// can we change content of element?
		contentEditable: true, //
		// can we change style of element?
		styleEditable: false,
		// can we resize element?
		// resizable: true,
	});
}

export function handleAddPagesToStore(store: StoreType, pages?: string[]) {
	if (!store) return;

	pages = pages?.length ? pages : ['Front', 'Back'];

	for (const page of pages) {
		store.addPage({
			custom: {
				name: page || 'Untitled page',
			},
		});
	}
}

export function handleRemoveOverlay(store: StoreType) {
	const ids: string[] = [];
	store.pages.forEach((page) => {
		page.children.forEach((child) => {
			if (
				child.custom?.name === 'front-overlay' ||
				child.custom?.name === 'back-overlay'
			) {
				ids.push(child.id);
			}
		});
	});
	store.deleteElements(ids);
}

export function handleAddOverlay(
	store: StoreType,
	postcardSize: PostcardSizeEnum,
) {
	const pages = store.pages;
	// Remove the existing overlays when changing the size

	const frontPageOverlay = getPostcardOverlay('front', postcardSize);
	const backPageOverlay = getPostcardOverlay('back', postcardSize);

	const commonProps = {
		type: 'image',
		alwaysOnTop: false,
		contentEditable: false,
		draggable: false,
		removable: false,
		resizable: false,
		selectable: false,
		showInExport: false,
		styleEditable: false,
	};

	if (!frontPageOverlay && !backPageOverlay) return;

	handleRemoveOverlay(store);

	for (const page of pages) {
		const isFrontPage = page.custom.name === 'Front';
		const isBackPage = page.custom.name === 'Back';

		if (isFrontPage && !!frontPageOverlay) {
			page.addElement({
				x: 0,
				y: 0,
				width: store.width,
				height: store.height,
				src: frontPageOverlay,
				custom: {
					name: PostcardVariableNames.FRONT_OVERLAY,
				},
				...commonProps,
			});
		}

		if (isBackPage && !!backPageOverlay) {
			page.addElement({
				x: 0,
				y: 0,
				width: store.width,
				height: store.height,
				src: backPageOverlay,
				custom: {
					name: PostcardVariableNames.BACK_OVERLAY,
				},
				...commonProps,
			});
		}
	}

	// Move the overlay to the bottom
	store.pages.forEach((page) => {
		const overlay = page.children.find(
			(child) =>
				child.custom?.name === 'front-overlay' ||
				child.custom?.name === 'back-overlay',
		);

		if (overlay) {
			page.moveElementsBottom([overlay.id]);
		}
	});
}
