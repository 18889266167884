import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import {
	Route,
	Navigate,
	createBrowserRouter,
	RouterProvider,
	createRoutesFromElements,
	Outlet,
} from 'react-router-dom';
import { FeaturesReady, useFeature } from '@growthbook/growthbook-react';
import { growthbookFeatures } from '@app/constants/growthbook';

import GoodkindTheme from '@global/theme';

import Login from '../containers/LoginScreen';
import AuthCallback from '../containers/AuthCallback';

import PrivateRoute from './PrivateRoute';
import { PageRouteEnum, ActivityRoute } from '../constants/pages';
import { LoaderContainer } from './PrivateRoute.styles';
import lazyRetry from '@common/utils/lazyRetry';

import * as S from '../app.styles';

import { WorkspacePlansEnum } from '@app/constants/modules/workspace';
import { NavigationBar } from '@common/design-system/components/organisms';
import { BrowserTitleHandler, Segment } from '@app/services';
import ErrorBoundary from '@common/components/interaction/ErrorBoundary';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@app/redux/types';
import { grantAccess, setAuthProviderInfo } from '@app/redux/auth/action';
import { getWorkspaceInfo } from '@app/redux/workspace/action';
import TokenStorage from '@app/services/Auth/TokenStorage';
import { useAuth } from '@app/services/Auth/useAuth';
import MobileAppRedirect from '@app/containers/MobileAppRedirect/MobileAppRedirect';
import { Loader } from '@common/design-system/components/atoms';

const Home = React.lazy(() => lazyRetry(() => import('../containers/Home')));

const Settings = React.lazy(() =>
	lazyRetry(() => import('../containers/Settings')),
);

const SettingsV2 = React.lazy(() =>
	lazyRetry(() => import('../containers/SettingsV2')),
);

const CampaignReport = React.lazy(() =>
	lazyRetry(() => import('../containers/CampaignReport')),
);

const CampaignsList = React.lazy(() =>
	lazyRetry(() => import('../containers/Campaigns')),
);

const CampaignsListV2 = React.lazy(() =>
	lazyRetry(() => import('../containers/CampaignsV2')),
);

const Audiences = lazy(() =>
	lazyRetry(() => import('../containers/Audiences')),
);

const AudiencesV2 = lazy(() =>
	lazyRetry(() => import('../containers/AudiencesV2')),
);

const ChangeWorkspace = lazy(() =>
	lazyRetry(() => import('../containers/ChangeWorkspace')),
);

const CampaignCreation = lazy(() =>
	lazyRetry(() => import('../containers/CampaignCreation')),
);

const CampaignCreationV2 = lazy(() =>
	lazyRetry(() => import('../containers/CampaignCreationV2')),
);

const VideosV2 = lazy(() => lazyRetry(() => import('../containers/VideosV2')));

const StoryDetail = lazy(() =>
	lazyRetry(() => import('../containers/StoryDetail')),
);

const Activity = lazy(() => lazyRetry(() => import('../containers/Activity')));

const ActivityV2 = lazy(() =>
	lazyRetry(() => import('../containers/ActivityV2')),
);

const Recorders = lazy(() =>
	lazyRetry(() => import('../containers/Recorders')),
);

const AssignedActivity = lazy(() =>
	lazyRetry(() => import('../containers/Activity/containers/AssignedActivity')),
);

const RepliedActivity = lazy(() =>
	lazyRetry(() => import('../containers/Activity/containers/RepliedActivity')),
);

const ScheduledActivity = lazy(() =>
	lazyRetry(
		() => import('../containers/Activity/containers/ScheduledActivity'),
	),
);

const SentActivity = lazy(() =>
	lazyRetry(() => import('../containers/Activity/containers/SentActivity')),
);

const ApprovalActivity = lazy(() =>
	lazyRetry(() => import('../containers/Activity/containers/ApprovalActivity')),
);

const Inbox = lazy(() => lazyRetry(() => import('../containers/Inbox')));

const SuccessPlan = lazy(() =>
	lazyRetry(() => import('../containers/SuccessPlan')),
);

const Chatbot = lazy(() => lazyRetry(() => import('../containers/Chatbot')));

const ChatbotSettings = lazy(() =>
	lazyRetry(() => import('../containers/Chatbot/ChatBotSettings')),
);

const ChatbotList = lazy(() =>
	lazyRetry(
		() => import('../containers/Chatbot/ChatBotsListContainer/ChatbotsList'),
	),
);

const VideoFolder = lazy(() =>
	lazyRetry(() => import('../containers/VideosV2/containers/VideoFolder')),
);

const Teams = lazy(() => lazyRetry(() => import('@app/containers/Teams')));

const NewTeam = lazy(() =>
	lazyRetry(() => import('@app/containers/Teams/NewTeam')),
);

const TeamProfile = lazy(() =>
	lazyRetry(() => import('@app/containers/Teams/TeamProfile')),
);

const TeamSettings = lazy(() =>
	lazyRetry(() => import('@app/containers/Teams/TeamSettings')),
);

const ActivityRoutes = [
	{
		path: ActivityRoute.ASSIGNED,
		component: <AssignedActivity />,
	},
	{
		path: ActivityRoute.APPROVAL,
		component: <ApprovalActivity />,
	},
	{
		path: ActivityRoute.SCHEDULED,
		component: <ScheduledActivity />,
	},
	{
		path: ActivityRoute.REPLIED,
		component: <RepliedActivity />,
	},
	{
		path: ActivityRoute.SENT,
		component: <SentActivity />,
	},
];

function RouteLayout({ isShowingNavBar }: { isShowingNavBar: boolean }) {
	return (
		<>
			<BrowserTitleHandler />
			<Segment />

			<S.Layout>
				{isShowingNavBar && <NavigationBar />}
				<Suspense
					fallback={
						<LoaderContainer>
							<Loader />
						</LoaderContainer>
					}
				>
					<Outlet />
				</Suspense>
			</S.Layout>
		</>
	);
}

type getRouterProps = {
	isShowingNavBar: boolean;
	isNewSettings: boolean;
	isNewCampaignList: boolean;
	isNewActivity: boolean;
	setIsShowingNavBar: (val: boolean) => void;
	isNewAudience: boolean;
};
const getRouter = ({
	isShowingNavBar,
	isNewSettings,
	isNewCampaignList,
	isNewActivity,
	isNewAudience,
	setIsShowingNavBar,
}: getRouterProps) => {
	const SettingsPage = isNewSettings ? SettingsV2 : Settings;

	const CampaignListPage = () => {
		return isNewCampaignList ? (
			<PrivateRoute
				setIsShowingNavBar={setIsShowingNavBar}
				adminRoute
				pageWrapperOverflowHidden
			>
				<CampaignsListV2 />
			</PrivateRoute>
		) : (
			<PrivateRoute setIsShowingNavBar={setIsShowingNavBar} adminRoute>
				<CampaignsList />
			</PrivateRoute>
		);
	};

	const AudiencePage = () => {
		return isNewAudience ? (
			<PrivateRoute
				adminRoute
				setIsShowingNavBar={setIsShowingNavBar}
				pageWrapperOverflowHidden
			>
				<AudiencesV2 />
			</PrivateRoute>
		) : (
			<PrivateRoute setIsShowingNavBar={setIsShowingNavBar} adminRoute>
				<Audiences />
			</PrivateRoute>
		);
	};

	const ActivityPage = () => {
		return isNewActivity ? (
			<PrivateRoute
				setIsShowingNavBar={setIsShowingNavBar}
				adminRoute
				pageWrapperOverflowHidden
				workspacePlans={[
					WorkspacePlansEnum.VIDEO_MESSAGING,
					WorkspacePlansEnum.REELS,
					WorkspacePlansEnum.SMS,
				]}
			>
				<ActivityV2 />
			</PrivateRoute>
		) : (
			<PrivateRoute
				setIsShowingNavBar={setIsShowingNavBar}
				adminRoute
				workspacePlans={[
					WorkspacePlansEnum.VIDEO_MESSAGING,
					WorkspacePlansEnum.REELS,
				]}
			>
				<Activity />
			</PrivateRoute>
		);
	};

	return createBrowserRouter(
		createRoutesFromElements(
			<Route
				errorElement={
					<ErrorBoundary handleReset={() => window.location.reload()} />
				}
				element={<RouteLayout isShowingNavBar={isShowingNavBar} />}
			>
				<Route path="/" element={<Login />} />

				<Route
					path={`/${PageRouteEnum.mobileAppRedirect}`}
					element={<MobileAppRedirect />}
				/>

				<Route
					path={`/${PageRouteEnum.callback}/*`}
					element={<AuthCallback />}
				/>

				<Route
					path={`/${PageRouteEnum.changingWorkspace}/*`}
					element={
						<PrivateRoute setIsShowingNavBar={setIsShowingNavBar}>
							<ChangeWorkspace />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.changingWorkspaceFallback}/*`}
					element={
						<PrivateRoute setIsShowingNavBar={setIsShowingNavBar}>
							<ChangeWorkspace />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.inbox}/*`}
					element={
						<PrivateRoute setIsShowingNavBar={setIsShowingNavBar}>
							<Inbox />
						</PrivateRoute>
					}
				/>
				<Route
					path={`/${PageRouteEnum.inbox}/:teamId/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							pageWrapperOverflowHidden
						>
							<Inbox />
						</PrivateRoute>
					}
				/>
				<Route
					path={`/${PageRouteEnum.inbox}/:teamId/:conversationId/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							pageWrapperOverflowHidden
						>
							<Inbox />
						</PrivateRoute>
					}
				/>

				{/** Teams */}

				<Route
					path={`/${PageRouteEnum.teams}/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute
							workspacePlans={[WorkspacePlansEnum.SMS]}
						>
							<Teams />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.teams}/${PageRouteEnum.newTeam}/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute
							workspacePlans={[WorkspacePlansEnum.SMS]}
						>
							<NewTeam />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.teams}/:teamId/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute
							workspacePlans={[WorkspacePlansEnum.SMS]}
						>
							<TeamProfile />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.teams}/:teamId/settings/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute
							workspacePlans={[WorkspacePlansEnum.SMS]}
						>
							<TeamSettings />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.recorders}/*`}
					element={
						<PrivateRoute setIsShowingNavBar={setIsShowingNavBar} adminRoute>
							<Recorders />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.home}/*`}
					element={
						<PrivateRoute setIsShowingNavBar={setIsShowingNavBar}>
							<Home />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.campaigns}/*`}
					element={<CampaignListPage />}
				/>

				<Route
					path={`/${PageRouteEnum.campaign}/:campaignId/*`}
					element={
						<PrivateRoute setIsShowingNavBar={setIsShowingNavBar} adminRoute>
							<FeaturesReady
								timeout={500}
								fallback={
									<LoaderContainer>
										<Loader
											loading={true}
											color={GoodkindTheme.colors.neutral[200]}
										/>
									</LoaderContainer>
								}
							>
								<CampaignReport />
							</FeaturesReady>
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.audiences}/*`}
					element={<AudiencePage />}
				/>

				<Route
					path={`/${PageRouteEnum.settings}/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute={!isNewSettings} // Old settings page is not accessible by non-admins
						>
							<SettingsPage setIsShowingNavBar={setIsShowingNavBar} />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.campaignCreation}/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute
							workspacePlans={[
								WorkspacePlansEnum.VIDEO_MESSAGING,
								WorkspacePlansEnum.REELS,
							]}
						>
							<CampaignCreation />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.campaignCreationV2}/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute
							workspacePlans={[
								WorkspacePlansEnum.VIDEO_MESSAGING,
								WorkspacePlansEnum.REELS,
							]}
						>
							<CampaignCreationV2 />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.videos}/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute
							workspacePlans={[
								WorkspacePlansEnum.VIDEO_MESSAGING,
								WorkspacePlansEnum.REELS,
							]}
						>
							<VideosV2 />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.videos}/folder/:folderId/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute
							workspacePlans={[
								WorkspacePlansEnum.VIDEO_MESSAGING,
								WorkspacePlansEnum.REELS,
							]}
						>
							<VideoFolder />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.video}/:storyId/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute
							workspacePlans={[
								WorkspacePlansEnum.VIDEO_MESSAGING,
								WorkspacePlansEnum.REELS,
							]}
						>
							<StoryDetail />
						</PrivateRoute>
					}
				/>

				<Route
					path={`/${PageRouteEnum.activity}`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute
							workspacePlans={[
								WorkspacePlansEnum.VIDEO_MESSAGING,
								WorkspacePlansEnum.REELS,
							]}
						>
							<Navigate
								to={
									isNewActivity
										? ActivityRoute.SCHEDULED
										: ActivityRoute.ASSIGNED
								}
							/>
						</PrivateRoute>
					}
				/>

				<Route path={`/${PageRouteEnum.activity}/*`} element={<ActivityPage />}>
					{ActivityRoutes.map(({ path, component }) => (
						<Route
							key={path}
							path={path}
							element={
								<PrivateRoute
									setIsShowingNavBar={setIsShowingNavBar}
									adminRoute
									workspacePlans={[
										WorkspacePlansEnum.VIDEO_MESSAGING,
										WorkspacePlansEnum.REELS,
									]}
								>
									{component}
								</PrivateRoute>
							}
						/>
					))}
				</Route>

				<Route
					path={`/${PageRouteEnum.successPlan}/*`}
					element={
						<PrivateRoute setIsShowingNavBar={setIsShowingNavBar} adminRoute>
							<SuccessPlan />
						</PrivateRoute>
					}
				/>
				<Route
					path={`/${PageRouteEnum.chatbot}/*`}
					element={
						<PrivateRoute setIsShowingNavBar={setIsShowingNavBar} adminRoute>
							<Chatbot />
						</PrivateRoute>
					}
				/>
				<Route
					path={`/${PageRouteEnum.chatbot}/list/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute
							workspacePlans={[WorkspacePlansEnum.CHATBOT]}
						>
							<ChatbotList />
						</PrivateRoute>
					}
				/>
				<Route
					path={`/${PageRouteEnum.chatbot}/:chatbotId/chatbotSettings/*`}
					element={
						<PrivateRoute
							setIsShowingNavBar={setIsShowingNavBar}
							adminRoute
							workspacePlans={[WorkspacePlansEnum.CHATBOT]}
						>
							<ChatbotSettings />
						</PrivateRoute>
					}
				/>

				<Route path="*" element={<Navigate to="/" />} />
			</Route>,
		),
	);
};

export default function Routers(): React.ReactElement {
	const dispatch = useAppDispatch();

	// Switch for settings v1 and v2
	const isNewSettings = useFeature(growthbookFeatures.FRONTEND_SETTINGS_V2).on;

	// Switch for campaign list v1 and v2
	const isNewCampaignList = useFeature(growthbookFeatures.ACTIVITY_V2).on;

	// Switch for audience v1 and v2
	const isNewAudience = useFeature(growthbookFeatures.AUDIENCE_V3).on;

	//Switch for Activity v1 and v2
	const isNewActivity = useFeature(growthbookFeatures.ACTIVITY_V2).on;

	const [isShowingNavBar, setIsShowingNavBar] = useState<boolean>(false);

	const router: any = useMemo(() => {
		return getRouter({
			isShowingNavBar,
			isNewSettings,
			isNewCampaignList,
			isNewAudience,
			isNewActivity,
			setIsShowingNavBar,
		});
	}, [
		isShowingNavBar,
		isNewSettings,
		isNewCampaignList,
		isNewActivity,
		isNewAudience,
		setIsShowingNavBar,
	]);

	const { workspaceInfo, loading: workspaceLoading } = useSelector(
		({ workspace: { workspaceInfo, loading } }: RootState) => ({
			workspaceInfo,
			loading,
		}),
	);

	const { authProvider, user: accessUser, propelAccessToken } = useAuth();

	const hasIdentityToken = !!TokenStorage.getIdentity();

	const isLoggedIn = useMemo(() => {
		return authProvider?.isAuthenticated && hasIdentityToken;
	}, [authProvider?.isAuthenticated, hasIdentityToken]);

	/* Logic to get workspace info when the page is refreshed and when the user is logged in on auth provider
	check for workspace id and if not then get workspace info to grant access and set auth client in redux
	*/
	useEffect(() => {
		if (!workspaceInfo?._id && isLoggedIn && !workspaceLoading) {
			dispatch(getWorkspaceInfo());
			dispatch(
				setAuthProviderInfo({
					logout: authProvider?.logout,
					loginWithRedirect: authProvider?.loginWithRedirect,
					user: authProvider?.user,
				}),
			);
		}
	}, [isLoggedIn, authProvider, workspaceInfo?._id, workspaceLoading]);

	/*
	 |
	 | 
	 |
	 |
	\ / 
	*/

	/* Logic to get the current logged user when the page is refreshed
	 and when the user is logged in - check for access user in redux, check for access token and workspace id
	to grant access if not already granted 
	*/
	useEffect(() => {
		if (
			!accessUser &&
			!!TokenStorage.getAccessToken() &&
			authProvider?.user?.email &&
			workspaceInfo?._id
		) {
			dispatch(
				grantAccess(workspaceInfo?._id as string, authProvider?.user?.email),
			);
		}
	}, [accessUser, workspaceInfo?._id, authProvider?.user?.email]);

	/* Logic to set the access token in storage when propel token refresh*/
	useEffect(() => {
		if (authProvider?.name === 'propelAuth' && propelAccessToken) {
			TokenStorage.setAccessToken(propelAccessToken);
		}
	}, [propelAccessToken, authProvider?.name]);

	return (
		<Suspense
			fallback={
				<LoaderContainer>
					<Loader />
				</LoaderContainer>
			}
		>
			<RouterProvider router={router} />
		</Suspense>
	);
}
