import { IDataHookResponse } from '@flatfile/adapter';
import { IField } from '@flatfile/adapter/build/main/interfaces';
import {
	COUNTRY_ISO_CODE,
	COUNTRY_PHONE_PREFIX,
	VIDEO_LANGUAGE_CODES,
} from '@app/constants/country';
import { ContactFieldTypes, StorytellerFieldTypes } from '../types';
import { CustomAttributeType } from '@app/types/modules/workspace';
import { emailRegex } from '@app/constants';

export function contactRecordChange(
	record: ContactFieldTypes,
): IDataHookResponse {
	try {
		const {
			country,
			mobilePhoneNumber,
			email,
			prefferedLanguageCode,
			communicationPreference,
		}: {
			country?: string;
			mobilePhoneNumber?: string;
			email?: string;
			prefferedLanguageCode?: string;
			communicationPreference?: string;
		} = record;

		const finalValues: any = {
			country: {},
			mobilePhoneNumber: {},
			email: {},
			prefferedLanguageCode: {},
			communicationPreference: {},
		};

		if (country) {
			// Check if user's typing whole country or just code
			const fullCountrySubmitted = country.length > 2;
			let countryCode = '';
			// Look for country code based on input
			const countryFound = COUNTRY_ISO_CODE.find((c) =>
				fullCountrySubmitted
					? c.name.toLowerCase().includes(country.toLowerCase())
					: c.alpha2 === country.toUpperCase(),
			);
			if (countryFound) {
				countryCode = countryFound.alpha2;
				// Find phone prefix according to country code
				const numberPrefix = COUNTRY_PHONE_PREFIX.find(
					(c) => c.code === countryCode,
				)?.phoneCode;

				finalValues.country.value = countryCode;

				let formattedMobilePhoneNumber = '';

				if (mobilePhoneNumber) {
					formattedMobilePhoneNumber = numberPrefix + mobilePhoneNumber;
					// If user includes complete phone prefix format
					if (numberPrefix && mobilePhoneNumber.startsWith(numberPrefix)) {
						formattedMobilePhoneNumber = mobilePhoneNumber;
					} else if (
						// If user is missing "+" but has phone prefix
						numberPrefix &&
						mobilePhoneNumber.startsWith(numberPrefix.replace('+', ''))
					) {
						formattedMobilePhoneNumber = '+' + mobilePhoneNumber;
					}
					finalValues.mobilePhoneNumber.value = formattedMobilePhoneNumber;
				}
			} else {
				finalValues.country.info = [
					{ message: 'Invalid country.', level: 'error' },
				];
			}
		}

		if (mobilePhoneNumber && mobilePhoneNumber.length > 15) {
			finalValues.mobilePhoneNumber.info = [
				{ message: 'Invalid phone number.', level: 'error' },
			];
		}

		if (prefferedLanguageCode) {
			let languageCode = '';

			const languageFound = VIDEO_LANGUAGE_CODES.find((l) =>
				l.name
					.toLowerCase()
					.replace(/([()])/g, '')
					.includes(prefferedLanguageCode.toLowerCase().replace(/([()])/g, '')),
			);

			if (languageFound) {
				languageCode = languageFound.name;

				finalValues.prefferedLanguageCode.value = languageCode;
			} else {
				finalValues.prefferedLanguageCode.info = [
					{ message: 'Invalid language.', level: 'error' },
				];
			}
		}

		if (!!email) {
			const emailInfo = finalValues?.email?.info
				? [...finalValues?.email.info]
				: [];

			const isValidEmail = emailRegex.test(email);

			if (!isValidEmail) {
				const emailError = {
					message: 'Please enter a valid email address.',
					level: 'error',
				};
				emailInfo.push(emailError);
				finalValues.email.info = emailInfo;
			}
		}

		if (communicationPreference) {
			const phoneInfo = finalValues?.mobilePhoneNumber?.info
				? [...finalValues?.mobilePhoneNumber.info]
				: [];

			const emailInfo = finalValues?.email?.info
				? [...finalValues?.email.info]
				: [];

			const phoneError = {
				message: 'Phone required because of sending method selected.',
				level: 'error',
			};

			const emailError = {
				message: 'Email required because of sending method selected.',
				level: 'error',
			};

			if (
				!mobilePhoneNumber &&
				(communicationPreference === 'sms' ||
					communicationPreference === 'both')
			) {
				phoneInfo.push(phoneError);
				finalValues.mobilePhoneNumber.info = phoneInfo;
			}

			if (
				!email &&
				(communicationPreference === 'email' ||
					communicationPreference === 'both')
			) {
				emailInfo.push(emailError);
				finalValues.email.info = emailInfo;
			}
		}

		return finalValues;
	} catch (err) {
		console.error('contactRecordChange err: ', err);
		throw err;
	}
}

export function storytellerRecordChange(
	record: StorytellerFieldTypes,
): IDataHookResponse {
	return {};
}

export function transformCustomAttributesIntoRows(
	customAttributes: CustomAttributeType[],
): IField[] {
	if (!!!customAttributes?.length) return [];
	return customAttributes.map((customAttribute) => {
		const fieldPerAttribute: any = {
			label: `${customAttribute.displayName}`,
			key: `${customAttribute.name}`,
		};

		if (customAttribute.format === 'boolean') {
			fieldPerAttribute.type = 'select';
			fieldPerAttribute.options = [
				{ value: 'true', label: 'True' },
				{ value: 'false', label: 'False' },
			];
			fieldPerAttribute.description = 'Optional. Must be either true or false.';
		}

		if (customAttribute.format === 'number') {
			fieldPerAttribute.validators = [
				{
					validate: 'regex_matches',
					regex: '^[0-9]*$',
					error: 'Value must be a number.',
				},
			];
			fieldPerAttribute.description = 'Optional. Must be a number.';
		}

		if (customAttribute.format === 'date') {
			fieldPerAttribute.validators = [
				{
					validate: 'regex_matches',
					regex: '^\\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])',
					error: 'Value must be a date in YYYY-MM-DD format.',
				},
			];

			fieldPerAttribute.description = 'Optional. Must be a date.';
		}

		return fieldPerAttribute;
	});
}
