import React from 'react';
import {
	useFloating,
	autoUpdate,
	offset,
	flip,
	shift,
	useHover,
	useFocus,
	useDismiss,
	useRole,
	useInteractions,
	useMergeRefs,
	FloatingPortal,
	Placement,
	FloatingArrow,
	arrow,
} from '@floating-ui/react';

import * as S from './Tooltip.styles';

import { Text } from '../';
import { useTheme } from 'styled-components';

type TooltipOptions = {
	initialOpen?: boolean;
	placement?: Placement;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
};

type PropsType = {
	children: React.ReactNode;
	content?: string;
	withArrow?: boolean;
} & TooltipOptions;

const ARROW_WIDTH = 12;
const ARROW_HEIGHT = 6;
const GAP = 2;

export function useTooltip({
	initialOpen = false,
	placement = 'top',
	open: controlledOpen,
	onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
	const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const arrowRef = React.useRef(null);

	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(ARROW_HEIGHT + GAP),
			flip({
				crossAxis: placement.includes('-'),
				fallbackAxisSideDirection: 'start',
				padding: 5,
			}),
			shift({ padding: 5 }),
			arrow({
				element: arrowRef,
			}),
			offset(4),
		],
	});

	const context = data.context;

	const hover = useHover(context, {
		move: false,
		enabled: controlledOpen == null,
	});
	const focus = useFocus(context, {
		enabled: controlledOpen == null,
	});
	const dismiss = useDismiss(context, {
		ancestorScroll: true,
	});
	const role = useRole(context, { role: 'tooltip' });

	const interactions = useInteractions([hover, focus, dismiss, role]);

	return React.useMemo(
		() => ({
			open,
			setOpen,
			...interactions,
			...data,
			arrowRef,
		}),
		[open, setOpen, interactions, data],
	);
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
	const context = React.useContext(TooltipContext);

	if (context == null) {
		throw new Error('Tooltip components must be wrapped in <Tooltip />');
	}

	return context;
};

export const TooltipTrigger = React.forwardRef<
	HTMLElement,
	React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, ...props }, propRef) {
	const context = useTooltipContext();
	const childrenRef = (children as any).ref;
	const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

	if (React.isValidElement(children)) {
		return React.cloneElement(
			children,
			context.getReferenceProps({
				ref,
				...props,
				...children.props,
				// Extend existing styles for valid elements
				style: {
					...children.props.style,
					cursor: 'pointer',
				},
				'data-state': context.open ? 'open' : 'closed',
			}),
		);
	}

	return (
		<S.TooltipTrigger
			ref={ref}
			data-state={context.open ? 'open' : 'closed'}
			{...context.getReferenceProps(props)}
		>
			{children}
		</S.TooltipTrigger>
	);
});

export const TooltipContent = React.forwardRef<HTMLDivElement, any>(
	function TooltipContent({ style, withArrow, ...props }, propRef) {
		const context = useTooltipContext();
		const ref = useMergeRefs([context.refs.setFloating, propRef]);

		const isEdgeAligned = context.placement.includes('-');

		const theme = useTheme();

		if (!context.open) return null;

		return (
			<FloatingPortal>
				<S.TooltipContentWrapper
					ref={ref}
					style={{
						...context.floatingStyles,
						...style,
						zIndex: theme.zIndex.tooltip,
						visibility: context?.middlewareData.hide?.referenceHidden
							? 'hidden'
							: 'visible',
					}}
					{...context.getFloatingProps(props)}
				>
					{props.children}
					{withArrow && (
						<FloatingArrow
							ref={context.arrowRef}
							context={context.context}
							fill={theme.colors.system.background.system}
							width={ARROW_WIDTH}
							height={ARROW_HEIGHT}
							staticOffset={isEdgeAligned ? '20%' : undefined}
							stroke={theme.colors.system.background.xStrong}
							strokeWidth={1}
						/>
					)}
				</S.TooltipContentWrapper>
			</FloatingPortal>
		);
	},
);

export function Tooltip({
	children,
	content,
	withArrow = true,
	...options
}: PropsType) {
	// This can accept any props as options, e.g. `placement`,
	// or other positioning options.
	const tooltip = useTooltip(options);

	//Simply return children if no content is provided - avoid blank tooltips
	if (!content) {
		return <>{children}</>;
	}

	return (
		<TooltipContext.Provider value={tooltip}>
			<TooltipTrigger>{children}</TooltipTrigger>

			<TooltipContent withArrow={withArrow}>
				<Text size="small" color="system.text.default" lineHeight="large">
					{content}
				</Text>
			</TooltipContent>
		</TooltipContext.Provider>
	);
}

export default Tooltip;
export type { PropsType as TooltipPropsType };
