import React, { useEffect, useMemo, useState } from 'react';
import FloatingMenu, {
	FloatingMenuPropsType,
} from '../FloatingMenu/FloatingMenu';
import Button, { ButtonProps } from '../../atoms/Button/Button';

import * as S from './SplitButton.styles';

type MenuItemsAsserted = Omit<
	FloatingMenuPropsType['menus'][number]['menuItems'][number],
	'onClick'
> & {
	onButtonClick?: () => void;
};

type PropsType = {
	buttonProps?: ButtonProps;
	popUpPlacement?: FloatingMenuPropsType['popUpPlacement'];
	menuItems?: MenuItemsAsserted[];
	arrowDirection?: 'up' | 'down';
	disableMenuButton?: boolean;
	hideSelectedOption?: boolean;
};

function SplitButton({
	buttonProps,
	popUpPlacement = 'top-end',
	menuItems = [],
	arrowDirection = 'up',
	disableMenuButton = false,
	hideSelectedOption = true,
}: PropsType) {
	const [currentMenuOption, setCurrentMenuOption] = useState<
		MenuItemsAsserted | undefined
	>(undefined);
	const [currentMenuOptionIndex, setCurrentMenuOptionIndex] =
		useState<number>(0);

	const assertedMenuItems = useMemo(() => {
		return menuItems.map((item) => {
			return {
				...item,
				onClick: (selectedItem: MenuItemsAsserted) => {
					const selectedOptionIndex = assertedMenuItems.findIndex(
						(item) => item.id === selectedItem?.id,
					);
					if (selectedOptionIndex === -1) {
						setCurrentMenuOption(assertedMenuItems[0]);
						setCurrentMenuOptionIndex(0);
					}
					setCurrentMenuOption(assertedMenuItems[selectedOptionIndex]);
					setCurrentMenuOptionIndex(selectedOptionIndex);
				},
			};
		});
	}, [menuItems]);

	const displayedMenuItems = useMemo(() => {
		if (!hideSelectedOption) return assertedMenuItems;

		return assertedMenuItems.filter(
			(_, index) => index !== currentMenuOptionIndex,
		);
	}, [assertedMenuItems, hideSelectedOption, currentMenuOptionIndex]);

	const computedButtonProps = useMemo(() => {
		return {
			variant: 'primary',
			iconPosition: 'right',
			...(!!currentMenuOption?.icon ? { icon: currentMenuOption?.icon } : {}),
			...buttonProps,
			onClick: () => {
				if (!!currentMenuOption?.onButtonClick) {
					currentMenuOption?.onButtonClick();
				}
			},
		} as ButtonProps;
	}, [currentMenuOption, buttonProps]);

	useEffect(() => {
		// Set the updated selected option when items change
		let selectedOptionIndex = assertedMenuItems.findIndex(
			(item) => item.id === currentMenuOption?.id,
		);
		const selectedOption =
			selectedOptionIndex !== -1
				? assertedMenuItems[selectedOptionIndex]
				: assertedMenuItems[0];

		if (selectedOptionIndex === -1) selectedOptionIndex = 0;

		setCurrentMenuOption(selectedOption);
		setCurrentMenuOptionIndex(selectedOptionIndex);
	}, [assertedMenuItems]);

	return (
		<S.SplitButtonWrapper>
			<Button {...computedButtonProps}>{currentMenuOption?.text}</Button>

			<S.Divider />

			<div style={{ position: 'relative' }}>
				<FloatingMenu
					popUpPlacement={popUpPlacement}
					menus={[
						{
							menuItems: displayedMenuItems,
						},
					]}
					trigger={
						<Button
							size={computedButtonProps?.size}
							variant={computedButtonProps?.variant}
							iconButton
							icon={arrowDirection === 'up' ? 'chevronUp' : 'chevronDown'}
							disabled={disableMenuButton}
						/>
					}
				/>
			</div>
		</S.SplitButtonWrapper>
	);
}

export default SplitButton;
export type { PropsType as SplitButtonPropsType };
