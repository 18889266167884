import { env } from '@app/env';
import { CloudinaryUploadResult } from '@app/types';
import { useEffect, useState } from 'react';

type useCloudinaryUploadWidgetProps = {
	configs?: {
		uploadPreset?: string;
		sources?: string[];
		multiple?: boolean;
		maxFiles?: number;
		showAdvancedOptions?: boolean;
		clientAllowedFormats?: string[];

		showSkipCropButton?: boolean;
		maxFileSize?: number;
		showPoweredBy?: boolean;
		singleUploadAutoClose?: boolean;
		cropping?: boolean;
		maxImageWidth?: number;
		maxImageHeight?: number;
		minImageWidth?: number;
		minImageHeight?: number;
		croppingAspectRatio?: number;
		croppingValidateDimensions?: boolean;
		croppingShowDimensions?: boolean;
		secure?: boolean;
	};
	onUpload: (result: CloudinaryUploadResult) => void;
	onError?: (error: any) => void;
};
function useCloudinaryUploadWidget({
	configs,
	onUpload,
	onError,
}: useCloudinaryUploadWidgetProps) {
	const [result, setResult] = useState<CloudinaryUploadResult>();
	const [error, setError] = useState();

	const [cloudinaryWidget, setCloudinaryWidget] = useState<any>();

	useEffect(() => {
		if (cloudinaryWidget) return;

		const uploadWidget = (window as any).cloudinary.createUploadWidget(
			{
				apiKey: env.CLOUDINARY_API_KEY,
				cloudName: env.CLOUDINARY_CLOUD_NAME,
				uploadPreset: configs?.uploadPreset || 'general-assets',
				sources: configs?.sources || [
					'local',
					'unsplash',
					'url',
					'camera',
					'dropbox',
					'image_search',
					'facebook',
					'instagram',
					'google_drive',
				],
				multiple: configs?.multiple || false,
				maxFiles: configs?.maxFiles || 1,
				showAdvancedOptions: configs?.showAdvancedOptions || false,
				showPoweredBy: configs?.showPoweredBy || false,
				clientAllowedFormats: configs?.clientAllowedFormats || [
					'webp',
					'png',
					'gif',
					'jpg',
					'jpeg',
				],

				showSkipCropButton: configs?.showSkipCropButton,
				maxFileSize: configs?.maxFileSize,
				singleUploadAutoClose: configs?.singleUploadAutoClose,
				cropping: configs?.cropping,
				maxImageWidth: configs?.maxImageWidth,
				maxImageHeight: configs?.maxImageHeight,
				minImageWidth: configs?.minImageWidth,
				minImageHeight: configs?.minImageHeight,
				croppingAspectRatio: configs?.croppingAspectRatio,
				croppingValidateDimensions: configs?.croppingValidateDimensions,
				croppingShowDimensions: configs?.croppingShowDimensions,
				secure: configs?.secure,
			},

			(error: any, result: CloudinaryUploadResult) => {
				if (!error && result && result.event === 'success') {
					onUpload(result);

					setResult(result);
				}

				if (error) {
					setError(error);
					onError && onError(error);
				}
			},
		);
		setCloudinaryWidget(uploadWidget);
	}, [cloudinaryWidget, configs, onUpload, onError]);

	const closeUploadWidget = () => {
		cloudinaryWidget?.close();
	};

	const openUploadWidget = () => {
		cloudinaryWidget?.open();
	};

	return {
		openWidget: openUploadWidget,
		closeWidget: closeUploadWidget,
		cloudinaryWidget,
		result,
		error,
	};
}

export default useCloudinaryUploadWidget;
