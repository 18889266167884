import { createSlice } from '@reduxjs/toolkit';
import createFetchReducer from '@app/utils/createFetchReducer';

import {
	getSMSUserTemplateTypes,
	getUserTemplatesTypes,
	getSingleUserTemplateTypes,
	getWhatsappUserTemplateTypes,
	getPostcardUserTemplateTypes,
	createTemplatesTypes,
	updateTemplatesTypes,
	duplicatedTemplatesTypes,
	updateTeamsOnUserTemplateTypes,
	deleteTemplatesTypes,
	getPresignedUrlTypes,
	getPostcardTemplateJsonTypes,
	createPostImageTypes,
	deletePostImageTypes,
	deleteAllPostImagesTypes,
	getPostImagesTypes,
} from '@app/redux/userTemplates/action';
import { GenericEntityState, ResponseAction } from '@app/redux/types';
import { FIRST_PAGE } from '@app/redux/constants';
import { UserTemplateChannelsEnum } from '@app/redux/userTemplates/constants';
import { getUserTemplatesOrdered } from './utils';

const initialState: GenericEntityState = {
	loading: false,
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	userTemplates: [],
	smsTemplates: {
		pagination: {
			totalPages: null,
			currentPage: null,
			count: null,
			pages: {},
		},
		smsTemplatesCount: 0,
		templates: [],
	},
	waTemplates: {
		pagination: {
			totalPages: null,
			currentPage: null,
			count: null,
			pages: {},
		},
		waTemplatesCount: 0,
		templates: [],
	},
	postcardTemplates: {
		pagination: {
			totalPages: null,
			currentPage: null,
			count: null,
			pages: {},
		},
		postcardTemplatesCount: 0,
		templates: [],
	},
	postImages: {
		images: [],
		loading: false,
		error: {},
		response: {
			status: null,
			message: null,
		},
	},
	custom: {
		createUserTemplateLoading: false,
		updateUserTemplateLoading: false,
		updateTeamsOnUserTemplateLoading: false,
		deleteUserTemplateLoading: false,
	},
};

export type userTemplatesType = typeof initialState;

function getUserTemplatesMapper(
	state: userTemplatesType,
	action: ResponseAction,
) {
	const page = action.response?.page;

	if (page === FIRST_PAGE) {
		state.userTemplates = action.response.userTemplates;
	} else {
		state.userTemplates = [
			...state.userTemplates,
			...action.response.userTemplates,
		];
	}

	state.pagination = {
		totalPages: action.response?.totalPages,
		currentPage: page,
		count: action?.response?.count,
		pages: {
			[page]: action.response.userTemplates,
		},
	};
}

function getSMSUserTemplateMapper(
	state: userTemplatesType,
	action: ResponseAction,
) {
	const page = action.response?.page;

	if (page === FIRST_PAGE) {
		state.smsTemplates.templates = action.response.userTemplates;
	} else {
		state.smsTemplates.templates = [
			...state.smsTemplates.templates,
			...action.response.userTemplates,
		];
	}

	state.smsTemplates.smsTemplatesCount =
		state.smsTemplates.templates.length || 0;
	state.smsTemplates.pagination = {
		totalPages: action.response?.totalPages,
		currentPage: page,
		count: action?.response?.count,
		pages: {
			...state.smsTemplates.pagination.pages,
			[page]: action.response.userTemplates,
		},
	};
}

function getWhatsAppUserTemplateMapper(
	state: userTemplatesType,
	action: ResponseAction,
) {
	const page = action.response?.page;

	if (page === FIRST_PAGE) {
		state.waTemplates.templates = action.response.userTemplates;
	} else {
		state.waTemplates.templates = [
			...state.waTemplates.templates,
			...action.response.userTemplates,
		];
	}

	state.waTemplates.waTemplatesCount = state.waTemplates.templates.length || 0;
	state.waTemplates.pagination = {
		totalPages: action.response?.totalPages,
		currentPage: page,
		count: action?.response?.count,
		pages: {
			...state.waTemplates.pagination.pages,
			[page]: action.response.userTemplates,
		},
	};
}
function getPostcardUserTemplateMapper(
	state: userTemplatesType,
	action: ResponseAction,
) {
	const page = action.response?.page;

	if (page === FIRST_PAGE) {
		state.postcardTemplates.templates = action.response.userTemplates;
	} else {
		state.postcardTemplates.templates = [
			...state.postcardTemplates.templates,
			...action.response.userTemplates,
		];
	}

	state.postcardTemplates.postcardTemplatesCount =
		state.postcardTemplates.templates.length || 0;
	state.postcardTemplates.pagination = {
		totalPages: action.response?.totalPages,
		currentPage: page,
		count: action?.response?.count,
		pages: {
			...state.postcardTemplates.pagination.pages,
			[page]: action.response.userTemplates,
		},
	};
}

const createTemplatesMapper = (
	state: userTemplatesType,
	action: ResponseAction,
) => {
	const createdTemplate = action.response.userTemplate;
	const channel = createdTemplate?.channel;
	const insertInList = action.payload?.insertInList;

	if (channel === UserTemplateChannelsEnum.SMS) {
		if (insertInList) {
			state.smsTemplates.templates = getUserTemplatesOrdered([
				createdTemplate,
				...state.smsTemplates.templates,
			]);
		}

		state.smsTemplates.smsTemplatesCount =
			state.smsTemplates.templates.length || 0;

		return;
	}

	if (channel === UserTemplateChannelsEnum.WHATSAPP) {
		if (insertInList) {
			state.waTemplates.templates = getUserTemplatesOrdered([
				createdTemplate,
				...state.waTemplates.templates,
			]);
		}

		state.waTemplates.waTemplatesCount =
			state.waTemplates.templates.length || 0;
		return;
	}

	if (channel === UserTemplateChannelsEnum.POSTCARD) {
		if (insertInList) {
			state.postcardTemplates.templates = getUserTemplatesOrdered([
				createdTemplate,
				...state.postcardTemplates.templates,
			]);
		}

		state.postcardTemplates.postcardTemplatesCount =
			state.postcardTemplates.templates.length || 0;
		return;
	}

	state.userTemplates = getUserTemplatesOrdered([
		createdTemplate,
		...state.userTemplates,
	]);
};

const updateTemplatesMapper = (
	state: userTemplatesType,
	action: ResponseAction,
) => {
	const updatedTemplate = action.response.userTemplate;
	const channel = updatedTemplate?.channel;

	if (channel === UserTemplateChannelsEnum.SMS) {
		state.smsTemplates.templates = getUserTemplatesOrdered(
			state.smsTemplates.templates,
			updatedTemplate,
		);
		return;
	}

	if (channel === UserTemplateChannelsEnum.WHATSAPP) {
		state.waTemplates.templates = getUserTemplatesOrdered(
			state.waTemplates.templates,
			updatedTemplate,
		);
		return;
	}

	if (channel === UserTemplateChannelsEnum.POSTCARD) {
		state.postcardTemplates.templates = getUserTemplatesOrdered(
			state.postcardTemplates.templates,
			updatedTemplate,
		);
		return;
	}

	state.userTemplates = getUserTemplatesOrdered(
		state.userTemplates,
		updatedTemplate,
	);
};

const getPostImagesMapper = (
	state: userTemplatesType,
	action: ResponseAction,
) => {
	state.postImages.images = action.response.postImages;
};

const createPostImageMapper = (
	state: userTemplatesType,
	action: ResponseAction,
) => {
	state.postImages.images = [
		action.response.postImage,
		...state.postImages.images,
	];
};

const deletePostImageMapper = (
	state: userTemplatesType,
	action: ResponseAction,
) => {
	const response = action.response;

	state.postImages.images = state.postImages.images.filter(
		(image: any) => image._id !== response.postImage._id,
	);
};

const deleteAllPostImagesMapper = (
	state: userTemplatesType,
	action: ResponseAction,
) => {
	state.postImages.images = [];
};

const userTemplateSlice = createSlice({
	name: 'userTemplate',
	initialState,
	reducers: {
		RESET_USER_TEMPLATE_LIST(state: userTemplatesType) {
			state.userTemplates = [];
			state.pagination = {
				pages: {},
				currentPage: null,
				totalPages: null,
			};

			state.smsTemplates = {
				pagination: {
					totalPages: null,
					currentPage: null,
					count: null,
					pages: {},
				},
				smsTemplatesCount: 0,
				templates: [],
			};

			state.waTemplates = {
				pagination: {
					totalPages: null,
					currentPage: null,
					count: null,
					pages: {},
				},
				waTemplatesCount: 0,
				templates: [],
			};

			state.postcardTemplates = {
				pagination: {
					totalPages: null,
					currentPage: null,
					count: null,
					pages: {},
				},
				postcardTemplatesCount: 0,
				templates: [],
			};

			state.custom = {
				createUserTemplateLoading: false,
				updateUserTemplateLoading: false,
				updateTeamsOnUserTemplateLoading: false,
				deleteUserTemplateLoading: false,
			};
		},
	},
	extraReducers: {
		...createFetchReducer(getSingleUserTemplateTypes),
		...createFetchReducer(getUserTemplatesTypes, getUserTemplatesMapper),
		...createFetchReducer(getSMSUserTemplateTypes, getSMSUserTemplateMapper),
		...createFetchReducer(
			getWhatsappUserTemplateTypes,
			getWhatsAppUserTemplateMapper,
		),
		...createFetchReducer(
			getPostcardUserTemplateTypes,
			getPostcardUserTemplateMapper,
		),
		...createFetchReducer(createTemplatesTypes, createTemplatesMapper),
		...createFetchReducer(updateTemplatesTypes, updateTemplatesMapper),
		...createFetchReducer(duplicatedTemplatesTypes, createTemplatesMapper),
		...createFetchReducer(deleteTemplatesTypes),
		...createFetchReducer(updateTeamsOnUserTemplateTypes),

		...createFetchReducer(getPresignedUrlTypes),
		...createFetchReducer(getPostcardTemplateJsonTypes),

		...createFetchReducer(createPostImageTypes, createPostImageMapper),
		...createFetchReducer(getPostImagesTypes, getPostImagesMapper),
		...createFetchReducer(deletePostImageTypes, deletePostImageMapper),
		...createFetchReducer(deleteAllPostImagesTypes, deleteAllPostImagesMapper),
	},
});

export default userTemplateSlice.reducer;
