import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ImagesGrid } from 'polotno/side-panel';
import { getImageSize } from 'polotno/utils/image';

import {
	CloudinaryUploadWidget,
	ConfirmationModal,
} from '@common/design-system/components/molecules';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@app/redux/types';
import {
	createPostImage,
	deletePostImage,
	getPostImages,
} from '@app/redux/userTemplates/action';
import { Button, Text } from '@common/design-system/components/atoms';
import useModal from '@app/hooks/useModal';
import isSuccessHttpStatus from '@app/utils/isSuccessHttpStatus';

const UploadPanel = observer(({ store }: any) => {
	const dispatch = useAppDispatch();

	const images = useSelector(
		({ userTemplates }: RootState) => userTemplates.postImages?.images,
	);
	const getPostImagesLoading = useSelector(
		({ userTemplates }: RootState) =>
			userTemplates.custom?.getPostImagesLoading,
	);
	const deletePostImageLoading = useSelector(
		({ userTemplates }: RootState) =>
			userTemplates.custom?.deletePostImageLoading,
	);

	const {
		isOpened: isConfirmationModalOpened,
		openModal: openConfirmationModal,
		closeModal: closeConfirmationModal,
	} = useModal();

	const [tempImageId, setTempImageId] = React.useState<string | null>(null);

	const handleGetImages = async () => {
		dispatch(getPostImages());
	};

	const handleDeleteImage = async () => {
		if (!tempImageId) return;

		const response: any = await dispatch(deletePostImage(tempImageId));

		if (isSuccessHttpStatus(response.status)) {
			closeConfirmationModal();
			setTempImageId(null);
		}
	};
	const handleUpload = async (url: string | null, result: any) => {
		if (!url) return;
		await dispatch(
			createPostImage({
				url: url || result.info.url,
				cloudinaryPublicId: result.info.public_id,
			}),
		);
	};

	const handleSelect = async (image: any, pos: any, element: any) => {
		const { url } = image;
		let { width, height } = await getImageSize(url);
		const isSVG = url.indexOf('svg+xml') >= 0 || url.indexOf('.svg') >= 0;

		const type = isSVG ? 'svg' : 'image';

		if (
			element &&
			element.type === 'svg' &&
			!element.locked &&
			type === 'image'
		) {
			element.set({ maskSrc: url });
			return;
		}

		if (
			element &&
			element.type === 'image' &&
			!element.locked &&
			type === 'image'
		) {
			element.set({ src: url });
			return;
		}

		const aspectRatio = width / height;

		// Scale down the image to fit the canvas
		width = 500;
		height = width / aspectRatio;

		// otherwise let's create new image
		const x = (pos?.x || store.width / 2) - width / 2;
		const y = (pos?.y || store.height / 2) - height / 2;

		store.activePage?.addElement({
			type,
			src: url,
			x,
			y,
			width,
			height,
		});
	};

	useEffect(() => {
		handleGetImages();
	}, []);

	return (
		<>
			{isConfirmationModalOpened && tempImageId && (
				<ConfirmationModal
					hasSeparators={false}
					title="Delete Image"
					isLoading={deletePostImageLoading}
					onClose={closeConfirmationModal}
					confirmButtonText="Delete"
					onConfirmClick={() => handleDeleteImage()}
					confirmButtonVariant="danger"
					dismissButtonText="Cancel"
					onDismissClick={() => closeConfirmationModal()}
				>
					<Text lineHeight="large">
						Are you sure you want to delete the image? This action cannot be
						undone.
					</Text>
				</ConfirmationModal>
			)}

			<div className="w-100 d-flex flex-column">
				<div className="mb-4">
					<CloudinaryUploadWidget
						showPreview={false}
						buttonProps={{
							size: 'large',
							text: 'Upload custom image here',
							isFullWidth: true,
						}}
						withPlaceholder={false}
						onUpload={(url, result) => handleUpload(url, result)}
						croppingShowDimensions
						minImageWidth={150}
						minImageHeight={150}
						cropping={false}
						sources={['local']}
					/>
				</div>
				<ImagesGrid
					isLoading={getPostImagesLoading}
					images={images}
					getPreview={(image: any) => image?.url}
					crossOrigin="anonymous"
					getCredit={(image) => (
						<div className="d-flex justify-content-center">
							<Button
								icon="trash"
								variant="danger"
								iconButton
								size="small"
								onClick={async (e) => {
									e.stopPropagation();
									setTempImageId(image?._id);
									openConfirmationModal();
								}}
							></Button>
						</div>
					)}
					onSelect={handleSelect}
				/>
			</div>
		</>
	);
});

export default UploadPanel;
