import React from 'react';

function useDisableScrollOnDocument() {
	React.useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);

	return null;
}

export default useDisableScrollOnDocument;
