import styled, { css } from 'styled-components';

export const TableContainer = styled.div<{
	withBorder?: boolean;
	isTableAccordionOpen?: boolean;
}>`
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: auto;
	flex: ${({ isTableAccordionOpen }) => (isTableAccordionOpen ? 1 : 'none')};
	height: ${({ isTableAccordionOpen }) =>
		isTableAccordionOpen ? '100%' : 'auto'};
	border-radius: ${({ withBorder }) => (withBorder ? '10px' : 0)};
	border: ${({ theme, withBorder }) =>
		withBorder ? `1px solid ${theme.colors.system.border.medium}` : 'none'};
`;

export const StyledTable = styled.table<{ hasInfiniteScroll?: boolean }>`
	position: relative;
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	border: none;
	height: fit-content;
	padding-bottom: ${({ hasInfiniteScroll, theme }) =>
		hasInfiniteScroll && theme.spacing.xxl};
	background-color: ${({ theme }) => theme.colors.system.background.system};

	white-space: nowrap;
`;

export const StyledThead = styled.thead<{ headerTheme: 'light' | 'dark' }>`
	position: sticky;
	top: 0;
	background: ${({ theme, headerTheme }) =>
		theme.colors.system.background[
			headerTheme === 'light' ? 'system' : 'xWeak'
		]};
	z-index: ${({ theme }) => theme.zIndex.sticky};
`;

export const StyledTableRow = styled.tr<{
	onClick?: () => void;
	heightSize: number;
	firstColumnFixed?: boolean;
	lastColumnFixed?: boolean;
}>`
	height: ${({ heightSize }) => `${heightSize}px`};
	max-height: ${({ heightSize }) => `${heightSize}px`};
	cursor: ${({ onClick }) => onClick && 'pointer'};
	font-size: ${({ theme }) => theme.fonts.sizes.small};
	transition: ${({ theme }) => theme.transitions.ease01};

	&:hover {
		> td {
			background: ${({ theme }) => theme.colors.system.background.xWeak};
		}
	}

	&:last-child {
		border-bottom: none;
	}

	//Children styles

	${({ firstColumnFixed }) =>
		firstColumnFixed &&
		css`
			> :first-child {
				position: sticky;
				left: 0;
				z-index: ${({ theme }) => theme.zIndex.base};
				border-right: 1px solid
					${({ theme }) => theme.colors.system.border.weak};
				padding: ${({ theme }) => `0 ${theme.spacing.large}`};
				box-shadow:
					2px 0px 3px 0px rgba(20, 20, 23, 0.05),
					3px 0px 6px 0px rgba(37, 37, 46, 0.04);
			}
		`}

	> :last-child {
		${({ lastColumnFixed }) =>
			lastColumnFixed &&
			css`
				position: sticky;
				right: 0;
				z-index: ${({ theme }) => theme.zIndex.base};
				border-left: 1px solid ${({ theme }) => theme.colors.system.border.weak};
				box-shadow:
					-2px 0px 3px 0px rgba(20, 20, 23, 0.05),
					-3px 0px 6px 0px rgba(37, 37, 46, 0.04);
			`}

		padding-right: ${({ theme }) => theme.spacing.large};
	}
`;

export const HeadingRow = styled.tr<{
	heightSize: number;
	firstColumnFixed?: boolean;
	lastColumnFixed?: boolean;
}>`
	height: ${({ heightSize }) => `${heightSize}px`};
	max-height: ${({ heightSize }) => `${heightSize}px`};
	border-bottom: 1px solid ${({ theme }) => theme.colors.system.border.weak};

	// Children styles

	${({ firstColumnFixed }) =>
		firstColumnFixed &&
		css`
			> :first-child {
				position: sticky;
				left: 0;
				z-index: ${({ theme }) => theme.zIndex.sticky};
				border-right: 1px solid
					${({ theme }) => theme.colors.system.border.weak};
				box-shadow:
					2px 0px 3px 0px rgba(20, 20, 23, 0.05),
					3px 0px 6px 0px rgba(37, 37, 46, 0.04);
			}
		`}

	${({ lastColumnFixed }) =>
		lastColumnFixed &&
		css`
			> :last-child {
				position: sticky;
				right: 0;
				z-index: ${({ theme }) => theme.zIndex.sticky};
				border-left: 1px solid ${({ theme }) => theme.colors.system.border.weak};
				box-shadow:
					-2px 0px 3px 0px rgba(20, 20, 23, 0.05),
					-3px 0px 6px 0px rgba(37, 37, 46, 0.04);
			}
		`}
`;

export const TableHeader = styled.th<{
	centered?: boolean;
	heightSize: number;
	headerTheme: 'light' | 'dark';
	cellWidth?: string;
}>`
	font-size: ${({ theme }) => theme.fonts.sizes.xxs};
	font-weight: ${({ theme }) => theme.fonts.weights.semiBold};
	color: ${({ theme }) => theme.colors.system.text.medium};
	letter-spacing: 0.35px;
	text-transform: uppercase;
	padding: ${({ theme }) => `0 ${theme.spacing.large}`};
	height: ${({ heightSize }) => `${heightSize}px`};
	margin: 0;
	text-align: ${({ centered }) => (centered ? 'center' : 'left')};
	white-space: nowrap;
	display: table-cell;
	vertical-align: middle;
	background: ${({ theme, headerTheme }) =>
		theme.colors.system.background[
			headerTheme === 'light' ? 'system' : 'xWeak'
		]};
	border-bottom: 1px solid ${({ theme }) => theme.colors.system.border.weak};

	min-width: ${({ cellWidth }) => cellWidth || 'auto'};
	width: ${({ cellWidth }) => cellWidth || 'auto'};

	p {
		text-transform: uppercase !important;
	}
`;

export const TableCell = styled.td<{
	centered?: boolean;
	heightSize: number;
	cellWidth?: string;
	maxContentWidth?: boolean;
	cellWrap?: boolean;
}>`
	margin: 0;
	height: ${({ heightSize }) => `${heightSize}px`};
	max-height: ${({ heightSize }) => `${heightSize}px`};
	text-align: ${({ centered }) => (centered ? 'center' : 'left')};
	display: table-cell;
	vertical-align: middle;
	background-color: ${({ theme }) => theme.colors.system.background.system};
	border-bottom: 1px solid ${({ theme }) => theme.colors.system.border.weak};

	padding: ${({ theme }) => `0 ${theme.spacing.large}`};
	white-space: nowrap;
	min-width: ${({ cellWidth }) => cellWidth};
	width: ${({ cellWidth, maxContentWidth }) =>
		cellWidth ? cellWidth : maxContentWidth ? 'max-content' : undefined};

	.table-cell-inner-container {
		width: ${({ cellWidth, maxContentWidth }) =>
			cellWidth ? cellWidth : maxContentWidth ? 'max-content' : undefined};
		margin: ${({ centered }) => centered && '0 auto'};
		display: flex;
		align-items: center;
		flex-wrap: ${({ cellWrap }) => (cellWrap ? 'wrap' : 'nowrap')};
		gap: ${({ theme }) => theme.spacing.xs};
		vertical-align: middle;

		&.multi-cell-inner-container {
			width: 100%;
			&.multi-cell-space-between {
				justify-content: space-between;
			}
		}
	}
`;

export const CheckboxTableCell = styled.div`
	display: inline-block;
	vertical-align: middle;
	width: max-content;
	margin-right: ${({ theme }) => theme.spacing.medium};
`;

export const Flex = styled.div`
	display: flex;
	align-items: center;
	column-gap: 8px;
`;

export const TableCenterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: ${({ theme }) => theme.spacing.large};
`;

export const ButtonGroupWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${({ theme }) => theme.spacing.xs};
`;

export const TableHeaderContainer = styled.div<{
	isTableAccordionOpen?: boolean;
}>`
	display: flex;
	align-items: center;
	width: 100%;
	position: sticky;
	left: 0;
	top: 0;
	gap: ${({ theme }) => theme.spacing.medium};
	padding: ${({ theme }) => theme.spacing.medium};
	border-bottom: ${({ theme, isTableAccordionOpen }) =>
		`1px solid ${isTableAccordionOpen ? theme.colors.system.border.medium : 'transparent'}`};
`;

export const HeaderTitleContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.small};
	width: 100%;
`;
