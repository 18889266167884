import GoodkindDSTheme from '@common/design-system/global/theme';
import styled from 'styled-components';

const getVariantStyles = (
	theme: typeof GoodkindDSTheme,
	variant: string,
	clickable?: boolean,
) => {
	switch (variant) {
		case 'success':
			return `
                background-color: ${theme.colors.success.background.default};
                color: ${theme.colors.success.text.default};
                fill: ${theme.colors.success.icon.default};

			${
				clickable &&
				`&:hover {
					background-color: ${theme.colors.success.background.defaultHover};
				}`
			}
						
				
            `;
		case 'warning':
			return `
                background-color: ${theme.colors.warning.background.default};
                color: ${theme.colors.warning.text.default};
                fill: ${theme.colors.warning.icon.default};

				${
					clickable &&
					`&:hover {
						background-color: ${theme.colors.warning.background.defaultHover};
					}`
				}
            `;
		case 'error':
			return `
                background-color: ${theme.colors.error.background.default};
                color: ${theme.colors.error.text.default};
                fill: ${theme.colors.error.icon.default};

				${
					clickable &&
					`&:hover {
						background-color: ${theme.colors.error.background.defaultHover};
					}`
				}
            `;
		case 'info':
			return `
                background-color: ${theme.colors.primary.background.default};
                color: ${theme.colors.primary.text.strong};
                fill: ${theme.colors.primary.icon.strong};

				${
					clickable &&
					`&:hover {
						background-color: ${theme.colors.primary.background.defaultHover};
					}`
				}
            `;
		case 'accent':
			return `
                background-color: ${theme.colors.accent.background.default};
                color: ${theme.colors.accent.text.default};
                fill: ${theme.colors.accent.icon.default};

				${
					clickable &&
					`&:hover {
						background-color: ${theme.colors.accent.background.defaultHover};
					}`
				}

            `;
		case 'neutral':
			return `
                background-color: ${theme.colors.neutral.background.default};
                color: ${theme.colors.system.text.medium};
                fill: ${theme.colors.system.text.medium};
				${
					clickable &&
					`&:hover {
						background-color: ${theme.colors.neutral.background.hover};
					}`
				}
            `;
		case 'outline':
			return `
                background-color: transparent;
                color: ${theme.colors.system.text.medium};
                fill: ${theme.colors.system.text.medium};
				border: 1px solid ${theme.colors.system.border.medium};

				${
					clickable &&
					`&:hover {
						background-color: ${theme.colors.system.background.weak};
					}`
				}
            `;

		case 'ghost':
			return `
                background-color: transparent;
                color: ${theme.colors.system.text.medium};
                fill: ${theme.colors.system.text.medium};

				&:hover {
					background-color: ${theme.colors.system.background.weak};
				}
            `;
		case 'highlight1':
			return `
                background-color: ${theme.colors.highlight1.background.default};
                color: ${theme.colors.highlight1.text.default};
                fill: ${theme.colors.highlight1.icon.default};

				${
					clickable &&
					`&:hover {
						background-color: ${theme.colors.highlight1.background.strong};
					}`
				}
            `;
		case 'highlight2':
			return `
                background-color: ${theme.colors.highlight2.background.default};
                color: ${theme.colors.highlight2.text.default};
                fill: ${theme.colors.highlight2.icon.default};

				${
					clickable &&
					`&:hover {
						background-color: ${theme.colors.highlight2.background.strong};
					}`
				}
            `;
		case 'highlight3':
			return `
                background-color: ${theme.colors.highlight3.background.default};
                color: ${theme.colors.highlight3.text.default};
                fill: ${theme.colors.highlight3.icon.default};

				${
					clickable &&
					`&:hover {
						background-color: ${theme.colors.highlight3.background.strong};
					}`
				}
            `;
		case 'highlight4':
			return `
                background-color: ${theme.colors.highlight4.background.default};
                color: ${theme.colors.highlight4.text.default};
                fill: ${theme.colors.highlight4.icon.default};

				${
					clickable &&
					`&:hover {
						background-color: ${theme.colors.highlight4.background.strong};
					}`
				}
            `;
		case 'highlight5':
			return `
                background-color: ${theme.colors.highlight5.background.default};
                color: ${theme.colors.highlight5.text.default};
                fill: ${theme.colors.highlight5.icon.default};

				${
					clickable &&
					`&:hover {
						background-color: ${theme.colors.highlight5.background.strong};
					}`
				}
            `;
	}
};

const getVariantSizing = (
	theme: typeof GoodkindDSTheme,
	size: string,
	iconBadge: boolean,
) => {
	switch (size) {
		case 'small':
			return `
                font-size: ${theme.fonts.sizes.xs};
				height: 22px;
				width: ${iconBadge ? '22px' : 'max-content'};
				flex: 0 0 ${iconBadge ? '22px' : 'max-content'};
                padding: ${
									iconBadge ? '0' : `0 6px` //TODO: Review 6px usage in spacing on theme
								};
            `;
		case 'large':
			return `
                font-size: ${theme.fonts.sizes.small};
				height: 28px;
				width: ${iconBadge ? '28px' : 'max-content'};
				flex: 0 0 ${iconBadge ? '28px' : 'max-content'};
                padding: ${
									iconBadge ? '0' : `0 10px` //TODO: Review 10px usage in spacing on theme
								};
            `;
		default:
			return `
                font-size: ${theme.fonts.sizes.small};
				height: 24px;
				width: ${iconBadge ? '24px' : 'max-content'};
				flex: 0 0 ${iconBadge ? '24px' : 'max-content'};
                padding: ${iconBadge ? '0' : `0 ${theme.spacing.xs}`};
            `;
	}
};

const getBorderRadius = (
	theme: typeof GoodkindDSTheme,
	iconBadge: boolean,
	squared?: boolean,
	size?: string,
) => {
	if (size === 'large') {
		return squared ? '7px' : theme.shapeRadius.xxl;
	}

	if (iconBadge) {
		return squared ? '6px' : theme.shapeRadius[50];
	}

	return squared ? '6px' : theme.shapeRadius.xxl;
};

export const BadgeContainer = styled.div<{
	variant: string;
	size: string;
	iconBadge: boolean;
	hasIcon: boolean;
	dropdownBadge?: boolean;
	squared?: boolean;
	onClick?: () => void;
}>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	column-gap: ${({ theme, dropdownBadge }) =>
		dropdownBadge ? theme.spacing.xxs : theme.spacing.xs};
	flex-wrap: wrap;
	background-color: ${({ theme }) => theme.colors.neutral.border.default};
	border-radius: ${({ theme, iconBadge, squared, size }) =>
		getBorderRadius(theme, iconBadge, squared, size)};
	width: max-content;
	flex: 0 0 max-content;
	cursor: ${({ dropdownBadge, onClick }) =>
		dropdownBadge || !!onClick ? 'pointer' : 'default'};

	${({ theme, size, iconBadge }) => getVariantSizing(theme, size, iconBadge)}
	${({ theme, variant, onClick, dropdownBadge }) =>
		getVariantStyles(theme, variant, !!onClick || dropdownBadge)}
`;

export const RemoveIconContainer = styled.button`
	color: inherit;
	transition: ${({ theme }) => theme.transitions.ease01};
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: 0;

	:hover {
		opacity: 0.8;
	}
`;
