import styled, { css } from 'styled-components';

export const Container = styled.div<{
	active?: boolean;
	width?: string;
	height?: string;
	hasBorder?: boolean;
	hasSecondaryImage?: boolean;
}>`
	position: relative;
	width: ${({ width }) => (width ? width : '192px')};
	height: ${({ height }) => (height ? height : '120px')};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.system.background.strong};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	border: ${({ theme, hasBorder, active }) => {
		if (!hasBorder) return '';

		if (active) return `1.5px solid ${theme.colors.primary.border.default}`;

		return `1px solid ${theme.colors.system.border.weak}`;
	}};
	overflow: hidden;

	.primary,
	.secondary {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.secondary {
		opacity: 0;
		visibility: hidden;
	}

	&:hover {
		${({ hasSecondaryImage }) => {
			if (!hasSecondaryImage) return '';

			return css`
				.secondary {
					opacity: 1;
					transition: ease-in-out opacity 50ms;
					visibility: visible;
				}
			`;
		}}
	}
`;

export const Image = styled.div<{ image?: string }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(${({ image }) => image});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;

export const CloseIcon = styled.div`
	position: absolute;
	top: 0;
	right: 0;
`;
