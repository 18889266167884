import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { FloatingPortal } from '@floating-ui/react';

import '@yaireo/tagify/dist/tagify.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { MixedTags } from '@yaireo/tagify/dist/react.tagify';
import Tagify, { TagData, TagifySettings } from '@yaireo/tagify';
import * as S from './TagsInput.styles';

import { TagsInputTooltip } from './components';
import {
	checkHasCurlyBracesMergeTags,
	checkHasSquareBracketMergeTags,
	checkMergeTagsHasSpacingWarning,
	cleanTextWithSpaceRemoval,
} from './utils';

import {
	allowedKeys,
	GrammarWarningEnum,
	MERGE_TAG_INVALID_FORMAT_WARNING,
} from './constants';
import useFloatingUi from '@app/hooks/useFloatingUi';
import {
	Asterisk,
	Label,
	SquareIconButton,
	Text,
} from '@common/design-system/components/atoms';
import {
	OptionsType,
	TagsInputEventType,
	TagsInputOrientationType,
} from './types';
import { InputPropsType } from '../Input/Input';

export type TagsInputProps = {
	tooltipOrientation?: TagsInputOrientationType;
	tagOptions?: string[] | TagData[];
	initialState?: string;
	maxLength?: number;
	onChange: (e: TagsInputEventType) => void; //value comes from e.detail.value
	onFocus?: (e: TagsInputEventType) => void;
	onBlur?: (e?: TagsInputEventType) => void;
	textArea?: boolean;
	rows?: number;
	labelText?: string;
	labelInfo?: string;
	hasError?: boolean;
	message?: string;
	name?: string;
	disabled?: boolean;
	disableTagsButton?: boolean;
	disableTagsWarning?: boolean;
	required?: boolean;
	description?: string;
	placeholder?: string;
	size?: InputPropsType['size'];
	onRefCallback?: (ref?: any) => any;
	hideTooltipTabs?: boolean;
	onGrammarWarnings?: (warnings: { [key: string]: boolean }) => void;
	noMessages?: boolean;
};

const TagsInput = (
	{
		tagOptions = [],
		onChange,
		onFocus,
		onBlur,
		initialState = '',
		tooltipOrientation = 'right',
		maxLength,
		textArea,
		rows = 2,
		labelText,
		labelInfo,
		hasError,
		message,
		disableTagsWarning,
		name,
		disabled,
		disableTagsButton,
		required,
		description,
		placeholder,
		size = 'medium',
		onRefCallback,
		hideTooltipTabs,
		onGrammarWarnings,
		noMessages,
		...rest
	}: TagsInputProps,
	ref: any,
) => {
	const [focused, setFocused] = useState(false);
	const [editMode, setEditMode] = useState(false);

	const [grammarWarnings, setGrammarWarning] = useState({
		[GrammarWarningEnum.MERGE_TAG_SPACE_AROUND]: false,
		[GrammarWarningEnum.MERGE_TAG_SQUARE_BRACKET]: false,
		[GrammarWarningEnum.MERGE_TAG_CURLY_BRACES]: false,
	});

	const [internalTagifyValue, setInternalTagifyValue] = useState(initialState);

	// Tooltip opening using floating UI
	const {
		x,
		y,
		openingReference,
		floatingReference,
		strategy,
		internalRefs,
		isOpen: isTooltipOpened,
		setIsOpen: setIsTooltipOpened,
		getFloatingProps,
		getReferenceProps,
	} = useFloatingUi({
		preferredPlacement: tooltipOrientation,
	});

	const hasValue = useMemo(
		() => !!internalTagifyValue && internalTagifyValue.length >= 1,
		[internalTagifyValue],
	);

	const handleInputWarnings = (text: string) => {
		const hasSpacingWarning = checkMergeTagsHasSpacingWarning(text);
		const hasSquareBracketMergeTag = checkHasSquareBracketMergeTags(text);
		const hasCurlyBracesMergeTag = checkHasCurlyBracesMergeTags(text);

		const warnings = {
			...grammarWarnings,
			[GrammarWarningEnum.MERGE_TAG_SPACE_AROUND]: hasSpacingWarning,
			[GrammarWarningEnum.MERGE_TAG_SQUARE_BRACKET]: hasSquareBracketMergeTag,
			[GrammarWarningEnum.MERGE_TAG_CURLY_BRACES]: hasCurlyBracesMergeTag,
		};

		setGrammarWarning(warnings);
	};

	useEffect(() => {
		handleInputWarnings(internalTagifyValue);
	}, [internalTagifyValue]);

	const grammarWarningsObj = useMemo(() => grammarWarnings, [grammarWarnings]);

	const hasWarning = useMemo(
		() =>
			grammarWarningsObj[GrammarWarningEnum.MERGE_TAG_SPACE_AROUND] ||
			grammarWarningsObj[GrammarWarningEnum.MERGE_TAG_SQUARE_BRACKET] ||
			grammarWarningsObj[GrammarWarningEnum.MERGE_TAG_CURLY_BRACES],
		[grammarWarningsObj],
	);

	const textsColor = hasError
		? 'error.text.default'
		: hasWarning
			? 'warning.text.default'
			: 'system.text.medium';
	const labelColor = hasError ? 'error.text.default' : 'system.text.default';

	/** References **/
	const tagRef = useRef<Tagify>();

	useEffect(() => {
		if (tagRef.current && onRefCallback) {
			onRefCallback(tagRef.current);
		}
	}, [tagRef, onRefCallback]);

	/**  Tagify settings **/
	const settings = useMemo(() => {
		const settingsConfig: TagifySettings = {
			pattern: '', // <- must define "patten" in mixed mode
			mode: 'mix',
			dropdown: {
				enabled: false,
			},
			tagTextProp: 'label',
			duplicates: true,
			whitelist: tagOptions,
			mixTagsInterpolator: ['{{{', '}}}'],
			editTags: false,
			originalInputValueFormat: (tagData: any) => tagData.value,
			transformTag: (tagData) => {
				if (tagData && tagData.value && tagOptions) {
					if (tagData.value === tagData.label) {
						const duplicatedOptions: any = [...tagOptions];

						const tagOption = duplicatedOptions?.find(
							(option: TagData) => option.value === tagData.value,
						);

						tagData.label = tagOption ? tagOption.label : tagData.label;
					}
				}
			},
		};

		return settingsConfig;
	}, [tagOptions]);

	/** Input state handler */
	const handleOnChange = useCallback(
		(e: TagsInputEventType) => {
			if (disabled) return;

			const fixedValue = e.detail.value.trim(); //Fix new line (\n) include on Tagify: https://github.com/yairEO/tagify/issues/983#issuecomment-1038799516
			const valueReplaced = cleanTextWithSpaceRemoval(fixedValue);

			const event = {
				...e,
				detail: {
					...e.detail,
					value: valueReplaced,
				},
			};

			if (onChange) {
				onChange(event);
			}

			setInternalTagifyValue(valueReplaced);
		},
		[disabled, onChange, setInternalTagifyValue],
	);

	const handleFocus = useCallback(
		(e: TagsInputEventType) => {
			setFocused(true);
			setEditMode(true);
			if (onFocus) {
				onFocus(e);
			}
		},
		[setFocused, setEditMode],
	);

	const handleBlur = useCallback(() => {
		setFocused(false);
		setEditMode(false);

		if (onBlur) {
			onBlur();
		}
	}, [setFocused, setEditMode]);

	const onAddTag = (value: TagData | HTMLElement, isEmoji?: boolean) => {
		const tgc = tagRef.current;
		if (tgc) {
			let tagElm: HTMLElement | string;
			const isATag = !isEmoji;

			if (isATag) tagElm = tgc.createTagElem(value as TagData);
			else tagElm = value as HTMLElement;

			tgc.injectAtCaret(tagElm);

			const elm = tgc.insertAfterTag(tagElm as HTMLElement, '');

			elm && tgc.placeCaretAfterNode(elm);

			setIsTooltipOpened(false);
		}
	};

	/** Place external value when initialState change **/
	useEffect(() => {
		const tgc = tagRef.current;

		if (tgc && !editMode) {
			tgc.loadOriginalValues(initialState);
			setInternalTagifyValue(initialState);
		}
	}, [initialState]);

	/**  Insert event to check maxLength of the input **/
	useEffect(() => {
		const tgc = tagRef.current;

		if (tgc) {
			tgc.DOM.input.addEventListener('keydown', (e) => {
				const keyIsNotAllowed = !allowedKeys.includes(e.key);
				// @ts-expect-error TS(2532): Object is possibly 'undefined'.
				const reachedMaxLength = tgc.DOM.originalInput.value.length > maxLength;

				if (keyIsNotAllowed && reachedMaxLength) {
					e.preventDefault();
				}
			});
		}
	}, [tagRef]);

	useEffect(() => {
		tagRef?.current?.setDisabled(Boolean(disabled));
	}, [disabled]);

	// It will send as callback the grammar warnings
	useEffect(() => {
		onGrammarWarnings && onGrammarWarnings(grammarWarningsObj);
	}, [grammarWarningsObj]);

	return (
		<S.TagWrapper
			textArea={textArea}
			textAreaRows={rows}
			isOpenButtonVisible={tagOptions && !disableTagsButton}
			hasFocus={focused}
			hasValue={hasValue}
			hasLabel={!!labelText}
			disabled={disabled}
			hasError={hasError}
			size={size}
			ref={ref}
		>
			{(labelText || description) && (
				<S.LabelWrapper>
					{labelText && (
						<Label
							htmlFor={name}
							size="small"
							color={labelColor}
							tooltipInfo={labelInfo}
							mediumBold
						>
							{labelText}
							{required && <Asterisk />}
						</Label>
					)}

					{description && (
						<Text size="small" color={textsColor}>
							{description}
						</Text>
					)}
				</S.LabelWrapper>
			)}

			<S.InputAndButtonWrapper>
				<MixedTags
					tagifyRef={tagRef}
					settings={settings}
					className="myTags"
					onChange={handleOnChange}
					defaultValue={initialState}
					name={name}
					onFocus={handleFocus}
					onBlur={handleBlur}
					placeholder={placeholder}
					{...rest}
				/>

				{tagOptions && !disableTagsButton && (
					<>
						<S.ButtonContainer
							textArea={textArea}
							ref={openingReference}
							inputSize={size}
							{...getReferenceProps()}
						>
							<SquareIconButton
								icon="plus"
								size="xs"
								variant="neutral"
								ariaLabel={'Add tag'}
							/>
						</S.ButtonContainer>

						<FloatingPortal>
							{isTooltipOpened && (
								<TagsInputTooltip
									hideTabs={hideTooltipTabs}
									tooltipRef={floatingReference}
									style={{
										position: strategy,
										top: y ?? 0,
										left: x ?? 0,
									}}
									tagOptions={tagOptions as OptionsType[]} //TODO: type
									onSelect={(tag, isEmoji) => onAddTag(tag, isEmoji)}
									{...getFloatingProps()}
								/>
							)}
						</FloatingPortal>
					</>
				)}
			</S.InputAndButtonWrapper>

			{!noMessages && (message || hasWarning) && (
				<Text size="small" lineHeight="large" color={textsColor}>
					{message ||
						(grammarWarningsObj[GrammarWarningEnum.MERGE_TAG_SPACE_AROUND] &&
							'Please review spacing around merge tag(s)') ||
						((grammarWarningsObj[GrammarWarningEnum.MERGE_TAG_SQUARE_BRACKET] ||
							grammarWarningsObj[GrammarWarningEnum.MERGE_TAG_CURLY_BRACES]) &&
							MERGE_TAG_INVALID_FORMAT_WARNING)}
				</Text>
			)}
		</S.TagWrapper>
	);
};

export default React.forwardRef<
	HTMLInputElement | HTMLTextAreaElement,
	TagsInputProps
>(TagsInput);
