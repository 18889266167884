export const timezones = [
	{ label: 'Africa/Abidjan', value: 'Africa/Abidjan' },
	{ label: 'Africa/Accra', value: 'Africa/Accra' },
	{ label: 'Africa/Algiers', value: 'Africa/Algiers' },
	{ label: 'Africa/Bissau', value: 'Africa/Bissau' },
	{ label: 'Africa/Cairo', value: 'Africa/Cairo' },
	{
		label: 'Africa/Casablanca',
		value: 'Africa/Casablanca',
	},
	{ label: 'Africa/Ceuta', value: 'Africa/Ceuta' },
	{ label: 'Africa/El_Aaiun', value: 'Africa/El_Aaiun' },
	{
		label: 'Africa/Johannesburg',
		value: 'Africa/Johannesburg',
	},
	{ label: 'Africa/Juba', value: 'Africa/Juba' },
	{ label: 'Africa/Khartoum', value: 'Africa/Khartoum' },
	{ label: 'Africa/Lagos', value: 'Africa/Lagos' },
	{ label: 'Africa/Maputo', value: 'Africa/Maputo' },
	{ label: 'Africa/Monrovia', value: 'Africa/Monrovia' },
	{ label: 'Africa/Nairobi', value: 'Africa/Nairobi' },
	{ label: 'Africa/Ndjamena', value: 'Africa/Ndjamena' },
	{ label: 'Africa/Sao_Tome', value: 'Africa/Sao_Tome' },
	{ label: 'Africa/Tripoli', value: 'Africa/Tripoli' },
	{ label: 'Africa/Tunis', value: 'Africa/Tunis' },
	{ label: 'Africa/Windhoek', value: 'Africa/Windhoek' },
	{ label: 'America/Adak', value: 'America/Adak' },
	{
		label: 'America/Anchorage',
		value: 'America/Anchorage',
	},
	{
		label: 'America/Araguaina',
		value: 'America/Araguaina',
	},
	{
		label: 'America/Argentina/Buenos_Aires',
		value: 'America/Argentina/Buenos_Aires',
	},
	{
		label: 'America/Argentina/Catamarca',
		value: 'America/Argentina/Catamarca',
	},
	{
		label: 'America/Argentina/Cordoba',
		value: 'America/Argentina/Cordoba',
	},
	{
		label: 'America/Argentina/Jujuy',
		value: 'America/Argentina/Jujuy',
	},
	{
		label: 'America/Argentina/La_Rioja',
		value: 'America/Argentina/La_Rioja',
	},
	{
		label: 'America/Argentina/Mendoza',
		value: 'America/Argentina/Mendoza',
	},
	{
		label: 'America/Argentina/Rio_Gallegos',
		value: 'America/Argentina/Rio_Gallegos',
	},
	{
		label: 'America/Argentina/Salta',
		value: 'America/Argentina/Salta',
	},
	{
		label: 'America/Argentina/San_Juan',
		value: 'America/Argentina/San_Juan',
	},
	{
		label: 'America/Argentina/San_Luis',
		value: 'America/Argentina/San_Luis',
	},
	{
		label: 'America/Argentina/Tucuman',
		value: 'America/Argentina/Tucuman',
	},
	{
		label: 'America/Argentina/Ushuaia',
		value: 'America/Argentina/Ushuaia',
	},
	{
		label: 'America/Asuncion',
		value: 'America/Asuncion',
	},
	{
		label: 'America/Atikokan',
		value: 'America/Atikokan',
	},
	{ label: 'America/Bahia', value: 'America/Bahia' },
	{
		label: 'America/Bahia_Banderas',
		value: 'America/Bahia_Banderas',
	},
	{
		label: 'America/Barbados',
		value: 'America/Barbados',
	},
	{ label: 'America/Belem', value: 'America/Belem' },
	{ label: 'America/Belize', value: 'America/Belize' },
	{
		label: 'America/Blanc-Sablon',
		value: 'America/Blanc-Sablon',
	},
	{
		label: 'America/Boa_Vista',
		value: 'America/Boa_Vista',
	},
	{ label: 'America/Bogota', value: 'America/Bogota' },
	{ label: 'America/Boise', value: 'America/Boise' },
	{
		label: 'America/Cambridge_Bay',
		value: 'America/Cambridge_Bay',
	},
	{
		label: 'America/Campo_Grande',
		value: 'America/Campo_Grande',
	},
	{ label: 'America/Cancun', value: 'America/Cancun' },
	{ label: 'America/Caracas', value: 'America/Caracas' },
	{ label: 'America/Cayenne', value: 'America/Cayenne' },
	{ label: 'America/Chicago', value: 'America/Chicago' },
	{
		label: 'America/Chihuahua',
		value: 'America/Chihuahua',
	},
	{
		label: 'America/Costa_Rica',
		value: 'America/Costa_Rica',
	},
	{ label: 'America/Creston', value: 'America/Creston' },
	{ label: 'America/Cuiaba', value: 'America/Cuiaba' },
	{ label: 'America/Curacao', value: 'America/Curacao' },
	{
		label: 'America/Danmarkshavn',
		value: 'America/Danmarkshavn',
	},
	{ label: 'America/Dawson', value: 'America/Dawson' },
	{
		label: 'America/Dawson_Creek',
		value: 'America/Dawson_Creek',
	},
	{ label: 'America/Denver', value: 'America/Denver' },
	{ label: 'America/Detroit', value: 'America/Detroit' },
	{
		label: 'America/Edmonton',
		value: 'America/Edmonton',
	},
	{
		label: 'America/Eirunepe',
		value: 'America/Eirunepe',
	},
	{
		label: 'America/El_Salvador',
		value: 'America/El_Salvador',
	},
	{
		label: 'America/Fort_Nelson',
		value: 'America/Fort_Nelson',
	},
	{
		label: 'America/Fortaleza',
		value: 'America/Fortaleza',
	},
	{
		label: 'America/Glace_Bay',
		value: 'America/Glace_Bay',
	},
	{
		label: 'America/Goose_Bay',
		value: 'America/Goose_Bay',
	},
	{
		label: 'America/Grand_Turk',
		value: 'America/Grand_Turk',
	},
	{
		label: 'America/Guatemala',
		value: 'America/Guatemala',
	},
	{
		label: 'America/Guayaquil',
		value: 'America/Guayaquil',
	},
	{ label: 'America/Guyana', value: 'America/Guyana' },
	{ label: 'America/Halifax', value: 'America/Halifax' },
	{ label: 'America/Havana', value: 'America/Havana' },
	{
		label: 'America/Hermosillo',
		value: 'America/Hermosillo',
	},
	{
		label: 'America/Indiana/Indianapolis',
		value: 'America/Indiana/Indianapolis',
	},
	{
		label: 'America/Indiana/Knox',
		value: 'America/Indiana/Knox',
	},
	{
		label: 'America/Indiana/Marengo',
		value: 'America/Indiana/Marengo',
	},
	{
		label: 'America/Indiana/Petersburg',
		value: 'America/Indiana/Petersburg',
	},
	{
		label: 'America/Indiana/Tell_City',
		value: 'America/Indiana/Tell_City',
	},
	{
		label: 'America/Indiana/Vevay',
		value: 'America/Indiana/Vevay',
	},
	{
		label: 'America/Indiana/Vincennes',
		value: 'America/Indiana/Vincennes',
	},
	{
		label: 'America/Indiana/Winamac',
		value: 'America/Indiana/Winamac',
	},
	{ label: 'America/Inuvik', value: 'America/Inuvik' },
	{ label: 'America/Iqaluit', value: 'America/Iqaluit' },
	{ label: 'America/Jamaica', value: 'America/Jamaica' },
	{ label: 'America/Juneau', value: 'America/Juneau' },
	{
		label: 'America/Kentucky/Louisville',
		value: 'America/Kentucky/Louisville',
	},
	{
		label: 'America/Kentucky/Monticello',
		value: 'America/Kentucky/Monticello',
	},
	{ label: 'America/La_Paz', value: 'America/La_Paz' },
	{ label: 'America/Lima', value: 'America/Lima' },
	{
		label: 'America/Los_Angeles',
		value: 'America/Los_Angeles',
	},
	{ label: 'America/Maceio', value: 'America/Maceio' },
	{ label: 'America/Managua', value: 'America/Managua' },
	{ label: 'America/Manaus', value: 'America/Manaus' },
	{
		label: 'America/Martinique',
		value: 'America/Martinique',
	},
	{
		label: 'America/Matamoros',
		value: 'America/Matamoros',
	},
	{
		label: 'America/Mazatlan',
		value: 'America/Mazatlan',
	},
	{
		label: 'America/Menominee',
		value: 'America/Menominee',
	},
	{ label: 'America/Merida', value: 'America/Merida' },
	{
		label: 'America/Metlakatla',
		value: 'America/Metlakatla',
	},
	{
		label: 'America/Mexico_City',
		value: 'America/Mexico_City',
	},
	{
		label: 'America/Miquelon',
		value: 'America/Miquelon',
	},
	{ label: 'America/Moncton', value: 'America/Moncton' },
	{
		label: 'America/Monterrey',
		value: 'America/Monterrey',
	},
	{
		label: 'America/Montevideo',
		value: 'America/Montevideo',
	},
	{ label: 'America/Nassau', value: 'America/Nassau' },
	{
		label: 'America/New_York',
		value: 'America/New_York',
	},
	{ label: 'America/Nipigon', value: 'America/Nipigon' },
	{ label: 'America/Nome', value: 'America/Nome' },
	{ label: 'America/Noronha', value: 'America/Noronha' },
	{ label: 'America/Nuuk', value: 'America/Nuuk' },
	{ label: 'America/Ojinaga', value: 'America/Ojinaga' },
	{ label: 'America/Panama', value: 'America/Panama' },
	{
		label: 'America/Pangnirtung',
		value: 'America/Pangnirtung',
	},
	{
		label: 'America/Paramaribo',
		value: 'America/Paramaribo',
	},
	{ label: 'America/Phoenix', value: 'America/Phoenix' },
	{
		label: 'America/Port-au-Prince',
		value: 'America/Port-au-Prince',
	},
	{
		label: 'America/Port_of_Spain',
		value: 'America/Port_of_Spain',
	},
	{
		label: 'America/Porto_Velho',
		value: 'America/Porto_Velho',
	},
	{
		label: 'America/Puerto_Rico',
		value: 'America/Puerto_Rico',
	},
	{
		label: 'America/Punta_Arenas',
		value: 'America/Punta_Arenas',
	},
	{
		label: 'America/Rainy_River',
		value: 'America/Rainy_River',
	},
	{
		label: 'America/Rankin_Inlet',
		value: 'America/Rankin_Inlet',
	},
	{ label: 'America/Recife', value: 'America/Recife' },
	{ label: 'America/Regina', value: 'America/Regina' },
	{
		label: 'America/Resolute',
		value: 'America/Resolute',
	},
	{
		label: 'America/Rio_Branco',
		value: 'America/Rio_Branco',
	},
	{
		label: 'America/Santarem',
		value: 'America/Santarem',
	},
	{
		label: 'America/Santiago',
		value: 'America/Santiago',
	},
	{
		label: 'America/Santo_Domingo',
		value: 'America/Santo_Domingo',
	},
	{
		label: 'America/Sao_Paulo',
		value: 'America/Sao_Paulo',
	},
	{
		label: 'America/Scoresbysund',
		value: 'America/Scoresbysund',
	},
	{ label: 'America/Sitka', value: 'America/Sitka' },
	{
		label: 'America/St_Johns',
		value: 'America/St_Johns',
	},
	{
		label: 'America/Swift_Current',
		value: 'America/Swift_Current',
	},
	{
		label: 'America/Tegucigalpa',
		value: 'America/Tegucigalpa',
	},
	{ label: 'America/Thule', value: 'America/Thule' },
	{
		label: 'America/Thunder_Bay',
		value: 'America/Thunder_Bay',
	},
	{ label: 'America/Tijuana', value: 'America/Tijuana' },
	{ label: 'America/Toronto', value: 'America/Toronto' },
	{
		label: 'America/Vancouver',
		value: 'America/Vancouver',
	},
	{
		label: 'America/Whitehorse',
		value: 'America/Whitehorse',
	},
	{
		label: 'America/Winnipeg',
		value: 'America/Winnipeg',
	},
	{ label: 'America/Yakutat', value: 'America/Yakutat' },
	{
		label: 'America/Yellowknife',
		value: 'America/Yellowknife',
	},
	{
		label: 'Antarctica/Casey',
		value: 'Antarctica/Casey',
	},
	{
		label: 'Antarctica/Davis',
		value: 'Antarctica/Davis',
	},
	{
		label: 'Antarctica/DumontDUrville',
		value: 'Antarctica/DumontDUrville',
	},
	{
		label: 'Antarctica/Macquarie',
		value: 'Antarctica/Macquarie',
	},
	{
		label: 'Antarctica/Mawson',
		value: 'Antarctica/Mawson',
	},
	{
		label: 'Antarctica/Palmer',
		value: 'Antarctica/Palmer',
	},
	{
		label: 'Antarctica/Rothera',
		value: 'Antarctica/Rothera',
	},
	{
		label: 'Antarctica/Syowa',
		value: 'Antarctica/Syowa',
	},
	{
		label: 'Antarctica/Troll',
		value: 'Antarctica/Troll',
	},
	{
		label: 'Antarctica/Vostok',
		value: 'Antarctica/Vostok',
	},
	{
		label: 'Arctic/Longyearbyen',
		value: 'Arctic/Longyearbyen',
	},
	{ label: 'Asia/Aden', value: 'Asia/Aden' },
	{ label: 'Asia/Almaty', value: 'Asia/Almaty' },
	{ label: 'Asia/Amman', value: 'Asia/Amman' },
	{ label: 'Asia/Anadyr', value: 'Asia/Anadyr' },
	{ label: 'Asia/Aqtau', value: 'Asia/Aqtau' },
	{ label: 'Asia/Aqtobe', value: 'Asia/Aqtobe' },
	{ label: 'Asia/Ashgabat', value: 'Asia/Ashgabat' },
	{ label: 'Asia/Atyrau', value: 'Asia/Atyrau' },
	{ label: 'Asia/Baghdad', value: 'Asia/Baghdad' },
	{ label: 'Asia/Bahrain', value: 'Asia/Bahrain' },
	{ label: 'Asia/Baku', value: 'Asia/Baku' },
	{ label: 'Asia/Bangkok', value: 'Asia/Bangkok' },
	{ label: 'Asia/Barnaul', value: 'Asia/Barnaul' },
	{ label: 'Asia/Beirut', value: 'Asia/Beirut' },
	{ label: 'Asia/Bishkek', value: 'Asia/Bishkek' },
	{ label: 'Asia/Brunei', value: 'Asia/Brunei' },
	{ label: 'Asia/Chita', value: 'Asia/Chita' },
	{ label: 'Asia/Choibalsan', value: 'Asia/Choibalsan' },
	{ label: 'Asia/Colombo', value: 'Asia/Colombo' },
	{ label: 'Asia/Damascus', value: 'Asia/Damascus' },
	{ label: 'Asia/Dhaka', value: 'Asia/Dhaka' },
	{ label: 'Asia/Dili', value: 'Asia/Dili' },
	{ label: 'Asia/Dubai', value: 'Asia/Dubai' },
	{ label: 'Asia/Dushanbe', value: 'Asia/Dushanbe' },
	{ label: 'Asia/Famagusta', value: 'Asia/Famagusta' },
	{ label: 'Asia/Gaza', value: 'Asia/Gaza' },
	{ label: 'Asia/Hebron', value: 'Asia/Hebron' },
	{
		label: 'Asia/Ho_Chi_Minh',
		value: 'Asia/Ho_Chi_Minh',
	},
	{ label: 'Asia/Hong_Kong', value: 'Asia/Hong_Kong' },
	{ label: 'Asia/Hovd', value: 'Asia/Hovd' },
	{ label: 'Asia/Irkutsk', value: 'Asia/Irkutsk' },
	{ label: 'Asia/Jakarta', value: 'Asia/Jakarta' },
	{ label: 'Asia/Jayapura', value: 'Asia/Jayapura' },
	{ label: 'Asia/Jerusalem', value: 'Asia/Jerusalem' },
	{ label: 'Asia/Kabul', value: 'Asia/Kabul' },
	{ label: 'Asia/Kamchatka', value: 'Asia/Kamchatka' },
	{ label: 'Asia/Karachi', value: 'Asia/Karachi' },
	{
		label: 'Asia/Kathmandu',
		value: 'Asia/Kathmandu',
	},
	{ label: 'Asia/Khandyga', value: 'Asia/Khandyga' },
	{ label: 'Asia/Kolkata', value: 'Asia/Kolkata' },
	{
		label: 'Asia/Krasnoyarsk',
		value: 'Asia/Krasnoyarsk',
	},
	{
		label: 'Asia/Kuala_Lumpur',
		value: 'Asia/Kuala_Lumpur',
	},
	{ label: 'Asia/Kuching', value: 'Asia/Kuching' },
	{ label: 'Asia/Kuwait', value: 'Asia/Kuwait' },
	{ label: 'Asia/Macau', value: 'Asia/Macau' },
	{ label: 'Asia/Magadan', value: 'Asia/Magadan' },
	{ label: 'Asia/Makassar', value: 'Asia/Makassar' },
	{ label: 'Asia/Manila', value: 'Asia/Manila' },
	{ label: 'Asia/Muscat', value: 'Asia/Muscat' },
	{ label: 'Asia/Nicosia', value: 'Asia/Nicosia' },
	{
		label: 'Asia/Novokuznetsk',
		value: 'Asia/Novokuznetsk',
	},
	{
		label: 'Asia/Novosibirsk',
		value: 'Asia/Novosibirsk',
	},
	{ label: 'Asia/Omsk', value: 'Asia/Omsk' },
	{ label: 'Asia/Oral', value: 'Asia/Oral' },
	{ label: 'Asia/Phnom_Penh', value: 'Asia/Phnom_Penh' },
	{ label: 'Asia/Pontianak', value: 'Asia/Pontianak' },
	{ label: 'Asia/Pyongyang', value: 'Asia/Pyongyang' },
	{ label: 'Asia/Qatar', value: 'Asia/Qatar' },
	{ label: 'Asia/Qyzylorda', value: 'Asia/Qyzylorda' },
	{ label: 'Asia/Riyadh', value: 'Asia/Riyadh' },
	{ label: 'Asia/Sakhalin', value: 'Asia/Sakhalin' },
	{ label: 'Asia/Samarkand', value: 'Asia/Samarkand' },
	{ label: 'Asia/Seoul', value: 'Asia/Seoul' },
	{ label: 'Asia/Shanghai', value: 'Asia/Shanghai' },
	{ label: 'Asia/Singapore', value: 'Asia/Singapore' },
	{
		label: 'Asia/Srednekolymsk',
		value: 'Asia/Srednekolymsk',
	},
	{ label: 'Asia/Taipei', value: 'Asia/Taipei' },
	{ label: 'Asia/Tashkent', value: 'Asia/Tashkent' },
	{ label: 'Asia/Tbilisi', value: 'Asia/Tbilisi' },
	{ label: 'Asia/Tehran', value: 'Asia/Tehran' },
	{ label: 'Asia/Thimphu', value: 'Asia/Thimphu' },
	{ label: 'Asia/Tokyo', value: 'Asia/Tokyo' },
	{ label: 'Asia/Tomsk', value: 'Asia/Tomsk' },
	{
		label: 'Asia/Ulaanbaatar',
		value: 'Asia/Ulaanbaatar',
	},
	{ label: 'Asia/Urumqi', value: 'Asia/Urumqi' },
	{ label: 'Asia/Ust-Nera', value: 'Asia/Ust-Nera' },
	{ label: 'Asia/Vientiane', value: 'Asia/Vientiane' },
	{
		label: 'Asia/Vladivostok',
		value: 'Asia/Vladivostok',
	},
	{ label: 'Asia/Yakutsk', value: 'Asia/Yakutsk' },
	{ label: 'Asia/Yangon', value: 'Asia/Yangon' },
	{
		label: 'Asia/Yekaterinburg',
		value: 'Asia/Yekaterinburg',
	},
	{ label: 'Asia/Yerevan', value: 'Asia/Yerevan' },
	{ label: 'Atlantic/Azores', value: 'Atlantic/Azores' },
	{
		label: 'Atlantic/Bermuda',
		value: 'Atlantic/Bermuda',
	},
	{ label: 'Atlantic/Canary', value: 'Atlantic/Canary' },
	{
		label: 'Atlantic/Cape_Verde',
		value: 'Atlantic/Cape_Verde',
	},
	{ label: 'Atlantic/Faroe', value: 'Atlantic/Faroe' },
	{
		label: 'Atlantic/Madeira',
		value: 'Atlantic/Madeira',
	},
	{
		label: 'Atlantic/Reykjavik',
		value: 'Atlantic/Reykjavik',
	},
	{
		label: 'Atlantic/South_Georgia',
		value: 'Atlantic/South_Georgia',
	},
	{
		label: 'Atlantic/Stanley',
		value: 'Atlantic/Stanley',
	},
	{
		label: 'Australia/Adelaide',
		value: 'Australia/Adelaide',
	},
	{
		label: 'Australia/Brisbane',
		value: 'Australia/Brisbane',
	},
	{
		label: 'Australia/Broken_Hill',
		value: 'Australia/Broken_Hill',
	},
	{
		label: 'Australia/Currie',
		value: 'Australia/Currie',
	},
	{
		label: 'Australia/Darwin',
		value: 'Australia/Darwin',
	},
	{
		label: 'Australia/Eucla',
		value: 'Australia/Eucla',
	},
	{
		label: 'Australia/Hobart',
		value: 'Australia/Hobart',
	},
	{
		label: 'Australia/Lindeman',
		value: 'Australia/Lindeman',
	},
	{
		label: 'Australia/Lord_Howe',
		value: 'Australia/Lord_Howe',
	},
	{
		label: 'Australia/Melbourne',
		value: 'Australia/Melbourne',
	},
	{ label: 'Australia/Perth', value: 'Australia/Perth' },
	{
		label: 'Australia/Sydney',
		value: 'Australia/Sydney',
	},
	{
		label: 'Europe/Amsterdam',
		value: 'Europe/Amsterdam',
	},
	{ label: 'Europe/Andorra', value: 'Europe/Andorra' },
	{
		label: 'Europe/Astrakhan',
		value: 'Europe/Astrakhan',
	},
	{ label: 'Europe/Athens', value: 'Europe/Athens' },
	{ label: 'Europe/Belgrade', value: 'Europe/Belgrade' },
	{ label: 'Europe/Berlin', value: 'Europe/Berlin' },
	{
		label: 'Europe/Bratislava',
		value: 'Europe/Bratislava',
	},
	{ label: 'Europe/Brussels', value: 'Europe/Brussels' },
	{
		label: 'Europe/Bucharest',
		value: 'Europe/Bucharest',
	},
	{ label: 'Europe/Budapest', value: 'Europe/Budapest' },
	{ label: 'Europe/Busingen', value: 'Europe/Busingen' },
	{ label: 'Europe/Chisinau', value: 'Europe/Chisinau' },
	{
		label: 'Europe/Copenhagen',
		value: 'Europe/Copenhagen',
	},
	{ label: 'Europe/Dublin', value: 'Europe/Dublin' },
	{
		label: 'Europe/Gibraltar',
		value: 'Europe/Gibraltar',
	},
	{ label: 'Europe/Guernsey', value: 'Europe/Guernsey' },
	{ label: 'Europe/Helsinki', value: 'Europe/Helsinki' },
	{
		label: 'Europe/Isle_of_Man',
		value: 'Europe/Isle_of_Man',
	},
	{ label: 'Europe/Istanbul', value: 'Europe/Istanbul' },
	{ label: 'Europe/Jersey', value: 'Europe/Jersey' },
	{
		label: 'Europe/Kaliningrad',
		value: 'Europe/Kaliningrad',
	},
	{ label: 'Europe/Kiev', value: 'Europe/Kiev' },
	{ label: 'Europe/Kirov', value: 'Europe/Kirov' },
	{ label: 'Europe/Lisbon', value: 'Europe/Lisbon' },
	{
		label: 'Europe/Ljubljana',
		value: 'Europe/Ljubljana',
	},
	{ label: 'Europe/London', value: 'Europe/London' },
	{
		label: 'Europe/Luxembourg',
		value: 'Europe/Luxembourg',
	},
	{ label: 'Europe/Madrid', value: 'Europe/Madrid' },
	{ label: 'Europe/Malta', value: 'Europe/Malta' },
	{
		label: 'Europe/Mariehamn',
		value: 'Europe/Mariehamn',
	},
	{ label: 'Europe/Minsk', value: 'Europe/Minsk' },
	{ label: 'Europe/Monaco', value: 'Europe/Monaco' },
	{ label: 'Europe/Moscow', value: 'Europe/Moscow' },
	{ label: 'Europe/Oslo', value: 'Europe/Oslo' },
	{ label: 'Europe/Paris', value: 'Europe/Paris' },
	{
		label: 'Europe/Podgorica',
		value: 'Europe/Podgorica',
	},
	{ label: 'Europe/Prague', value: 'Europe/Prague' },
	{ label: 'Europe/Riga', value: 'Europe/Riga' },
	{ label: 'Europe/Rome', value: 'Europe/Rome' },
	{ label: 'Europe/Samara', value: 'Europe/Samara' },
	{
		label: 'Europe/San_Marino',
		value: 'Europe/San_Marino',
	},
	{ label: 'Europe/Sarajevo', value: 'Europe/Sarajevo' },
	{ label: 'Europe/Saratov', value: 'Europe/Saratov' },
	{
		label: 'Europe/Simferopol',
		value: 'Europe/Simferopol',
	},
	{ label: 'Europe/Skopje', value: 'Europe/Skopje' },
	{ label: 'Europe/Sofia', value: 'Europe/Sofia' },
	{
		label: 'Europe/Stockholm',
		value: 'Europe/Stockholm',
	},
	{ label: 'Europe/Tallinn', value: 'Europe/Tallinn' },
	{ label: 'Europe/Tirane', value: 'Europe/Tirane' },
	{
		label: 'Europe/Ulyanovsk',
		value: 'Europe/Ulyanovsk',
	},
	{ label: 'Europe/Uzhgorod', value: 'Europe/Uzhgorod' },
	{ label: 'Europe/Vaduz', value: 'Europe/Vaduz' },
	{ label: 'Europe/Vatican', value: 'Europe/Vatican' },
	{ label: 'Europe/Vienna', value: 'Europe/Vienna' },
	{ label: 'Europe/Vilnius', value: 'Europe/Vilnius' },
	{
		label: 'Europe/Volgograd',
		value: 'Europe/Volgograd',
	},
	{ label: 'Europe/Warsaw', value: 'Europe/Warsaw' },
	{ label: 'Europe/Zagreb', value: 'Europe/Zagreb' },
	{
		label: 'Europe/Zaporozhye',
		value: 'Europe/Zaporozhye',
	},
	{ label: 'Europe/Zurich', value: 'Europe/Zurich' },
	{
		label: 'Indian/Antananarivo',
		value: 'Indian/Antananarivo',
	},
	{ label: 'Indian/Chagos', value: 'Indian/Chagos' },
	{
		label: 'Indian/Christmas',
		value: 'Indian/Christmas',
	},
	{ label: 'Indian/Cocos', value: 'Indian/Cocos' },
	{ label: 'Indian/Comoro', value: 'Indian/Comoro' },
	{
		label: 'Indian/Kerguelen',
		value: 'Indian/Kerguelen',
	},
	{ label: 'Indian/Mahe', value: 'Indian/Mahe' },
	{ label: 'Indian/Maldives', value: 'Indian/Maldives' },
	{
		label: 'Indian/Mauritius',
		value: 'Indian/Mauritius',
	},
	{ label: 'Indian/Mayotte', value: 'Indian/Mayotte' },
	{ label: 'Indian/Reunion', value: 'Indian/Reunion' },
	{ label: 'Pacific/Apia', value: 'Pacific/Apia' },
	{
		label: 'Pacific/Auckland',
		value: 'Pacific/Auckland',
	},
	{
		label: 'Pacific/Bougainville',
		value: 'Pacific/Bougainville',
	},
	{
		label: 'Pacific/Chatham',
		value: 'Pacific/Chatham',
	},
	{ label: 'Pacific/Chuuk', value: 'Pacific/Chuuk' },
	{ label: 'Pacific/Easter', value: 'Pacific/Easter' },
	{ label: 'Pacific/Efate', value: 'Pacific/Efate' },
	{
		label: 'Pacific/Enderbury',
		value: 'Pacific/Enderbury',
	},
	{
		label: 'Pacific/Fakaofo',
		value: 'Pacific/Fakaofo',
	},
	{ label: 'Pacific/Fiji', value: 'Pacific/Fiji' },
	{
		label: 'Pacific/Funafuti',
		value: 'Pacific/Funafuti',
	},
	{
		label: 'Pacific/Galapagos',
		value: 'Pacific/Galapagos',
	},
	{ label: 'Pacific/Gambier', value: 'Pacific/Gambier' },
	{
		label: 'Pacific/Guadalcanal',
		value: 'Pacific/Guadalcanal',
	},
	{ label: 'Pacific/Guam', value: 'Pacific/Guam' },
	{
		label: 'Pacific/Honolulu',
		value: 'Pacific/Honolulu',
	},
	{
		label: 'Pacific/Kiritimati',
		value: 'Pacific/Kiritimati',
	},
	{ label: 'Pacific/Kosrae', value: 'Pacific/Kosrae' },
	{
		label: 'Pacific/Kwajalein',
		value: 'Pacific/Kwajalein',
	},
	{ label: 'Pacific/Majuro', value: 'Pacific/Majuro' },
	{
		label: 'Pacific/Marquesas',
		value: 'Pacific/Marquesas',
	},
	{ label: 'Pacific/Midway', value: 'Pacific/Midway' },
	{ label: 'Pacific/Nauru', value: 'Pacific/Nauru' },
	{ label: 'Pacific/Niue', value: 'Pacific/Niue' },
	{
		label: 'Pacific/Norfolk',
		value: 'Pacific/Norfolk',
	},
	{ label: 'Pacific/Noumea', value: 'Pacific/Noumea' },
	{
		label: 'Pacific/Pago_Pago',
		value: 'Pacific/Pago_Pago',
	},
	{ label: 'Pacific/Palau', value: 'Pacific/Palau' },
	{
		label: 'Pacific/Pitcairn',
		value: 'Pacific/Pitcairn',
	},
	{
		label: 'Pacific/Pohnpei',
		value: 'Pacific/Pohnpei',
	},
	{
		label: 'Pacific/Port_Moresby',
		value: 'Pacific/Port_Moresby',
	},
	{
		label: 'Pacific/Rarotonga',
		value: 'Pacific/Rarotonga',
	},
	{ label: 'Pacific/Saipan', value: 'Pacific/Saipan' },
	{ label: 'Pacific/Tahiti', value: 'Pacific/Tahiti' },
	{ label: 'Pacific/Tarawa', value: 'Pacific/Tarawa' },
	{
		label: 'Pacific/Tongatapu',
		value: 'Pacific/Tongatapu',
	},
	{ label: 'Pacific/Wake', value: 'Pacific/Wake' },
	{ label: 'Pacific/Wallis', value: 'Pacific/Wallis' },
];

export const TIMEZONE_DEFAULT_VALUE = 'America/New_York';
export const UTC_4_TIMEZONES = [
	{ label: 'America/Adak (HAST)', value: 'America/Adak' },
	{ label: 'America/Anchorage (AKDT)', value: 'America/Anchorage' },
	{ label: 'America/Araguaina (BRT)', value: 'America/Araguaina' },
	{
		label: 'America/Argentina/Buenos_Aires (ART)',
		value: 'America/Argentina/Buenos_Aires',
	},
	{
		label: 'America/Argentina/Catamarca (ART)',
		value: 'America/Argentina/Catamarca',
	},
	{
		label: 'America/Argentina/Cordoba (ART)',
		value: 'America/Argentina/Cordoba',
	},
	{ label: 'America/Argentina/Jujuy (ART)', value: 'America/Argentina/Jujuy' },
	{
		label: 'America/Argentina/La_Rioja (ART)',
		value: 'America/Argentina/La_Rioja',
	},
	{
		label: 'America/Argentina/Mendoza (ART)',
		value: 'America/Argentina/Mendoza',
	},
	{
		label: 'America/Argentina/Rio_Gallegos (ART)',
		value: 'America/Argentina/Rio_Gallegos',
	},
	{ label: 'America/Argentina/Salta (ART)', value: 'America/Argentina/Salta' },
	{
		label: 'America/Argentina/San_Juan (ART)',
		value: 'America/Argentina/San_Juan',
	},
	{
		label: 'America/Argentina/San_Luis (ART)',
		value: 'America/Argentina/San_Luis',
	},
	{
		label: 'America/Argentina/Tucuman (ART)',
		value: 'America/Argentina/Tucuman',
	},
	{
		label: 'America/Argentina/Ushuaia (ART)',
		value: 'America/Argentina/Ushuaia',
	},
	{ label: 'America/Asuncion (PYT)', value: 'America/Asuncion' },
	{ label: 'America/Atikokan (EST)', value: 'America/Atikokan' },
	{ label: 'America/Bahia (BRT)', value: 'America/Bahia' },
	{ label: 'America/Bahia_Banderas (CDT)', value: 'America/Bahia_Banderas' },
	{ label: 'America/Barbados (AST)', value: 'America/Barbados' },
	{ label: 'America/Belem (BRT)', value: 'America/Belem' },
	{ label: 'America/Belize (CST)', value: 'America/Belize' },
	{ label: 'America/Blanc-Sablon (AST)', value: 'America/Blanc-Sablon' },
	{ label: 'America/Boa_Vista (AMT)', value: 'America/Boa_Vista' },
	{ label: 'America/Bogota (COT)', value: 'America/Bogota' },
	{ label: 'America/Boise (MDT)', value: 'America/Boise' },
	{ label: 'America/Cambridge_Bay (MDT)', value: 'America/Cambridge_Bay' },
	{ label: 'America/Campo_Grande (AMT)', value: 'America/Campo_Grande' },
	{ label: 'America/Cancun (CDT)', value: 'America/Cancun' },
	{ label: 'America/Caracas (VET)', value: 'America/Caracas' },
	{ label: 'America/Cayenne (GFT)', value: 'America/Cayenne' },
	{ label: 'America/Chicago (CDT)', value: 'America/Chicago' },
	{ label: 'America/Chihuahua (MDT)', value: 'America/Chihuahua' },
	{ label: 'America/Costa_Rica (CST)', value: 'America/Costa_Rica' },
	{ label: 'America/Creston (MST)', value: 'America/Creston' },
	{ label: 'America/Cuiaba (AMT)', value: 'America/Cuiaba' },
	{ label: 'America/Curacao (AST)', value: 'America/Curacao' },
	{ label: 'America/Danmarkshavn (GMT)', value: 'America/Danmarkshavn' },
	{ label: 'America/Dawson (PDT)', value: 'America/Dawson' },
	{ label: 'America/Dawson_Creek (MST)', value: 'America/Dawson_Creek' },
	{ label: 'America/Denver (MDT)', value: 'America/Denver' },
	{ label: 'America/Detroit (EDT)', value: 'America/Detroit' },
	{ label: 'America/Edmonton (MDT)', value: 'America/Edmonton' },
	{ label: 'America/Eirunepe (ACT)', value: 'America/Eirunepe' },
	{ label: 'America/El_Salvador (CST)', value: 'America/El_Salvador' },
	{ label: 'America/Fort_Nelson (MST)', value: 'America/Fort_Nelson' },
	{ label: 'America/Fortaleza (BRT)', value: 'America/Fortaleza' },
	{ label: 'America/Glace_Bay (ADT)', value: 'America/Glace_Bay' },
	{ label: 'America/Goose_Bay (ADT)', value: 'America/Goose_Bay' },
	{ label: 'America/Grand_Turk (EDT)', value: 'America/Grand_Turk' },
	{ label: 'America/Guatemala (CST)', value: 'America/Guatemala' },
	{ label: 'America/Guayaquil (ECT)', value: 'America/Guayaquil' },
	{ label: 'America/Guyana (GYT)', value: 'America/Guyana' },
	{ label: 'America/Halifax (ADT)', value: 'America/Halifax' },
	{ label: 'America/Havana (CDT)', value: 'America/Havana' },
	{ label: 'America/Hermosillo (MST)', value: 'America/Hermosillo' },
	{
		label: 'America/Indiana/Indianapolis (EDT)',
		value: 'America/Indiana/Indianapolis',
	},
	{ label: 'America/Indiana/Knox (CDT)', value: 'America/Indiana/Knox' },
	{ label: 'America/Indiana/Marengo (EDT)', value: 'America/Indiana/Marengo' },
	{
		label: 'America/Indiana/Petersburg (EDT)',
		value: 'America/Indiana/Petersburg',
	},
	{
		label: 'America/Indiana/Tell_City (CDT)',
		value: 'America/Indiana/Tell_City',
	},
	{ label: 'America/Indiana/Vevay (EDT)', value: 'America/Indiana/Vevay' },
	{
		label: 'America/Indiana/Vincennes (EDT)',
		value: 'America/Indiana/Vincennes',
	},
	{ label: 'America/Indiana/Winamac (EDT)', value: 'America/Indiana/Winamac' },
	{ label: 'America/Inuvik (MDT)', value: 'America/Inuvik' },
	{ label: 'America/Iqaluit (EDT)', value: 'America/Iqaluit' },
	{ label: 'America/Jamaica (EST)', value: 'America/Jamaica' },
	{ label: 'America/Juneau (AKDT)', value: 'America/Juneau' },
	{
		label: 'America/Kentucky/Louisville (EDT)',
		value: 'America/Kentucky/Louisville',
	},
	{
		label: 'America/Kentucky/Monticello (EDT)',
		value: 'America/Kentucky/Monticello',
	},
	{ label: 'America/La_Paz (BOT)', value: 'America/La_Paz' },
	{ label: 'America/Lima (PET)', value: 'America/Lima' },
	{ label: 'America/Los_Angeles (PDT)', value: 'America/Los_Angeles' },
	{ label: 'America/Maceio (BRT)', value: 'America/Maceio' },
	{ label: 'America/Managua (CST)', value: 'America/Managua' },
	{ label: 'America/Manaus (AMT)', value: 'America/Manaus' },
	{ label: 'America/Martinique (AST)', value: 'America/Martinique' },
	{ label: 'America/Matamoros (CDT)', value: 'America/Matamoros' },
	{ label: 'America/Mazatlan (MST)', value: 'America/Mazatlan' },
	{ label: 'America/Menominee (CDT)', value: 'America/Menominee' },
	{ label: 'America/Merida (CDT)', value: 'America/Merida' },
	{ label: 'America/Metlakatla (AKDT)', value: 'America/Metlakatla' },
	{ label: 'America/Mexico_City (CDT)', value: 'America/Mexico_City' },
	{ label: 'America/Miquelon (PMST)', value: 'America/Miquelon' },
	{ label: 'America/Moncton (ADT)', value: 'America/Moncton' },
	{ label: 'America/Monterrey (CDT)', value: 'America/Monterrey' },
	{ label: 'America/Montevideo (UYT)', value: 'America/Montevideo' },
	{ label: 'America/Nassau (EDT)', value: 'America/Nassau' },
	{ label: 'America/New_York (EDT)', value: 'America/New_York' },
	{ label: 'America/Nipigon (EDT)', value: 'America/Nipigon' },
	{ label: 'America/Nome (AKDT)', value: 'America/Nome' },
	{ label: 'America/Noronha (FNT)', value: 'America/Noronha' },
	{
		label: 'America/North_Dakota/Beulah (CDT)',
		value: 'America/North_Dakota/Beulah',
	},
	{
		label: 'America/North_Dakota/Center (CDT)',
		value: 'America/North_Dakota/Center',
	},
	{
		label: 'America/North_Dakota/New_Salem (CDT)',
		value: 'America/North_Dakota/New_Salem',
	},
	{ label: 'America/Nuuk (WGT)', value: 'America/Nuuk' },
	{ label: 'America/Ojinaga (MDT)', value: 'America/Ojinaga' },
	{ label: 'America/Panama (EST)', value: 'America/Panama' },
	{ label: 'America/Pangnirtung (EDT)', value: 'America/Pangnirtung' },
	{ label: 'America/Paramaribo (SRT)', value: 'America/Paramaribo' },
	{ label: 'America/Phoenix (MST)', value: 'America/Phoenix' },
	{
		label: 'America/Port-au-Prince (EDT)',
		value: 'America/Port-au-Prince',
	},
	{ label: 'America/Port_of_Spain (AST)', value: 'America/Port_of_Spain' },
	{ label: 'America/Porto_Velho (AMT)', value: 'America/Porto_Velho' },
	{ label: 'America/Puerto_Rico (AST)', value: 'America/Puerto_Rico' },
	{ label: 'America/Punta_Arenas (CLT)', value: 'America/Punta_Arenas' },
	{ label: 'America/Rainy_River (CDT)', value: 'America/Rainy_River' },
	{ label: 'America/Rankin_Inlet (CDT)', value: 'America/Rankin_Inlet' },
	{ label: 'America/Recife (BRT)', value: 'America/Recife' },
	{ label: 'America/Regina (CST)', value: 'America/Regina' },
	{ label: 'America/Resolute (CDT)', value: 'America/Resolute' },
	{ label: 'America/Rio_Branco (ACT)', value: 'America/Rio_Branco' },
	{ label: 'America/Santarem (BRT)', value: 'America/Santarem' },
	{ label: 'America/Santiago (CLT)', value: 'America/Santiago' },
	{ label: 'America/Santo_Domingo (AST)', value: 'America/Santo_Domingo' },
	{ label: 'America/Sao_Paulo (BRT)', value: 'America/Sao_Paulo' },
	{ label: 'America/Scoresbysund (EGT)', value: 'America/Scoresbysund' },
	{ label: 'America/Sitka (AKDT)', value: 'America/Sitka' },
	{ label: 'America/St_Johns (NDT)', value: 'America/St_Johns' },
	{ label: 'America/Swift_Current (CST)', value: 'America/Swift_Current' },
	{ label: 'America/Tegucigalpa (CST)', value: 'America/Tegucigalpa' },
	{ label: 'America/Thule (ADT)', value: 'America/Thule' },
	{ label: 'America/Thunder_Bay (EDT)', value: 'America/Thunder_Bay' },
	{ label: 'America/Tijuana (PDT)', value: 'America/Tijuana' },
	{ label: 'America/Toronto (EDT)', value: 'America/Toronto' },
	{ label: 'America/Vancouver (PDT)', value: 'America/Vancouver' },
	{ label: 'America/Whitehorse (PDT)', value: 'America/Whitehorse' },
	{ label: 'America/Winnipeg (CDT)', value: 'America/Winnipeg' },
	{ label: 'America/Yakutat (AKDT)', value: 'America/Yakutat' },
	{ label: 'America/Yellowknife (MDT)', value: 'America/Yellowknife' },
	{ label: 'Antarctica/Casey (CAST)', value: 'Antarctica/Casey' },
	{ label: 'Antarctica/Davis (DAVT)', value: 'Antarctica/Davis' },
	{
		label: 'Antarctica/DumontDUrville (DDUT)',
		value: 'Antarctica/DumontDUrville',
	},
	{ label: 'Antarctica/Macquarie (AEDT)', value: 'Antarctica/Macquarie' },
	{ label: 'Antarctica/Mawson (MAWT)', value: 'Antarctica/Mawson' },
	{ label: 'Antarctica/Palmer (CLT)', value: 'Antarctica/Palmer' },
	{ label: 'Antarctica/Rothera (ROTT)', value: 'Antarctica/Rothera' },
	{ label: 'Antarctica/Syowa (SYOT)', value: 'Antarctica/Syowa' },
	{ label: 'Antarctica/Troll (UTC)', value: 'Antarctica/Troll' },
	{ label: 'Antarctica/Vostok (VOST)', value: 'Antarctica/Vostok' },
	{ label: 'Australia/Adelaide (ACST)', value: 'Australia/Adelaide' },
	{ label: 'Australia/Brisbane (AEST)', value: 'Australia/Brisbane' },
	{ label: 'Australia/Broken_Hill (ACST)', value: 'Australia/Broken_Hill' },
	{ label: 'Australia/Darwin (ACST)', value: 'Australia/Darwin' },
	{ label: 'Australia/Eucla (ACWST)', value: 'Australia/Eucla' },
	{ label: 'Australia/Hobart (AEDT)', value: 'Australia/Hobart' },
	{ label: 'Australia/Lindeman (AEST)', value: 'Australia/Lindeman' },
	{ label: 'Australia/Lord_Howe (LHDT)', value: 'Australia/Lord_Howe' },
	{ label: 'Australia/Melbourne (AEDT)', value: 'Australia/Melbourne' },
	{ label: 'Australia/Perth (AWST)', value: 'Australia/Perth' },
	{ label: 'Australia/Sydney (AEDT)', value: 'Australia/Sydney' },
	{ label: 'Europe/Amsterdam (CEST)', value: 'Europe/Amsterdam' },
	{ label: 'Europe/Andorra (CEST)', value: 'Europe/Andorra' },
	{ label: 'Europe/Astrakhan (MSK)', value: 'Europe/Astrakhan' },
	{ label: 'Europe/Athens (EEST)', value: 'Europe/Athens' },
	{ label: 'Europe/Belgrade (CEST)', value: 'Europe/Belgrade' },
	{ label: 'Europe/Berlin (CEST)', value: 'Europe/Berlin' },
	{ label: 'Europe/Brussels (CEST)', value: 'Europe/Brussels' },
	{ label: 'Europe/Bucharest (EEST)', value: 'Europe/Bucharest' },
	{ label: 'Europe/Budapest (CEST)', value: 'Europe/Budapest' },
	{ label: 'Europe/Chisinau (EEST)', value: 'Europe/Chisinau' },
	{ label: 'Europe/Copenhagen (CEST)', value: 'Europe/Copenhagen' },
	{ label: 'Europe/Dublin (IST)', value: 'Europe/Dublin' },
	{ label: 'Europe/Gibraltar (CEST)', value: 'Europe/Gibraltar' },
	{ label: 'Europe/Helsinki (EEST)', value: 'Europe/Helsinki' },
	{ label: 'Europe/Istanbul (TRT)', value: 'Europe/Istanbul' },
	{ label: 'Europe/Kaliningrad (EET)', value: 'Europe/Kaliningrad' },
	{ label: 'Europe/Kiev (EEST)', value: 'Europe/Kiev' },
	{ label: 'Europe/Kirov (MSK)', value: 'Europe/Kirov' },
	{ label: 'Europe/Lisbon (WEST)', value: 'Europe/Lisbon' },
	{ label: 'Europe/London (BST)', value: 'Europe/London' },
	{ label: 'Europe/Luxembourg (CEST)', value: 'Europe/Luxembourg' },
	{ label: 'Europe/Madrid (CEST)', value: 'Europe/Madrid' },
	{ label: 'Europe/Malta (CEST)', value: 'Europe/Malta' },
	{ label: 'Europe/Minsk (MSK)', value: 'Europe/Minsk' },
	{ label: 'Europe/Monaco (CEST)', value: 'Europe/Monaco' },
	{ label: 'Europe/Moscow (MSK)', value: 'Europe/Moscow' },
	{ label: 'Europe/Oslo (CEST)', value: 'Europe/Oslo' },
	{ label: 'Europe/Paris (CEST)', value: 'Europe/Paris' },
	{ label: 'Europe/Prague (CEST)', value: 'Europe/Prague' },
	{ label: 'Europe/Riga (EEST)', value: 'Europe/Riga' },
	{ label: 'Europe/Rome (CEST)', value: 'Europe/Rome' },
	{ label: 'Europe/Samara (SAMT)', value: 'Europe/Samara' },
	{ label: 'Europe/Saratov (MSK)', value: 'Europe/Saratov' },
	{ label: 'Europe/Simferopol (MSK)', value: 'Europe/Simferopol' },
	{ label: 'Europe/Sofia (EEST)', value: 'Europe/Sofia' },
	{ label: 'Europe/Stockholm (CEST)', value: 'Europe/Stockholm' },
	{ label: 'Europe/Tallinn (EEST)', value: 'Europe/Tallinn' },
	{ label: 'Europe/Tirane (CEST)', value: 'Europe/Tirane' },
	{ label: 'Europe/Ulyanovsk (MSK)', value: 'Europe/Ulyanovsk' },
	{ label: 'Europe/Uzhgorod (EEST)', value: 'Europe/Uzhgorod' },
	{ label: 'Europe/Vienna (CEST)', value: 'Europe/Vienna' },
	{ label: 'Europe/Vilnius (EEST)', value: 'Europe/Vilnius' },
	{ label: 'Europe/Volgograd (MSK)', value: 'Europe/Volgograd' },
	{ label: 'Europe/Warsaw (CEST)', value: 'Europe/Warsaw' },
	{ label: 'Europe/Zaporozhye (EEST)', value: 'Europe/Zaporozhye' },
	{ label: 'Europe/Zurich (CEST)', value: 'Europe/Zurich' },
];
