import React from 'react';

import { CustomIcon } from './components/CustomIcon';
import { IconEnum } from '@global/icons/customIconsConstant';

// Make sure to add the Rounded version icon
import { IconProps } from './types';

import { GoodkindTheme } from '@global/theme';

import {
	Play,
	CircleNotch,
	HouseSimple,
	Users,
	User,
	UsersThree,
	Cube,
	MonitorPlay,
	PuzzlePiece,
	CirclesThree,
	Receipt,
	BookBookmark,
	PaperPlaneTilt,
	PaperPlaneRight,
	UserFocus,
	UserMinus,
	UserPlus,
	Plus,
	ChatText,
	Chats,
	ClosedCaptioning,
	BellRinging,
	MegaphoneSimple,
	Lightning,
	LightningSlash,
	GearSix,
	Question,
	List,
	CloudArrowUp,
	Cloud,
	Upload,
	PencilSimple,
	Check,
	FireSimple,
	Cursor,
	CalendarBlank,
	CheckCircle,
	ArrowSquareOut,
	ArrowSquareDown,
	ArrowRight,
	ArrowLeft,
	ArrowBendUpLeft,
	ArrowsClockwise,
	ArrowDownLeft,
	ArrowDownRight,
	PaintBrush,
	Globe,
	ChartBar,
	X,
	Eye,
	Minus,
	Info,
	MicrophoneSlash,
	Warning,
	FileArrowUp,
	TrashSimple,
	Trash,
	PlayCircle,
	FileCsv,
	MagnifyingGlass,
	MagicWand,
	CaretRight,
	CaretLeft,
	CaretDown,
	CaretUp,
	Circle,
	FileText,
	File,
	PlusCircle,
	ChatCircle,
	ShieldCheck,
	EnvelopeSimple,
	Clock,
	DotsThreeOutline,
	DotsThreeOutlineVertical,
	DotsSix,
	CopySimple,
	ShareNetwork,
	HandPointing,
	Tray,
	UsersFour,
	DownloadSimple,
	FloppyDisk,
	Scissors,
	Printer,
	Copy,
	Power,
	XCircle,
	ArchiveBox,
	Download,
	WarningCircle,
	Quotes,
	Note,
	Envelope,
	ChatCenteredText,
	Wrench,
	VideoCamera,
	VideoCameraSlash,
	SpeakerHigh,
	CheckSquareOffset,
	PushPinSimple,
	PushPinSimpleSlash,
	Cake,
	Desktop,
	DeviceMobile,
	Phone,
	IdentificationCard,
	SortAscending,
	Columns,
	IdentificationBadge,
	WhatsappLogo,
	SpinnerGap,
	Article,
	Bug,
	Calendar,
	Plugs,
	UserList,
	Exam,
	Gift,
	Target,
	PencilCircle,
	Money,
	Trophy,
	Books,
	Tree,
	FaceMask,
	MapPin,
} from 'phosphor-react';
import {
	BookOpenText,
	FilmReel,
	Atom,
	Toolbox,
	Scroll,
	PottedPlant,
	RainbowCloud,
	Calculator,
	Stamp,
	Flask,
	WheelchairMotion,
	GlobeHemisphereWest,
	Bird,
	ShootingStar,
	Handshake,
	Eyeglasses,
	ChartLineUp,
	PersonSimpleThrow,
	Scales,
	Chalkboard,
	Campfire,
	Dna,
	MusicNotes,
	GraduationCap,
	PersonSimpleBike,
	Binoculars,
	HandsPraying,
	Star,
	LightbulbFilament,
	PiggyBank,
	ChalkboardTeacher,
	CubeTransparent,
	Confetti,
	MaskHappy,
	Certificate,
	HandHeart,
	ChatCircleDots,
} from '@phosphor-icons/react';
import { emojiAndSymbolsRegex } from '@app/constants/regex';

export default function Icon({
	iconName,
	size = 18,
	color = 'inherit',
	weight = 'regular',
	width,
	height,
	className,
}: IconProps) {
	const isEmoji = iconName && emojiAndSymbolsRegex().test(iconName);

	if (isEmoji) {
		return (
			<span
				className="d-flex align-items-center justify-content-center"
				style={{ fontSize: size }}
			>
				{iconName}
			</span>
		);
	}

	function handleStyles() {
		return {
			fontSize: size,
			minWidth: size,
			color: color,
			fill: color,
			transition: GoodkindTheme.transition.typography,
		};
	}

	// The icon names are in alphabetical order
	function HandleIcon() {
		if (!iconName) {
			return (
				<Cube style={handleStyles()} weight={weight} className={className} />
			);
		}

		switch (iconName) {
			case 'confetti':
				return (
					<Confetti
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'cube-transparent':
				return (
					<CubeTransparent
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'chalkboard-teacher':
				return (
					<ChalkboardTeacher
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'piggy-bank':
				return (
					<PiggyBank
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'lightbulb-filament':
				return (
					<LightbulbFilament
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'star':
				return (
					<Star style={handleStyles()} weight={weight} className={className} />
				);
			case 'hands-praying':
				return (
					<HandsPraying
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'binoculars':
				return (
					<Binoculars
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'graduation-cap':
				return (
					<GraduationCap
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'music-notes':
				return (
					<MusicNotes
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'dna':
				return (
					<Dna style={handleStyles()} weight={weight} className={className} />
				);
			case 'campfire':
				return (
					<Campfire
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'chalkboard':
				return (
					<Chalkboard
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'scales':
				return (
					<Scales
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'person-simple-bike':
				return (
					<PersonSimpleBike
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'person-simple-throw':
				return (
					<PersonSimpleThrow
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'chart-line-up':
				return (
					<ChartLineUp
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'eyeglasses':
				return (
					<Eyeglasses
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'handshake':
				return (
					<Handshake
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'shooting-start':
				return (
					<ShootingStar
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'bird':
				return (
					<Bird style={handleStyles()} weight={weight} className={className} />
				);
			case 'brush':
				return (
					<PaintBrush
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'globe-hemisphere-west':
				return (
					<GlobeHemisphereWest
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'wheelchair-motion':
				return (
					<WheelchairMotion
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'flask':
				return (
					<Flask style={handleStyles()} weight={weight} className={className} />
				);
			case 'stamp':
				return (
					<Stamp style={handleStyles()} weight={weight} className={className} />
				);
			case 'calculator':
				return (
					<Calculator
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'rainbow-cloud':
				return (
					<RainbowCloud
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'potted-plant':
				return (
					<PottedPlant
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'scroll':
				return (
					<Scroll
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'toolbox':
				return (
					<Toolbox
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'atom':
				return (
					<Atom style={handleStyles()} weight={weight} className={className} />
				);
			case 'film-reel':
				return (
					<FilmReel
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'books':
				return (
					<Books style={handleStyles()} weight={weight} className={className} />
				);
			case 'tree':
				return (
					<Tree style={handleStyles()} weight={weight} className={className} />
				);
			case 'face-mask':
				return (
					<FaceMask
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'money':
				return (
					<Money style={handleStyles()} weight={weight} className={className} />
				);
			case 'pencil-circle':
				return (
					<PencilCircle
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'trophy':
				return (
					<Trophy
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'book-open-text':
				return (
					<BookOpenText
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'target':
				return (
					<Target
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'gift':
				return (
					<Gift style={handleStyles()} weight={weight} className={className} />
				);
			case 'mask':
				return (
					<MaskHappy
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'exam':
				return (
					<Exam style={handleStyles()} weight={weight} className={className} />
				);
			case 'certificate':
				return (
					<Certificate
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'hand-heart':
				return (
					<HandHeart
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'book-bookmark':
				return (
					<BookBookmark
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'volume-on':
				return (
					<SpeakerHigh
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'custom-play':
				return (
					<Play
						style={handleStyles()}
						weight={weight || 'duotone'}
						className={className}
					/>
				);
			case 'loader':
				return (
					<CircleNotch
						style={handleStyles()}
						weight={weight || 'duotone'}
						className={className}
					/>
				);

			case 'spinnerGap':
				return (
					<SpinnerGap
						style={handleStyles()}
						weight={weight || 'duotone'}
						className={className}
					/>
				);

			case 'home':
				return (
					<HouseSimple
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'campaign':
				return (
					<MegaphoneSimple
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'phone':
				return (
					<Phone style={handleStyles()} weight={weight} className={className} />
				);

			case 'email':
				return (
					<EnvelopeSimple
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'audience':
				return (
					<UsersThree
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'action':
			case 'flash':
				return (
					<Lightning
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'lightningSlash':
				return (
					<LightningSlash
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'checkSquare':
				return (
					<CheckSquareOffset
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'settings':
				return (
					<GearSix
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'help':
				return (
					<Question
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'preview':
				return (
					<ArrowSquareOut
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'user':
				return (
					<User style={handleStyles()} weight={weight} className={className} />
				);

			case 'userList':
				return (
					<UserList
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'users':
			case 'group':
				return (
					<Users style={handleStyles()} weight={weight} className={className} />
				);

			case 'language':
				return (
					<Globe style={handleStyles()} weight={weight} className={className} />
				);

			case 'wrench':
				return (
					<Wrench
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'assessment':
				return (
					<ChartBar
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'arrowForward':
				return (
					<ArrowRight
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'expandLess':
			case 'arrowUpIndicator':
				return (
					<CaretUp
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'expandMore':
			case 'chevronDown':
			case 'downArrowIndicator':
				return (
					<CaretDown
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'info':
				return (
					<Info style={handleStyles()} weight={weight} className={className} />
				);

			case 'sync':
				return (
					<ArrowsClockwise
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'chevronRight':
				return (
					<CaretRight
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'verifiedUser':
				return (
					<ShieldCheck
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'chevronLeft':
				return (
					<CaretLeft
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'reply':
				return (
					<ArrowBendUpLeft
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'file':
				return (
					<File style={handleStyles()} weight={weight} className={className} />
				);

			case 'fileCsv':
				return (
					<FileCsv
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'dash':
				return (
					<Minus style={handleStyles()} weight={weight} className={className} />
				);

			case 'playCircle':
				return (
					<PlayCircle
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'copy':
				return (
					<Copy style={handleStyles()} weight={weight} className={className} />
				);

			case 'list':
				return (
					<List style={handleStyles()} weight={weight} className={className} />
				);

			case 'fileCopy':
				return (
					<CopySimple
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'mail':
				return (
					<EnvelopeSimple
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'envelope':
				return (
					<Envelope
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'chatCenteredText':
				return (
					<ChatCenteredText
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'article':
				return (
					<Article
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'accessTime':
				return (
					<Clock style={handleStyles()} weight={weight} className={className} />
				);

			case 'moreVert':
				return (
					<DotsThreeOutlineVertical
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'moreHoriz':
				return (
					<DotsThreeOutline
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'dotsSix':
				return (
					<DotsSix
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'close':
				return (
					<X style={handleStyles()} weight={weight} className={className} />
				);

			case 'closeCircle':
				return (
					<XCircle
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'archive':
				return (
					<ArchiveBox
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'unarchive':
				return (
					<FileArrowUp
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'trash':
			case 'delete':
				return (
					<TrashSimple
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'trashCan':
				return (
					<Trash style={handleStyles()} weight={weight} className={className} />
				);
			case 'addCircle':
			case 'addCircleOutline':
				return (
					<PlusCircle
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'systemUpdate':
			case 'systemUpdateAlt':
				return (
					<ArrowSquareDown
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'save':
				return (
					<FloppyDisk
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'scissors':
				return (
					<Scissors
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'warning':
				return (
					<Warning
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'warningCircle':
				return (
					<WarningCircle
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'comment':
				return (
					<ChatCircle
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'videos':
			case 'videoPage':
				return (
					<MonitorPlay
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'micOff':
				return (
					<MicrophoneSlash
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'puzzle':
				return (
					<PuzzlePiece
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'identificationBadge':
				return (
					<IdentificationBadge
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'identificationCard':
				return (
					<IdentificationCard
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'workspace':
				return (
					<CirclesThree
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'whatsapp':
				return (
					<WhatsappLogo
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'usersFour':
			case 'audiences':
				return (
					<UsersFour
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'receipt':
				return (
					<Receipt
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'visibility':
				return (
					<Eye style={handleStyles()} weight={weight} className={className} />
				);

			case 'pin':
				return (
					<PushPinSimple
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'unpin':
				return (
					<PushPinSimpleSlash
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'share':
				return (
					<ShareNetwork
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'touchApp':
				return (
					<HandPointing
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'arrowLeft':
				return (
					<ArrowLeft
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'send':
				return (
					<PaperPlaneTilt
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'sendRight':
				return (
					<PaperPlaneRight
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'userFocus':
				return (
					<UserFocus
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'plus':
				return (
					<Plus style={handleStyles()} weight={weight} className={className} />
				);

			case 'videoCamera':
				return (
					<VideoCamera
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'videoCameraSlash':
				return (
					<VideoCameraSlash
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'unsubscribe':
				return (
					<UserMinus
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'personAdd':
				return (
					<UserPlus
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'sms':
				return (
					<ChatText
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'sms-three-dots':
				return (
					<ChatCircleDots
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'chats':
				return (
					<Chats style={handleStyles()} weight={weight} className={className} />
				);

			case 'subtitles':
				return (
					<ClosedCaptioning
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'notification':
				return (
					<BellRinging
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'note':
				return (
					<Note style={handleStyles()} weight={weight} className={className} />
				);

			case 'openInNew':
				return (
					<ArrowSquareOut
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'uploadCloud':
				return (
					<CloudArrowUp
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'cloud':
				return (
					<Cloud style={handleStyles()} weight={weight} className={className} />
				);

			case 'upload':
				return (
					<Upload
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'export':
				return (
					<DownloadSimple
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'download':
				return (
					<Download
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'tray':
				return (
					<Tray style={handleStyles()} weight={weight} className={className} />
				);

			case 'edit':
			case 'pencil':
				return (
					<PencilSimple
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'check':
				return (
					<Check style={handleStyles()} weight={weight} className={className} />
				);

			case 'fire':
				return (
					<FireSimple
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'click':
				return (
					<Cursor
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'sort':
				return (
					<SortAscending
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'event':
			case 'calendar':
				return (
					<CalendarBlank
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'checkCircle':
				return (
					<CheckCircle
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'radioButtonUnchecked':
			case 'circle':
				return (
					<Circle
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'search':
				return (
					<MagnifyingGlass
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'wand':
				return (
					<MagicWand
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'desktop':
				return (
					<Desktop
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'mobile':
				return (
					<DeviceMobile
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'description':
				return (
					<FileText
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'printer':
				return (
					<Printer
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'power':
				return (
					<Power style={handleStyles()} weight={weight} className={className} />
				);
			case 'quotes':
				return (
					<Quotes
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'cake':
				return (
					<Cake style={handleStyles()} weight={weight} className={className} />
				);

			case 'columns':
				return (
					<Columns
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'arrow-down-left':
				return (
					<ArrowDownLeft
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'arrow-down-right':
				return (
					<ArrowDownRight
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);
			case 'bug':
				return (
					<Bug style={handleStyles()} weight={weight} className={className} />
				);
			case 'calendarDate':
				return (
					<Calendar
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			case 'plugs':
				return (
					<Plugs style={handleStyles()} weight={weight} className={className} />
				);

			case 'map-pin':
				return (
					<MapPin
						style={handleStyles()}
						weight={weight}
						className={className}
					/>
				);

			default:
				if (IconEnum[iconName?.toLowerCase()]) {
					return (
						<CustomIcon
							iconName={iconName}
							color={color}
							className={className}
							width={width ? width : size ? `${size}px` : undefined}
							height={height ? height : size ? `${size}px` : undefined}
						/>
					);
				}

				return (
					<Cube style={handleStyles()} weight={weight} className={className} />
				);
		}
	}

	return <HandleIcon />;
}
