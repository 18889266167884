import React from 'react';
import * as S from './ImagePreview.styles';
import { Icon } from '../Icon';
import Button from '../Button/Button';

export type ImagePreviewProps = {
	url?: string;
	secondaryUrl?: string;
	active?: boolean;
	width?: string;
	height?: string;
	hasBorder?: boolean;
	onClose?: () => void;
};
function ImagePreview({
	url,
	secondaryUrl,
	active,
	width,
	height,
	hasBorder,
	onClose,
}: ImagePreviewProps) {
	return (
		<S.Container
			width={width}
			height={height}
			hasBorder={hasBorder}
			active={active}
			hasSecondaryImage={!!secondaryUrl}
		>
			<S.Image image={url} className="primary" />

			<S.Image image={secondaryUrl} className="secondary" />

			{!!onClose && (
				<S.CloseIcon>
					<Button
						iconButton
						icon="close"
						size="xs"
						onClick={onClose}
						variant="outlined"
					/>
				</S.CloseIcon>
			)}

			{/* Icon always at the center. Visible if image url is invalid or empty */}
			<Icon iconName="image" size="xl" color="system.text.medium" />
		</S.Container>
	);
}

export default ImagePreview;
