import styled, { DefaultTheme, css } from 'styled-components';
import { ButtonVariantType } from './SquareIconButton';

const getButtonSizeStyling = (size: string) => {
	switch (size) {
		case 'xs':
			return `
				width: 28px;
				min-width: 28px;
				height: 28px;
				min-height: 28px;
			`;
		case 'small':
			return `
				width: 32px;
				min-width: 32px;
				height: 32px;
				min-height: 32px;
			`;
		case 'medium':
			return `
				width: 36px;
				min-width: 36px;
				height: 36px;
				min-height: 36px;
			`;
		case 'large':
			return `
				width: 40px;
				min-width: 40px;
				height: 40px;
				min-height: 40px;
			`;
	}
};

const getBgColor = (
	variant: ButtonVariantType,
	theme: DefaultTheme,
	pressed?: boolean,
) => {
	if (pressed) {
		return getPressedBgColor(theme, variant);
	}
	return null;
};

const getVariantColor = (theme: DefaultTheme, variant: ButtonVariantType) => {
	if (variant === 'ghost') {
		return theme.colors.neutral.icon.default;
	}
	return theme.colors[variant].icon.default;
};

const getHoverBgColor = (theme: DefaultTheme, variant: ButtonVariantType) => {
	if (variant === 'ghost') {
		return theme.colors.system.background.weak;
	}
	return theme.colors[variant].background.default;
};

const getPressedBgColor = (theme: DefaultTheme, variant: ButtonVariantType) => {
	if (variant === 'ghost') {
		return theme.colors.system.background.weak;
	}
	return (
		(theme.colors[variant].background as any).defaultHover ||
		(theme.colors[variant].background as any).defaultActive
	);
};

const getActiveBgColor = (theme: DefaultTheme, variant: ButtonVariantType) => {
	if (variant === 'ghost') {
		return theme.colors.neutral.background.hover;
	}
	return (
		(theme.colors[variant].background as any).hover ||
		(theme.colors[variant].background as any).defaultHover
	);
};

export const StyledButton = styled.div<{
	size: string;
	disabled: boolean;
	active?: boolean;
	pressed?: boolean;
	variant: ButtonVariantType;
}>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 2px 0;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	pointer-events: ${({ disabled }) => {
		return disabled ? 'none' : 'auto';
	}};

	border-radius: ${({ theme, size }) => {
		switch (size) {
			case 'small':
				return theme.shapeRadius.xs;
			case 'medium':
				return theme.shapeRadius.small;
			case 'large':
				return theme.shapeRadius.small;
			default:
				return theme.shapeRadius[6];
		}
	}};

	color: ${({ theme, variant, active }) =>
		variant && active
			? theme.colors.primary.icon.default
			: getVariantColor(theme, variant)};
	background-color: ${({ theme, variant, pressed }) =>
		getBgColor(variant, theme, pressed)};

	border: none;

	transition: ${({ theme }) => theme.transitions.ease01};

	:hover {
		background-color: ${({ theme, variant }) =>
			getHoverBgColor(theme, variant)};
	}

	:active {
		background-color: ${({ theme, variant }) =>
			getActiveBgColor(theme, variant)};
	}

	${({ disabled }) =>
		disabled &&
		css`
			background-color: ${({ theme }) => theme.colors.system.background.weak};
			opacity: 0.9;
		`};

	${({ active, variant }) =>
		active &&
		css`
			background-color: ${({ theme }) =>
				(theme.colors.primary.background as any).hover ||
				(theme.colors.primary.background as any).defaultHover};

			:hover {
				background-color: ${({ theme }) =>
					theme.colors.primary.background.defaultActive};
			}
		`};

	${({ size }) => getButtonSizeStyling(size)};
`;
