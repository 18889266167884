import * as React from 'react';
import { observer } from 'mobx-react-lite';
// import default tab component
import { ImagesGrid, SectionTab } from 'polotno/side-panel';

import { Icon, Text } from '@common/design-system/components/atoms';
import { PostcardVariableNames } from '@app/constants/modules/postcard';

import * as SharedS from '../../CreativeEditor.styles';
import * as S from './MergeImages.styles';

import { useCreativeEditorContext } from '../../providers/CreativeEditorProvider';
import {
	getMergeImageUrl,
	handleAddMergeImageToStore,
} from '@app/utils/modules/postcard';

type SectionTabProps = {
	onClick: any;
	active: boolean;
};
type CustomItemProps = {
	variableName: `${string}-${string}` | (string & object);
	displayName: string;
	imageUrl: string;
	borderRadius?: number;
	handleAddElement: () => void;
};

const CustomItem = ({
	displayName,
	variableName,
	imageUrl,
	borderRadius,
	handleAddElement,
}: CustomItemProps) => {
	return (
		<S.CustomItemWrapper
			role="button"
			tabIndex={0}
			aria-hidden="true"
			onClick={handleAddElement}
			borderRadius={borderRadius}
		>
			<div className="image-wrapper">
				<img src={imageUrl} alt={variableName} />
			</div>

			<Text>{displayName}</Text>
		</S.CustomItemWrapper>
	);
};

const MergeImagesSection = {
	name: 'mergeImages',
	Tab: ({ active, onClick }: SectionTabProps) => {
		return (
			<SectionTab name="Merge Images" active={active} onClick={onClick}>
				<SharedS.CustomIconWrapper isActive={active}>
					<Icon
						iconName="mergeImagesActive"
						className="active-icon merge-images-icon"
					/>

					<Icon
						iconName="mergeImages"
						className="inactive-icon merge-images-icon"
					/>
				</SharedS.CustomIconWrapper>
			</SectionTab>
		);
	},
	// we need observer to update component automatically on any store changes
	Panel: observer(({ store }: { store: any }) => {
		const { workspaceInfo, customMergeImages, disabledMergeImages } =
			useCreativeEditorContext();
		const isRounded = !!workspaceInfo?.theme?.borderRadius?.round;

		const borderRadiusField = isRounded ? 'round' : 'square';
		const getImageUrl = (variableName: PostcardVariableNames) => {
			const imageUrl = getMergeImageUrl(variableName, customMergeImages);
			return imageUrl;
		};
		const handleAddElement = async (variableName: PostcardVariableNames) => {
			handleAddMergeImageToStore(
				store,
				variableName,
				borderRadiusField,
				customMergeImages,
			);
		};

		const mergeImages = React.useMemo(() => {
			const allMergeImages = [
				{
					src: getImageUrl(PostcardVariableNames.QR_CODE),
					name: 'QR Code',
					variableName: PostcardVariableNames.QR_CODE,
				},
				{
					src: getImageUrl(PostcardVariableNames.WORKSPACE_LOGO),
					name: 'Logo',
					variableName: PostcardVariableNames.WORKSPACE_LOGO,
				},
				{
					src: getImageUrl(PostcardVariableNames.VIDEO_THUMBNAIL),
					name: 'Video Thumbnail',
					variableName: PostcardVariableNames.VIDEO_THUMBNAIL,
				},
				{
					src: getImageUrl(PostcardVariableNames.WORKSPACE_BG),
					name: 'Workspace Background',
					variableName: PostcardVariableNames.WORKSPACE_BG,
				},
			];

			return allMergeImages?.filter((mergeImage) => {
				return !disabledMergeImages?.includes(mergeImage.variableName);
			});
		}, [workspaceInfo, disabledMergeImages]);

		return (
			<S.Container>
				<ImagesGrid
					images={mergeImages || []}
					getPreview={(image: (typeof mergeImages)[number]) => image.src}
					onSelect={async (image, pos, element) =>
						handleAddElement(image.variableName)
					}
					shadowEnabled={false}
					isLoading={false}
					error=""
					getCredit={(image) => <Text size="xs">{image.name}</Text>}
				/>
			</S.Container>
		);
	}),
};

export default MergeImagesSection;
