import axios from '@global/axios';
import { fetchAction } from '@app/redux/types';
import { apiUrl, ToastMessage } from '@app/constants';
import createFetchTypes from '@app/utils/createFetchTypes';
import { FilterOptions } from '@app/types';
import { UserTemplateType } from '@app/redux/userTemplates/types';
import { UserTemplateChannelsEnum } from '@app/redux/userTemplates/constants';

const mainType = 'userTemplates';

export const getUserTemplatesTypes = createFetchTypes(
	`${mainType}_GET_USER_TEMPLATES`,
);

export const getSingleUserTemplateTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_USER_TEMPLATE`,
);

// Handles the sms template creation and update - saves on userTemplates.smsTemplates.templates
export const getSMSUserTemplateTypes = createFetchTypes(
	`${mainType}_GET_SMS_USER_TEMPLATE`,
);

// Handles the whatsapp template creation and update - saves on userTemplates.waTemplates.templates
export const getWhatsappUserTemplateTypes = createFetchTypes(
	`${mainType}_GET_WHATSAPP_USER_TEMPLATE`,
);

//  Handles the postcard template creation and update - saves on userTemplates.postcardTemplates.templates
export const getPostcardUserTemplateTypes = createFetchTypes(
	`${mainType}_GET_POSTCARD_USER_TEMPLATE`,
);

export const createTemplatesTypes = createFetchTypes(
	`${mainType}_CREATE_USER_TEMPLATE`,
);

export const updateTemplatesTypes = createFetchTypes(
	`${mainType}_UPDATE_USER_TEMPLATE`,
);

export const duplicatedTemplatesTypes = createFetchTypes(
	`${mainType}_DUPLICATE_USER_TEMPLATE`,
);

export const updateTeamsOnUserTemplateTypes = createFetchTypes(
	`${mainType}_UPDATE_TEAMS_ON_USER_TEMPLATE`,
);

export const deleteTemplatesTypes = createFetchTypes(
	`${mainType}_DELETE_USER_TEMPLATE`,
);

export const getPresignedUrlTypes = createFetchTypes(
	`${mainType}_GET_PRESIGNED_URL`,
);

export const getPostcardTemplateJsonTypes = createFetchTypes(
	`${mainType}_GET_POSTCARD_TEMPLATE_JSON`,
);

// Post images
export const createPostImageTypes = createFetchTypes(
	`${mainType}_CREATE_POST_IMAGE`,
);

export const getPostImagesTypes = createFetchTypes(
	`${mainType}_GET_POST_IMAGES`,
);

export const deletePostImageTypes = createFetchTypes(
	`${mainType}_DELETE_POST_IMAGE`,
);

export const deleteAllPostImagesTypes = createFetchTypes(
	`${mainType}_DELETE_POST_IMAGES`,
);

function getSingleUserTemplate(userTemplateId: string): fetchAction {
	return {
		type: getSingleUserTemplateTypes,
		callAPI: () => axios.get(`${apiUrl}/userTemplates/${userTemplateId}`),
		payload: {
			custom: 'getSingleUserTemplate',
		},
	};
}

function getUserTemplates(
	filter?: FilterOptions,
	tab?: UserTemplateChannelsEnum,
): fetchAction {
	let actionType = getUserTemplatesTypes;

	//Saves data on the sms reducer
	if (tab === UserTemplateChannelsEnum.SMS) {
		actionType = getSMSUserTemplateTypes;
	}

	//Saves data on the whatsapp reducer
	if (tab === UserTemplateChannelsEnum.WHATSAPP) {
		actionType = getWhatsappUserTemplateTypes;
	}

	if (tab === UserTemplateChannelsEnum.POSTCARD) {
		actionType = getPostcardUserTemplateTypes;
	}

	return {
		type: actionType,
		callAPI: () =>
			axios.get(
				`${apiUrl}/userTemplates${
					filter
						? `?filter=${JSON.stringify(filter)}&updateStatus=true`
						: '?updateStatus=true'
				}`,
			),
	};
}

function createUserTemplate({
	userTemplate,
	showSuccessMessage = true,
	customSuccessMessage,
	insertInList = true,
}: {
	userTemplate: Partial<UserTemplateType>;
	showSuccessMessage?: boolean;
	customSuccessMessage?: string;
	insertInList?: boolean;
}): fetchAction {
	return {
		type: createTemplatesTypes,
		callAPI: () => axios.post(`${apiUrl}/userTemplates`, userTemplate),
		payload: {
			custom: 'createUserTemplate',
			successMessage: showSuccessMessage
				? customSuccessMessage || ToastMessage.templateCreateSuccess
				: '',
			insertInList,
		},
	};
}

function updateUserTemplate(
	userTemplateId: string,
	userTemplate: Partial<UserTemplateType>,
	showSuccessMessage = true,
	showErrorMessage = true,
): fetchAction {
	return {
		type: updateTemplatesTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/userTemplates/${userTemplateId}`, userTemplate),
		payload: {
			custom: 'updateUserTemplate',
			successMessage: showSuccessMessage
				? ToastMessage.templateUpdateSuccess
				: '',
			errorMessage: showErrorMessage ? ToastMessage.templateUpdateError : '',
		},
	};
}

function duplicateUserTemplate({
	templateId,
	insertInList,
}: {
	templateId: string;
	insertInList?: boolean;
}): fetchAction {
	return {
		type: duplicatedTemplatesTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/userTemplates/duplicate/${templateId}`),
		payload: {
			insertInList,
			custom: 'updateUserTemplate',
			successMessage: ToastMessage.templateDuplicateSuccess,
			errorMessage: ToastMessage.templateDuplicateError,
		},
	};
}

function deleteUserTemplate(userTemplateId: string): fetchAction {
	return {
		type: deleteTemplatesTypes,
		callAPI: () => axios.delete(`${apiUrl}/userTemplates/${userTemplateId}`),
		payload: {
			custom: 'deleteUserTemplate',
			successMessage: ToastMessage.templateDeleteSuccess,
			errorMessage: ToastMessage.templateDeleteError,
		},
	};
}

function updateTeamsOnUserTemplate(
	userTemplateId: string,
	teams: string[],
): fetchAction {
	return {
		type: updateTeamsOnUserTemplateTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/userTemplates/teams/${userTemplateId}`, { teams }),
		payload: {
			custom: 'updateTeamsOnUserTemplate',
			successMessage: ToastMessage.templateAssignSuccess,
			errorMessage: ToastMessage.templateAssignError,
		},
	};
}

function getPresignedUrl(userTemplateId?: string): fetchAction {
	return {
		type: getPresignedUrlTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/userTemplates/getPreSignedUrl?${userTemplateId ? `userTemplateId=${userTemplateId}` : ''}`,
			),
		payload: {
			custom: 'getPresignedUrl',
		},
	};
}

function getPostcardTemplateJson(userTemplateId: string): fetchAction {
	return {
		type: getPostcardTemplateJsonTypes,
		callAPI: () => axios.get(`${apiUrl}/userTemplates/post/${userTemplateId}`),
		payload: {
			custom: 'getPostcardTemplateJson',
		},
	};
}

function getPostImages(): fetchAction {
	return {
		type: getPostImagesTypes,
		callAPI: () => axios.get(`${apiUrl}/postimages`),
		payload: {
			custom: 'getPostImages',
		},
	};
}

function createPostImage(payload: {
	url: string;
	cloudinaryPublicId: string;
}): fetchAction {
	return {
		type: createPostImageTypes,
		callAPI: () => axios.post(`${apiUrl}/postimages`, payload),
		payload: {
			custom: 'createPostImage',
		},
	};
}

function deletePostImage(imageId: string): fetchAction {
	return {
		type: deletePostImageTypes,
		callAPI: () => axios.delete(`${apiUrl}/postimages/${imageId}`),
		payload: {
			custom: 'deletePostImage',
		},
	};
}

function deleteAllPostImages(): fetchAction {
	return {
		type: deleteAllPostImagesTypes,
		callAPI: () => axios.delete(`${apiUrl}/postImages`),
		payload: {
			custom: 'deleteAllPostImages',
		},
	};
}

function resetUserTemplateList() {
	return {
		type: 'userTemplate/RESET_USER_TEMPLATE_LIST',
	};
}

export {
	getUserTemplates,
	getSingleUserTemplate,
	createUserTemplate,
	updateUserTemplate,
	duplicateUserTemplate,
	updateTeamsOnUserTemplate,
	deleteUserTemplate,
	resetUserTemplateList,
	getPresignedUrl,
	getPostcardTemplateJson,
	getPostImages,
	createPostImage,
	deletePostImage,
	deleteAllPostImages,
};
