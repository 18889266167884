import React from 'react';
import { CloudinaryUploadResult } from '@app/types';
import * as S from './CloudinaryUploadWidget.styles';
import isEmptyValue from '@app/utils/isEmptyValue';
import { RequiredAsterisk } from '@common/components/presentation';
import { Button, Icon, ImagePreview, Text, Title, Tooltip } from '../../atoms';
import useCloudinaryUploadWidget from '@app/hooks/useCloudinaryUploadWidget';
import { showToast } from '../Toast/Toast';
import { ButtonProps } from '../../atoms/Button/Button';

type CloudinaryUploadWidgetProps = {
	uploadPreset?: string;
	isRequired?: boolean;
	showSkipCropButton?: boolean;
	clientAllowedFormats?: string[] | null;
	maxImageWidth?: number;
	maxImageHeight?: number;
	multiple?: boolean;
	maxFiles?: number;
	maxFileSize?: number;
	cropping?: boolean;
	croppingAspectRatio?: number;
	sources?: string[] | null;
	minImageWidth?: number;
	minImageHeight?: number;
	croppingShowDimensions?: boolean;
	onUpload: (url: string | null, result?: CloudinaryUploadResult) => void;
	buttonProps?: ButtonProps;
	srcFromParent?: string;
	title?: string;
	subtitle?: string | React.ReactNode;
	withPlaceholder?: boolean;
	placeholderImg?: string;
	showPreview?: boolean;
	tooltipProps?: {
		text: string;
	};
	trigger?: React.ReactNode;
	hidePreview?: boolean;
	hideRemove?: boolean;
};

function CloudinaryUploadWidget({
	uploadPreset,
	isRequired,
	showSkipCropButton,
	clientAllowedFormats,
	maxImageWidth,
	maxImageHeight,
	maxFileSize,
	multiple,
	maxFiles,
	cropping,
	croppingAspectRatio,
	sources,
	minImageWidth,
	minImageHeight,
	onUpload,
	buttonProps,
	srcFromParent,
	croppingShowDimensions,
	title,
	subtitle,
	placeholderImg,
	withPlaceholder = true,
	showPreview = true,
	tooltipProps,
	trigger,
	hidePreview,
	hideRemove = false,
}: CloudinaryUploadWidgetProps) {
	function handlePreviewRemove() {
		onUpload(null);
	}

	const { openWidget: openCloudinaryUploadWidget, cloudinaryWidget } =
		useCloudinaryUploadWidget({
			configs: {
				uploadPreset,
				showSkipCropButton,
				clientAllowedFormats: clientAllowedFormats ?? [
					'webp',
					'png',
					'gif',
					'jpg',
					'jpeg',
				],
				maxFiles,
				multiple,
				maxFileSize: maxFileSize ?? 10000000,
				showPoweredBy: false,
				singleUploadAutoClose: false,
				cropping: cropping ?? true,
				maxImageWidth: maxImageWidth ?? 3000,
				maxImageHeight: maxImageHeight ?? 2000,
				minImageWidth: minImageWidth ?? 1280,
				minImageHeight: minImageHeight ?? 720,
				croppingAspectRatio: croppingAspectRatio ?? 16 / 9,
				// croppingValidateDimensions: true,
				croppingShowDimensions: croppingShowDimensions ?? false,
				secure: true,
				sources: sources ?? [
					'local',
					'unsplash',
					'url',
					'camera',
					'dropbox',
					'image_search',
					'facebook',
					'instagram',
					'google_drive',
				],
			},
			onUpload: (result) => {
				onUpload(result.info.secure_url, result);
			},
			onError: (error) => {
				showToast({
					message: error?.message,
					type: 'error',
				});
			},
		});

	const hasSrcFromParent = !!srcFromParent && !isEmptyValue(srcFromParent);

	return (
		<S.CloudinaryUploadWidgetContainer>
			<S.SubSectionWrapper>
				{trigger && (
					<div
						tabIndex={0}
						onKeyDown={openCloudinaryUploadWidget}
						aria-label="upload"
						role="button"
						onClick={openCloudinaryUploadWidget}
					>
						{trigger}
					</div>
				)}
				{/* If there is a title, make the title a clickable link button */}
				{!!title && (
					<S.TitleWrapper>
						<Title lineHeight="medium">{title}</Title>
						{!!tooltipProps?.text && (
							<Tooltip content={tooltipProps?.text}>
								<span>
									<Icon
										iconName="info"
										size={'small'}
										color="neutral.icon.default"
									/>
								</span>
							</Tooltip>
						)}
						{isRequired && <RequiredAsterisk />}
					</S.TitleWrapper>
				)}

				{!!subtitle && (
					<Text size="small" lineHeight="large" color="system.text.medium">
						{subtitle}
					</Text>
				)}

				{!hidePreview && (!hasSrcFromParent || !showPreview) && (
					<span>
						<Button
							variant="outlined"
							size="small"
							{...buttonProps}
							onClick={() => openCloudinaryUploadWidget()}
						>
							{buttonProps?.text ?? 'Upload image'}
						</Button>
					</span>
				)}
			</S.SubSectionWrapper>

			{showPreview && (
				<S.SubSectionWrapper>
					<ImagePreview
						url={srcFromParent}
						onClose={
							hasSrcFromParent && !hideRemove ? handlePreviewRemove : undefined
						}
						width="100%"
					/>
				</S.SubSectionWrapper>
			)}
		</S.CloudinaryUploadWidgetContainer>
	);
}

export default CloudinaryUploadWidget;
