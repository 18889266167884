import React from 'react';
import { FloatingMenu } from '@common/design-system/components/molecules';
import { Badge } from '@common/design-system/components/atoms';
import { TableMapperType } from '../../types';
import { FloatingMenuPropsType } from '@common/design-system/components/molecules/FloatingMenu/FloatingMenu';

type PropsType = {
	item: any;
	mapperField: TableMapperType;
};

function BadgeFloatingMenuCell({ item, mapperField }: PropsType) {
	const badgeData =
		mapperField?.badge &&
		(typeof mapperField.badge === 'function'
			? mapperField.badge(item)
			: mapperField.badge);

	const badgeIconName =
		typeof badgeData?.icon === 'function'
			? badgeData?.icon(item)
			: badgeData?.icon;

	const badgeVariant =
		typeof badgeData?.variant === 'function'
			? badgeData?.variant(item)
			: badgeData?.variant;

	const badgeText =
		typeof badgeData?.text === 'function'
			? badgeData?.text(item)
			: badgeData?.text;

	const badgeIconWeight =
		typeof badgeData?.iconWeight === 'function'
			? badgeData?.iconWeight(item)
			: badgeData?.iconWeight;

	const menuItemsResult =
		typeof mapperField.menuItems === 'function'
			? mapperField.menuItems(item)
			: mapperField.menuItems;

	const menuItems = menuItemsResult?.map(
		(
			menuItem: FloatingMenuPropsType['menus'][number]['menuItems'][number],
		) => ({
			...menuItem,
			disabled:
				typeof menuItem.disabled === 'function'
					? menuItem.disabled(item)
					: menuItem.disabled,
		}),
	) as FloatingMenuPropsType['menus'][number]['menuItems'];

	return (
		<>
			{badgeVariant && badgeText && (
				<FloatingMenu
					menus={[{ menuItems }]}
					popUpPlacement="bottom-end"
					itemCallbackData={item}
					trigger={
						<Badge
							text={badgeText}
							dropdownBadge
							iconName={badgeIconName}
							iconWeight={badgeIconWeight}
							size={badgeData?.size}
							variant={badgeVariant}
						/>
					}
				/>
			)}
		</>
	);
}

export default BadgeFloatingMenuCell;
