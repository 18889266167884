import { operators } from '@app/constants';

function getStartEndDateFilter(
	field: string,
	dateFilter?: {
		start: string | Date | null;
		end: string | Date | null;
	},
) {
	if (!dateFilter) {
		return [];
	}

	const dateFilterStart = dateFilter?.start
		? new Date(dateFilter?.start)
		: null;
	const dateFilterEnd = dateFilter?.end ? new Date(dateFilter?.end) : null;

	dateFilterStart?.setHours(0);
	dateFilterEnd?.setHours(23, 59, 59, 999);

	return dateFilter?.start || dateFilter?.end
		? [
				...(dateFilter?.start
					? [
							{
								field: field,
								value: `${dateFilterStart}`,
								operator: operators.greaterThan,
							},
						]
					: []),
				...(dateFilter?.end
					? [
							{
								field: field,
								value: `${dateFilterEnd}`,
								operator: operators.lesserThan,
							},
						]
					: []),
			]
		: [];
}

export default getStartEndDateFilter;
