import { pagination } from './constants';
import { normalize } from 'normalizr';
import { ResponseAction } from '../types';
import { contactsStateType } from './@types';
import fileDownload from 'js-file-download';
import { FIRST_PAGE } from '@app/redux/constants';
import { inboxPresenceChannel } from '@app/services/Pusher/usePusher';
import { InboxPresenceEvents } from '@app/services/Pusher/events';
export const paginationSuccessMapper = function paginationMapper(
	state: contactsStateType,
	action: ResponseAction,
): void {
	const {
		response: {
			contacts,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;

	const { infinite } = action.payload;

	const normalizedData = normalize(
		{ contacts, page, pageSize, totalPages, count },
		pagination,
	);

	if (infinite) {
		state.contacts = {
			...(page > 1 ? { ...state.contacts } : {}),
			...normalizedData.entities.contacts,
		};
	} else {
		state.contacts = normalizedData.entities.contacts;
	}

	state.contactsCount = Object.keys(state.contacts)?.length;

	if (state.pagination) {
		state.pagination.pages[action.response.page] =
			normalizedData.result.contacts;
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count = action.response.count;
		state.pagination.totalTabCount = action.response?.totalTabCount || 0;
	}
};

export const complexSuccessMapper = function paginationMapper(
	state: contactsStateType,
	action: ResponseAction,
): void {
	const {
		response: {
			contacts,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
		payload: { audienceId },
	} = action;
	const normalizedData = normalize(
		{ contacts, page, pageSize, totalPages, count },
		pagination,
	);

	const copyContacts = action.response.page > 1 ? { ...state.contacts } : {};
	if (action.payload?.custom && state.custom) {
		state.custom.contacts = {
			...copyContacts,
			...normalizedData.entities.contacts,
		};
		state.custom.pagination = {
			pages: {},
			currentPage: action.response.page,
			totalPages: action.response.totalPages,
			count: action.response.count || 0,
		};
		state.custom.pagination.pages[action.response.page] =
			normalizedData.result.contacts;
	} else {
		if (audienceId) {
			state?.response && (state.response.audienceId = audienceId);
		}
		state.contacts = { ...copyContacts, ...normalizedData.entities.contacts };
		state.contactsCount = Object.keys(state.contacts)?.length;

		if (state.pagination) {
			state.pagination.currentPage = action.response.page;
			state.pagination.totalPages = action.response.totalPages;
			state.pagination.count = action.response.count;
		}
	}
};

export const softDeleteContactsSuccessMapper = function paginationMapper(
	state: contactsStateType,
	action: ResponseAction,
): void {
	const {
		response: { contacts },
		payload,
	} = action;
	const result = payload.contactIds.reduce(
		(objToReturn: any, id: string) => {
			const { [id]: value, ...rest } = objToReturn;
			return { ...rest };
		},
		{ ...state.contacts },
	);
	state.contacts = result;
	state.contactsCount = Object.keys(result)?.length;
	state.update = !state.update;
};

export const uploadFlatfileContactsErrorMapper = function paginationMapper(
	state: contactsStateType,
	action: ResponseAction,
): void {
	state.type = action.type;
};

export const updateContactsSuccessMapper = function (
	state: contactsStateType,
	action: ResponseAction,
) {
	const { payload, response } = action;
	const auxContact = state.contacts[payload.contactId];
	const tagIndex = auxContact.tags.findIndex(
		(tag: any) => tag.id === payload.tagId,
	);
	const newTags = auxContact.tags;
	newTags[tagIndex] = { ...response.tag, edited: true };

	const result = {
		...state.contacts,
		[payload.contactId]: {
			...auxContact,
			tags: newTags,
		},
	};

	state.contacts = result;
	state.update = !state.update;
};

export const deleteContactsTagSucessMaper = function (
	state: contactsStateType,
	action: ResponseAction,
) {
	const { payload } = action;
	const auxContact = state.contacts[payload.contactId];
	const tagIndex = auxContact.tags.findIndex(
		(tag: any) => tag.id === payload.tagId,
	);
	const newTags = auxContact.tags;
	newTags[tagIndex] = { ...newTags[tagIndex], deleted: true };

	const result = {
		...state.contacts,
		[payload.contactId]: {
			...auxContact,
			tags: newTags,
		},
	};

	state.contacts = result;
	state.update = !state.update;
};

export const exportContactsSuccessMapper = (
	state: contactsStateType,
	action: ResponseAction,
) => {
	const { response } = action;
	fileDownload(response, 'contacts.csv');
	state.type = action.type;
};

export const repliesActivityMapper = (
	state: contactsStateType,
	action: ResponseAction,
) => {
	if (action.response.page === 1) {
		state.repliesActivity = action.response.replies;
	} else {
		state.repliesActivity = [
			...state.repliesActivity,
			...action.response.replies,
		];
	}

	state.repliesActivityPagination.currentPage = action.response.page;
	state.repliesActivityPagination.totalPages = action.response.totalPages;
	state.repliesActivityPagination.count = action.response.count;
};

export const storiesActivityMapper = (
	state: contactsStateType,
	action: ResponseAction,
) => {
	if (action.response.page === 1) {
		state.storiesActivity = action.response.stories;
	} else {
		state.storiesActivity = [
			...state.storiesActivity,
			...action.response.stories,
		];
	}

	state.storiesActivityPagination.currentPage = action.response.page;
	state.storiesActivityPagination.totalPages = action.response.totalPages;
	state.storiesActivityPagination.count = action.response.count;
};

export const getSingleContactMapper = (
	state: contactsStateType,
	action: ResponseAction,
) => {
	const { response } = action;

	const newContact = response.contact;
	const contactId = response.contact?.id || response.contact?._id;

	const normalizedData = normalize(
		{ contacts: { ...state.contacts, [contactId]: newContact } },
		pagination,
	);
	state.contacts = { ...normalizedData.entities.contacts };
};

export const getTeamContactsMapper = (
	state: contactsStateType,
	action: ResponseAction,
) => {
	const { response, payload } = action;

	const teamId = payload.teamId;
	const contacts = response.contacts;

	const page = response.page;

	state.teamContacts.team = teamId;

	if (page === FIRST_PAGE) {
		state.teamContacts.contacts = contacts;
	} else {
		state.teamContacts.contacts = [...state.teamContacts.contacts, ...contacts];
	}

	state.teamContacts.pagination.currentPage = page;
	state.teamContacts.pagination.totalPages = response.totalPages;
	state.teamContacts.pagination.count = response.count;
	state.teamContacts.pagination.pageSize = response.pageSize;
};

export const unsubscribeContactMapper = (
	state: contactsStateType,
	action: ResponseAction,
) => {
	const isUnsubscribed = action.response?.contact?.unsubscribed;

	const conversation = {
		...action.payload?.conversation,
		contact: {
			...action.payload?.conversation?.contact,
			unsubscribed: isUnsubscribed,
		},
	};

	inboxPresenceChannel?.trigger(InboxPresenceEvents.OPTED_OUT_CONVERSATION, {
		conversationId: conversation._id,
		unsubscribed: isUnsubscribed,
		conversation: conversation,
	});
};
