import { useState } from 'react';

const useModal = () => {
	const [isOpened, setIsOpened] = useState(false);

	function closeModal() {
		setIsOpened(false);
	}

	function openModal() {
		setIsOpened(true);
	}

	return {
		isOpened,
		closeModal,
		openModal,
	};
};

export default useModal;
