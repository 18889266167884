import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.merge-images-icon {
		width: 44px;
		height: 24px;
	}
	.polotno-container * {
		font-family: ${({ theme }) => theme.fonts.family.default};
	}

	.polotno-side-tabs-container .polotno-side-panel-tab * {
		font-size: ${({ theme }) => theme.fonts.sizes.small};
	}

	.bp5-navbar {
		box-shadow:
			0 0 0 1px ${({ theme }) => theme.colors.system.border.weak},
			0 1px 1px ${({ theme }) => theme.colors.system.border.weak};
	}

	.polotno-side-tabs-container .polotno-side-panel-tab {
		padding: 0px;
		max-width: 100px;
		min-height: 80px;
		padding: 5px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.bp5-icon,
		div:nth-child(2) {
			white-space: normal;
			line-height: ${({ theme }) => theme.fonts.lineHeight.regular};
			font-weight: ${({ theme }) => theme.fonts.weights.regular};
		}

		&.active div:nth-child(2) {
			color: ${({ theme }) => theme.colors.primary.text.strong};
		}
		&.active .bp5-icon svg {
			fill: ${({ theme }) => theme.colors.primary.icon.default};
		}
	}
`;

export const CustomIconWrapper = styled.div<{ isActive: boolean }>`
	.active-icon {
		opacity: ${({ isActive }) => (isActive ? 1 : 0)};
		position: ${({ isActive }) => (isActive ? 'relative' : 'absolute')};
	}

	.inactive-icon {
		opacity: ${({ isActive }) => (isActive ? 0 : 1)};
		position: ${({ isActive }) => (isActive ? 'absolute' : 'relative')};
	}
`;
