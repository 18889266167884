import React from 'react';
import { ModalComponent } from '@common/design-system/components/molecules';
import { ButtonVariantType } from '@common/design-system/components/atoms/Button/Button';

export type PropsType = {
	title: string;
	subtitle?: string;
	isLoading?: boolean;
	selectedIcon?: string;
	confirmButtonText?: string;
	dismissButtonText?: string;
	showConfirm?: boolean;
	showDismiss?: boolean;
	onClose: () => void;
	onConfirmClick?: () => void;
	onDismissClick?: () => void;
	closeOnDismiss?: boolean;
	showCloseButton?: boolean;
	confirmButtonVariant?: ButtonVariantType;
	dismissButtonVariant?: ButtonVariantType;
	dismissButtonIcon?: string;
	confirmButtonIcon?: string;
	children?: React.ReactElement;
	hasSeparators?: boolean;
};

function ConfirmationModal({
	title,
	subtitle,
	isLoading,
	confirmButtonText = 'Yes, Proceed',
	dismissButtonText = 'No, Cancel',
	confirmButtonVariant = 'primary',
	dismissButtonVariant = 'outlined',
	dismissButtonIcon,
	confirmButtonIcon,
	onClose,
	onConfirmClick,
	onDismissClick,
	closeOnDismiss = true,
	showCloseButton = false,
	children,
	hasSeparators,
	showConfirm = true,
	showDismiss = true,
}: PropsType) {
	const handleDismissClick = () => {
		if (onDismissClick) {
			onDismissClick();
		}

		closeOnDismiss && onClose();
	};

	const handleConfirmClick = () => {
		if (onConfirmClick) {
			onConfirmClick();
		}
	};

	return (
		<ModalComponent
			title={title}
			subtitle={subtitle}
			minWidth="500px"
			maxWidth="500px"
			hasSeparators={hasSeparators}
			onClose={showCloseButton ? onClose : undefined}
			buttons={[
				...(showDismiss
					? [
							{
								disabled: isLoading,
								text: dismissButtonText,
								variant: dismissButtonVariant,
								onClick: handleDismissClick,
								icon: dismissButtonIcon,
							},
						]
					: []),
				...(showConfirm
					? [
							{
								disabled: isLoading,
								text: confirmButtonText,
								variant: confirmButtonVariant,
								onClick: handleConfirmClick,
								icon: confirmButtonIcon,
							},
						]
					: []),
			]}
		>
			{children}
		</ModalComponent>
	);
}

export default ConfirmationModal;
