import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, ToastMessage } from '@app/constants/index';
import { fetchAction } from '../types';
import { FilterOptions, saveCampaignCustomName } from '@app/types/index';
import axios from '@global/axios';
import { CampaignDropdownFilter } from '../constants';
import { CampaignType } from './types';
import { PostcardPreviewDataProps } from '@app/components/modals/SendPostcardPreviewModal/SendPostcardPreviewModal';
const mainType = 'campaign';

export const getCampaignTypes = createFetchTypes(`${mainType}_GET_CAMPAIGN`);

export const getCampaignsCountTypes = createFetchTypes(
	`${mainType}_GET_CAMPAIGNS_COUNT`,
);

export const acceptPendingCampaignContactsTypes = createFetchTypes(
	`${mainType}_ACCEPT_PENDING_CAMPAIGN_CONTACTS`,
);

export const filterCampaignsTypes = createFetchTypes(
	`${mainType}_FILTER_CAMPAIGNS`,
);
export const getCampaignOptionsTypes = createFetchTypes(
	`${mainType}_GET_CAMPAIGNS_OPTIONS`,
);

export const deleteCampaignsTypes = createFetchTypes(
	`${mainType}_DELETE_CAMPAIGNS`,
);

export const updateCampaignTypes = createFetchTypes(
	`${mainType}_UPDATE_CAMPAIGNS`,
);

export const launchDraftCampaignTypes = createFetchTypes(
	`${mainType}_LAUNCH_DRAFT_CAMPAIGNS`,
);
export const addContactsToCampaignTypes = createFetchTypes(
	`${mainType}_ADD_CONTACT_TO_CAMPAIGN`,
);
export const deactivateCampaignTypes = createFetchTypes(
	`${mainType}_DEACTIVATE`,
);
export const bulkDeleteDraftCampaignsTypes = createFetchTypes(
	`${mainType}_DELETE_DRAFT_CAMPAIGNS`,
);

export const getVerifiedStorytellersTypes = createFetchTypes(
	`${mainType}_GET_VERIFIED_STORYTELLERS`,
);

export const pinCampaignTypes = createFetchTypes(`${mainType}_PIN_CAMPAIGN`);

function deactivateCampaign(deactivateCampaignObject: {
	campaignId: string;
	deleteAll: boolean;
}): fetchAction {
	return {
		type: deactivateCampaignTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/campaign/deactivate`, deactivateCampaignObject),
		payload: {
			custom: 'deactivateCampaign',
			successMessage: ToastMessage.deactivatedCampaign,
			errorMessage: ToastMessage.error,
		},
	};
}

function getVerifiedStorytellers(campaignId: string): fetchAction {
	return {
		type: getVerifiedStorytellersTypes,
		callAPI: () =>
			axios.get(`${apiUrl}/campaign/storytellers/address/${campaignId}`),
		payload: {
			custom: 'getVerifiedStorytellers',
		},
	};
}

function getCampaign(campaignId: string): fetchAction {
	return {
		type: getCampaignTypes,
		callAPI: () => axios.get(`${apiUrl}/campaign/${campaignId}`),
		payload: {
			custom: 'getCampaign',
		},
	};
}
function acceptPendingContacts(campaignId: string): fetchAction {
	return {
		type: acceptPendingCampaignContactsTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/campaign/approvePendingContacts`, { campaignId }),
		payload: {
			custom: 'acceptPendingContactsCampaign',
			successMessage: ToastMessage.contactsToCampaignAdded,
			errorMessage: ToastMessage.addContactsToCampaignError,
		},
	};
}

function getCampaignsCount() {
	return {
		type: getCampaignsCountTypes,
		callAPI: () => axios.get(`${apiUrl}/campaign/count`),
		payload: { custom: 'getCampaignsCount' },
	};
}

function filterCampaigns(
	FilterOptions: FilterOptions,
	options?: { isFilter: boolean; custom: string },
): fetchAction {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: filterCampaignsTypes,
		payload: { custom: options?.custom, isFilter: options?.isFilter },
		callAPI: () =>
			axios.get(
				`${apiUrl}/campaign${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
	};
}

function getCampaignOptions(
	options: {
		excludeAll?: boolean;
		includeBoth?: boolean;
		filterOptions?: FilterOptions;
	} = {},
): fetchAction {
	return {
		type: getCampaignOptionsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/campaign?filter=${JSON.stringify(
					options.filterOptions
						? options.filterOptions
						: CampaignDropdownFilter,
				)}`,
			),
		payload: {
			excludeAll: options.excludeAll,
			includeBoth: options.includeBoth,
		},
	};
}

function deleteCampaigns(DeleteOptions: string[]): fetchAction {
	const deleteString = JSON.stringify(DeleteOptions);
	return {
		type: deleteCampaignsTypes,
		callAPI: () =>
			axios.delete(
				`${apiUrl}/campaign${DeleteOptions ? `?filter=${deleteString}` : ``}`,
			),
	};
}

function bulkDeleteDraftCampaigns(campaigns: CampaignType[]): fetchAction {
	return {
		type: bulkDeleteDraftCampaignsTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/campaign/bulkDeleteDraft`, {
				campaigns,
			}),
		payload: {
			custom: 'bulkDeleteDraft',
			successMessage: ToastMessage.draftCampaignsDeleted,
		},
	};
}

/**
 * @deprecated This action is deprecated. edit campaign api should be used instead, check updateCampaignV2
 */
function updateCampaign(
	createCampaignObject: { [key: string]: any },
	removeFromCampaignsList?: boolean,
	hideSuccessToast?: boolean,
	customSuccessMessage?: string,
) {
	return {
		type: updateCampaignTypes,
		callAPI: () => axios.put(`${apiUrl}/campaign`, createCampaignObject),
		payload: {
			custom: 'updateCampaign',
			successMessage:
				!hideSuccessToast &&
				(customSuccessMessage
					? customSuccessMessage
					: ToastMessage.campaignUpdated),
			errorMessage: ToastMessage.error,
			removeFromCampaignsList,
		},
	};
}

export const updateCampaignV2Types = createFetchTypes(
	`${mainType}_UPDATE_V2_CAMPAIGNS`,
);

function updateCampaignV2({
	campaignId,
	updateObject,
	removeFromCampaignsList,
	customSuccessMessage,
	isReelsCampaign,
	recorderAssignedTo,
}: {
	campaignId: string;
	updateObject: { [key: string]: any };
	removeFromCampaignsList?: boolean;
	customSuccessMessage?: string;
	isReelsCampaign?: boolean;
	recorderAssignedTo?: {
		fullName: string;
		_id: string;
	};
}) {
	return {
		type: updateCampaignV2Types,
		callAPI: () =>
			axios.patch(`${apiUrl}/campaign/edit/${campaignId}`, updateObject),
		payload: {
			custom: 'updateCampaignV2',
			successMessage: customSuccessMessage
				? customSuccessMessage
				: ToastMessage.campaignUpdated,
			errorMessage: ToastMessage.error,
			removeFromCampaignsList,
			isReelsCampaign,
			recorderAssignedTo,
		},
	};
}

function pinCampaign(campaignId: string, pinned: boolean) {
	return {
		type: pinCampaignTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/campaign/${campaignId}/pin`, { pinned }),
		payload: {
			custom: 'pinCampaign',
			successMessage: pinned
				? ToastMessage.campaignPinned
				: ToastMessage.campaignUnpinned,
			errorMessage: ToastMessage.error,
		},
	};
}

function launchDraftCampaign(createCampaignId: string) {
	return {
		type: launchDraftCampaignTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/launch/${createCampaignId}`),
	};
}

export const setLiveCampaignTypes = createFetchTypes(
	`${mainType}_SET_CAMPAIGN_LIVE`,
);

function setCampaignLive({
	campaignId,
	removeFromCampaignsList,
}: {
	campaignId: string;
	removeFromCampaignsList?: boolean;
}) {
	return {
		type: setLiveCampaignTypes,
		callAPI: () => axios.patch(`${apiUrl}/campaign/live/${campaignId}`),
		payload: {
			custom: 'setCampaignLive',
			removeFromCampaignsList,
			successMessage: ToastMessage.campaignSetLive,
			errorMessage: ToastMessage.error,
		},
	};
}

export const exportCampaignsTypes = createFetchTypes(
	`${mainType}_EXPORT_CAMPAIGNS`,
);

function exportCampaigns(FilterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: exportCampaignsTypes,
		callAPI: () =>
			axios({
				url: `${apiUrl}/campaign/export${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
				method: 'GET',
				responseType: 'blob',
			}),
		payload: {
			custom: 'exportCampaigns',
		},
	};
}

export const exportSingleCampaignTypes = createFetchTypes(
	`${mainType}_EXPORT_SINGLE_CAMPAIGN`,
);
function exportSingleCampaign(campaign: any): fetchAction {
	const paginationObject = {
		page: 1,
		pageSize: 1,
	};

	const populateObject = [
		{ field: 'callToAction', select: [] },
		{
			field: 'storytellers',
			select: [
				'firstName',
				'lastName',
				'fullName',
				'storiesSent',
				'assignedStories',
				'storiesSaved',
			],
		},
		{
			field: 'tags',
			select: ['text', 'color', '_id'],
		},
		{
			field: 'audience',
			select: ['_id', 'name'],
		},
	];

	const filterOject = [{ field: '_id', value: campaign._id }];

	const filterOptions = {
		pagination: paginationObject,
		populate: populateObject,
		filter: filterOject,
	};

	return {
		type: exportSingleCampaignTypes,
		payload: { campaignName: campaign.name, custom: 'exportSingleCampaign' },
		callAPI: () =>
			axios({
				url: `${apiUrl}/campaign/export?filter=${JSON.stringify(
					filterOptions,
				)}`,
				method: 'GET',
				responseType: 'blob',
			}),
	};
}

export const emailPreviewTypes = createFetchTypes(`${mainType}_EMAIL_PREVIEW`);

// This version sends to the logged in storyteller
function sendEmailPreview(
	data: {
		videoId?: string;
		ctaId: string;
		emailSubjectTitle?: string;
		smsBody?: string;
		sendThroughDefault: boolean;
		storytellerId?: string;
		emails?: string;
		phoneNumbers?: string;
	},
	noEmail = false,
): fetchAction {
	return {
		type: emailPreviewTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/stories/preview${noEmail ? '?noEmail=true' : ''}`, {
				...data,
			}),
		payload: {
			custom: 'sendPreview',
			...(noEmail
				? {}
				: {
						successMessage: ToastMessage.campaignPreviewSent,
						errorMessage: '',
					}),
		},
	};
}

// V2 sends only to the provided emails and phone numbers
function sendEmailPreviewVersion2(
	data: {
		videoId?: string;
		ctaId: string;
		emailSubjectTitle?: string;
		smsBody?: string;
		sendThroughDefault: boolean;
		storytellerId?: string;
		emails?: string;
		phoneNumbers?: string;
	},
	noEmail = false,
): fetchAction {
	return {
		type: emailPreviewTypes,
		callAPI: () =>
			axios.post(
				`${apiUrl}/stories/v2/preview${noEmail ? '?noEmail=true' : ''}`,
				{
					...data,
				},
			),
		payload: {
			custom: 'sendPreview',
			...(noEmail
				? {}
				: {
						successMessage: ToastMessage.campaignPreviewSent,
						errorMessage: '',
					}),
		},
	};
}

export const sendPostcardPreviewTypes = createFetchTypes(
	`${mainType}_POSTCARD_PREVIEW`,
);

function sendPostcardPreview(payload: PostcardPreviewDataProps): fetchAction {
	return {
		type: emailPreviewTypes,
		callAPI: () => axios.post(`${apiUrl}/stories/post/preview`, payload),
		payload: {
			custom: 'sendPostcardPreview',
			successMessage: ToastMessage.postcardPreviewSent,
			errorMessage: '',
		},
	};
}
export const resendCampaignStoriesTypes = createFetchTypes(
	`${mainType}_RESEND_CAMPAIGN_STORIES`,
);

function resendCampaignStories(data: {
	storiesToEmailNotOpened: boolean;
	storiesToStoryNotPlayed: boolean;
	storiesToVideoNotOpened: boolean;
	campaignId: string;
}): fetchAction {
	return {
		type: resendCampaignStoriesTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/resend`, data),
	};
}

export const resendStoriesCounttypes = createFetchTypes(
	`${mainType}_RESEND_STORIES_COUNT`,
);

function resendStoriesCount({
	storiesToEmailNotOpened,
	storiesToStoryNotPlayed,
	storiesToVideoNotOpened,
	campaignId,
}: {
	storiesToEmailNotOpened: boolean;
	storiesToStoryNotPlayed: boolean;
	storiesToVideoNotOpened: boolean;
	campaignId: string;
}): fetchAction {
	return {
		type: resendStoriesCounttypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/campaign/resend/count/?campaignId=${campaignId}&storiesToEmailNotOpened=${storiesToEmailNotOpened}&storiesToStoryNotPlayed=${storiesToStoryNotPlayed}&storiesToVideoNotOpened=${storiesToVideoNotOpened}`,
			),
		payload: {
			custom: 'resendStoriesCount',
		},
	};
}

const addContactToCampaign = (
	values: {
		campaign: string;
		contacts: string[];
	},
	errorMessage?: string,
) => {
	return {
		type: addContactsToCampaignTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/contact`, values),
		payload: {
			custom: 'addContactToCampaing',
			successMessage: ToastMessage.contactsToCampaignAdded,
		},
	};
};

export const getEmailTemplatesTypes = createFetchTypes(
	`${mainType}_GET_EMAIL_TEMPLATES`,
);

function getEmailTemplates(): fetchAction {
	return {
		type: getEmailTemplatesTypes,
		callAPI: () => axios.get(`${apiUrl}/workspaces/templates`),
		payload: {
			custom: 'emailTemplates',
		},
	};
}

export const getEmailTemplateHTMLTypes = createFetchTypes(
	`${mainType}_GET_EMAIL_TEMPLATE_HTML`,
);

function getEmailTemplateHTML(emailTemplateId: string): fetchAction {
	return {
		type: getEmailTemplateHTMLTypes,
		callAPI: () => axios.get(`${apiUrl}/templates/email/${emailTemplateId}`),
		payload: {
			custom: 'emailTemplateHtml',
		},
	};
}

export const editEmailTemplateTypes = createFetchTypes(
	`${mainType}_EDIT_EMAIL_TEMPLATE`,
);

function editEmailTemplate(data: {
	emailTemplateId: string;
	html: string;
	designJSON: string;
}): fetchAction {
	const { emailTemplateId, html, designJSON } = data;

	return {
		type: editEmailTemplateTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/templates/email/${emailTemplateId}`, {
				html,
				designJSON,
			}),
		payload: {
			custom: 'editEmailTemplate',
		},
	};
}

function clearDraftResponse() {
	return {
		type: 'CLEAR_DRAFT_RESPONSE',
		payload: {},
	};
}

function resetCampaignState() {
	return {
		type: 'campaign/RESET',
	};
}

export const saveCampaignTypes = createFetchTypes(`${mainType}_SAVE_CAMPAIGN`);

function saveCampaign(
	saveCampaignParams: any,
	customName?: saveCampaignCustomName,
	successMessage?: string,
) {
	return {
		type: saveCampaignTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/save`, saveCampaignParams),
		payload: {
			custom: customName,
			successMessage:
				(customName === 'saveDraft' && ToastMessage.savedDraftCampaign) ||
				successMessage,
		},
	};
}

export const saveCurrentChildCampaignTypes = createFetchTypes(
	`${mainType}_SAVE_CHILD_CAMPAIGN`,
);

export const saveAndUpdateCurrentChildCampaignTypes = createFetchTypes(
	`${mainType}_SAVE_AND_UPDATE_CHILD_CAMPAIGN`,
);

function saveCurrentChildCampaign(
	saveChildCampaignParams: any,
	updateCurrentChild?: boolean,
) {
	return {
		type: updateCurrentChild
			? saveAndUpdateCurrentChildCampaignTypes
			: saveCurrentChildCampaignTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/campaign/save`, saveChildCampaignParams),
		payload: {
			custom: 'saveChild',
			successMessage: '',
			errorMessage: ToastMessage.error,
		},
	};
}

export const launchCampaignTypes = createFetchTypes(
	`${mainType}_LAUNCH_CAMPAIGN`,
);

function launchCampaign(campaignId: string) {
	return {
		type: launchCampaignTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/bulkLaunch/${campaignId}`),
		payload: {
			custom: 'launchCampaign',
			successMessage: ToastMessage.campaignLaunched,
			errorMessage: '',
		},
	};
}

function editCampaign(campaign: any, activeCampaignEditMode?: boolean) {
	return {
		type: 'campaign/EDIT_CAMPAIGN',
		payload: {
			campaign,
			activeCampaignEditMode,
		},
	};
}

export const duplicateCampaignTypes = createFetchTypes(
	`${mainType}_DUPLICATE_CAMPAIGN`,
);

function duplicateCampaign(campaignId: string) {
	return {
		type: duplicateCampaignTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/duplicate/${campaignId}`),
		payload: {
			custom: 'duplicateCampaign',
			errorMessage: ToastMessage.error,
		},
	};
}

function setCampaignTree(campaigns: any[]) {
	return {
		type: 'campaign/SET_CAMPAIGN_TREE',
		payload: campaigns,
	};
}

function setCampaignName(name: string) {
	return {
		type: 'campaign/SET_CAMPAIGN_NAME',
		payload: name,
	};
}

export const getHotglueJobTypes = createFetchTypes(
	`${mainType}_GET_HOTGLUE_JOB`,
);

function getHotglueJob(jobId: string) {
	return {
		type: getHotglueJobTypes,
		callAPI: () => axios.get(`${apiUrl}/jobs/${jobId}`),
		payload: {
			custom: 'hotglueJob',
		},
	};
}

export const getCampaignPerCTAType = createFetchTypes(
	`${mainType}_GET_CAMPAIGN_PER_CTA`,
);

function getCampaignsPerCTA(ctaId: string) {
	return {
		type: getCampaignPerCTAType,
		callAPI: () => axios.get(`${apiUrl}/campaign/cta/${ctaId}`),
		payload: {
			custom: 'getCampaignPerCTA',
		},
	};
}

export const getCTAsInCampaignsTypes = createFetchTypes(
	`${mainType}_GET_CTAS_IN_CAMPAIGNS`,
);
/** Gets the CTAs that are being used in active campaigns*/
function getCTAsInCampaigns(filterOptions: FilterOptions) {
	const ctaFilter = JSON.stringify(filterOptions);
	return {
		type: getCTAsInCampaignsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/campaign/ctas${ctaFilter ? `?filter=${ctaFilter}` : ''}`,
			),
		payload: {
			custom: 'getCTAsInCampaigns',
		},
	};
}

function resetActiveCTAs() {
	return {
		type: 'campaign/RESET_ACTIVE_CTAS',
	};
}

export const approvePendingStoriesTypes = createFetchTypes(
	`${mainType}_APPROVE_PENDING_STORIES`,
);

function approvePendingStories(
	stories: string[],
	campaignToUpdate?: any,
	campaignReportsPage?: boolean,
) {
	return {
		type: approvePendingStoriesTypes,
		callAPI: () => axios.patch(`${apiUrl}/stories/bulk/approval`, { stories }),
		payload: {
			custom: 'approvePendingStories',
			stories,
			successMessage: ToastMessage.success,
			errorMessage: ToastMessage.error,
			campaignToUpdate,
			campaignReportsPage,
		},
	};
}
export const rejectPendingStoriesTypes = createFetchTypes(
	`${mainType}_REJECT_PENDING_STORIES`,
);
function RejectPendingStories(
	stories: string[],
	rejectionReason: string,
	campaignToUpdate?: any,
	campaignReportsPage?: boolean,
) {
	return {
		type: rejectPendingStoriesTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/stories/bulk/reject`, {
				stories,
				rejectionReason,
			}),
		payload: {
			custom: 'rejectPendingStories',
			campaignToUpdate,
			campaignReportsPage,
			successMessage: ToastMessage.success,
			errorMessage: ToastMessage.error,
		},
	};
}

export {
	getCampaign,
	getCampaignsCount,
	filterCampaigns,
	deleteCampaigns,
	updateCampaign,
	updateCampaignV2,
	launchDraftCampaign,
	exportCampaigns,
	exportSingleCampaign,
	addContactToCampaign,
	clearDraftResponse,
	sendEmailPreview,
	sendEmailPreviewVersion2,
	sendPostcardPreview,
	getEmailTemplates,
	resendStoriesCount,
	resendCampaignStories,
	resetCampaignState,
	getCampaignOptions,
	deactivateCampaign,
	setCampaignName,
	//Campaign creation services
	saveCampaign,
	launchCampaign,
	editCampaign,
	setCampaignTree,
	saveCurrentChildCampaign,
	duplicateCampaign,
	bulkDeleteDraftCampaigns,
	getVerifiedStorytellers,
	pinCampaign,
	getEmailTemplateHTML,
	editEmailTemplate,
	getHotglueJob,
	getCampaignsPerCTA,
	getCTAsInCampaigns,
	resetActiveCTAs,
	acceptPendingContacts,
	approvePendingStories,
	setCampaignLive,
	RejectPendingStories,
};
