import { AvailableMergeTags } from '@app/constants/mergeTags';
import { Button } from '@common/design-system/components/atoms';
import { addCustomAttributesToMergeTagOptions } from '@common/design-system/components/atoms/TagsInput/utils';
import { FloatingMenu } from '@common/design-system/components/molecules';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useCreativeEditorContext } from '../../providers/CreativeEditorProvider';

const TextMergeTags = observer(({ store, element, elements }: any) => {
	const { workspaceInfo } = useCreativeEditorContext();

	const mergeTagsOptions = useMemo(() => {
		if (!workspaceInfo) return [];

		const tags = addCustomAttributesToMergeTagOptions(
			AvailableMergeTags.action,
			workspaceInfo,
		);

		return tags.map((tag) => ({
			value: tag.value,
			text: tag?.label,

			onClick: (item?: any, list?: any) => {
				const activeElement = document.activeElement as HTMLTextAreaElement;
				const selectionStart = activeElement.selectionStart;
				const hasSelectionStart =
					selectionStart !== undefined && !isNaN(selectionStart);

				const isElementATextElement = element?.type === 'text';
				const isActiveElementAPolotnoInput =
					activeElement?.classList.contains('polotno-input') &&
					activeElement?.nodeName === 'TEXTAREA';

				if (!activeElement || !element) return;

				// Sometimes the text element is selected, but due to the absence of the cursor position, the activeElement is not a polotno input
				// In this case, we need to check if the element is a text element
				if (
					!hasSelectionStart &&
					!isActiveElementAPolotnoInput &&
					isElementATextElement
				) {
					const newText = `${element.text || ''} [${tag.value}]`;
					element.set({
						text: newText,
					});
					return;
				}

				// When the text element has a cursor position
				const text = activeElement.value || '';
				const newText = `${text.slice(0, selectionStart).trim()} [${tag.value}] ${text.slice(selectionStart).trim()}`;
				activeElement.value = newText;
				element.set({
					text: newText,
				});

				// Return cursor to the position after the merge tag
				// If selectionStart is 0, set it to 1
				activeElement.selectionStart =
					(selectionStart || 1) + tag.value.length + 2;
				activeElement.selectionEnd =
					(selectionStart || 1) + tag.value.length + 2;
			},
		}));
	}, [workspaceInfo, store, element, elements]);

	return (
		<>
			<FloatingMenu
				disabled={elements.length === 0 || elements.length > 1}
				preventFocusTrap
				popUpPlacement="bottom-start"
				maxWidth="240px"
				menus={[
					{
						menuItems: mergeTagsOptions,
					},
				]}
				trigger={
					<Button
						icon="plus"
						variant="tertiary"
						size="xs"
						disabled={elements.length === 0 || elements.length > 1}
					>
						Merge tags
					</Button>
				}
			/>
		</>
	);
});

export default TextMergeTags;
