import React, { ReactNode, useEffect, useMemo, useRef } from 'react';

import { Col } from 'react-bootstrap';

import { checkHasModalHeader } from './utils';

import * as S from './ModalComponent.styles';
import { Button, Icon, LinkButton, Text, Title } from '../../atoms';
import SquareIconButton from '../../atoms/SquareIconButton/SquareIconButton';
import { createPortal } from 'react-dom';
import { ButtonProps } from '../../atoms/Button/Button';
import SplitButton, { SplitButtonPropsType } from '../SplitButton/SplitButton';
import useDisableScrollOnDocument from '@app/hooks/useDisableScrollOnDocument';

type HeaderLinkType = {
	text: string;
	url: string;
	openInNewTab?: boolean;
};

type ButtonsAssertedType =
	| (ButtonProps & {
			buttonType?: 'button' | 'splitButton';
	  })
	| (SplitButtonPropsType & {
			buttonType?: 'button' | 'splitButton';
	  });

export type ModalComponentProps = {
	children?: ReactNode;
	title?: string;
	minWidth?: string;
	maxWidth?: string;
	maxHeight?: string;
	minHeight?: string;
	buttons?: ButtonsAssertedType[];
	subtitle?: string;
	description?: string;
	descriptionIcon?: string;
	headerLink?: HeaderLinkType;
	onTitleClick?: () => void;
	backButton?: {
		text?: string;
		disabled?: boolean;
		onClick: () => void;
	};
	customCloseIconName?: string;
	hasSeparators?: boolean;
	onBlur?: () => void;
	onClose?: () => void;
	contentDivId?: string;
	contentDivRef?: React.RefObject<HTMLDivElement>;
	hasContentPadding?: boolean;
	customFooterElement?: ReactNode;
	splitModalElement?: ReactNode;
	smallFooter?: boolean;
	className?: string;
};

export default function ModalComponent({
	children,
	title,
	minWidth,
	maxWidth,
	maxHeight,
	minHeight,
	buttons,
	subtitle,
	headerLink,
	onTitleClick,
	backButton,
	customCloseIconName,
	onBlur,
	onClose,
	hasSeparators = true,
	contentDivId,
	contentDivRef,
	hasContentPadding = true,
	customFooterElement,
	splitModalElement,
	smallFooter,
	className,
	description,
	descriptionIcon,
}: ModalComponentProps) {
	const modalRef = useRef<HTMLDivElement | null>(null);
	const closeIconRef = useRef<HTMLDivElement | null>(null);
	const backButtonRef = useRef<HTMLDivElement | null>(null);

	const isShowingFooter =
		(buttons && buttons?.length > 0) ||
		!!backButton?.onClick ||
		!!customFooterElement;

	const buttonsToRender = useMemo(() => {
		if (!buttons) {
			return [];
		}

		return buttons.map((button) => {
			return {
				...button,
				buttonType: button.buttonType || ('button' as const),
			};
		});
	}, [buttons]);

	const ButtonComponent = ({ button }: { button: ButtonsAssertedType }) => {
		if (button.buttonType === 'splitButton') {
			const buttonAssert = button as SplitButtonPropsType;
			return (
				<SplitButton
					buttonProps={buttonAssert.buttonProps}
					menuItems={buttonAssert.menuItems}
					popUpPlacement={buttonAssert.popUpPlacement}
					arrowDirection={buttonAssert.arrowDirection}
				/>
			);
		}

		if (button.buttonType === 'button') {
			const buttonAssert = button as ButtonProps;

			return (
				<Button
					variant={buttonAssert.variant}
					disabled={buttonAssert.disabled}
					onClick={buttonAssert.onClick}
					size={buttonAssert.size || 'medium'}
					icon={buttonAssert.icon}
					iconButton={buttonAssert.iconButton}
				>
					{buttonAssert.text}
				</Button>
			);
		}

		return null;
	};

	const handleKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			onClose && onClose();
		}

		if (event.key === 'Enter' && event.target === closeIconRef?.current) {
			onClose && onClose();
		}

		if (event.key === 'Enter' && event.target === backButtonRef?.current) {
			backButton?.onClick();
		}
	};

	// Accessibility
	useEffect(() => {
		// Add event listeners to document, close icon and back icon
		document.addEventListener('keydown', handleKeyPress);
		closeIconRef?.current?.addEventListener('keydown', handleKeyPress);
		backButtonRef?.current?.addEventListener('keydown', handleKeyPress);

		// Set focus on the first button
		const allButtons = modalRef?.current?.querySelectorAll('button');

		if (allButtons && allButtons.length > 0) {
			const lastButton = Array.from(allButtons)[allButtons.length - 1];
			lastButton?.focus();
		} else {
			closeIconRef?.current?.focus();
		}

		// Set focus on the modal container
		const modalContainer = modalRef?.current;

		modalContainer?.setAttribute('tabindex', '0');

		// TODO: Restrict keyboard tabbing to the modal

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
			closeIconRef?.current?.removeEventListener('keydown', handleKeyPress);
			backButtonRef?.current?.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	// Disable scrolling on the document
	useDisableScrollOnDocument();

	return createPortal(
		<S.ModalScreenCover className={className}>
			<S.ModalScreenBackground tabIndex={-1} onClick={onBlur} />
			<S.ModalContainer
				ref={modalRef}
				role="dialog"
				minWidth={minWidth || '500px'}
				maxWidth={maxWidth || '800px'}
				maxHeight={maxHeight || '90vh'}
				minHeight={minHeight}
				isShowingFooter={isShowingFooter}
				smallFooter={smallFooter}
				aria-hidden={false}
				aria-modal={true}
				aria-label={title}
				aria-describedby="modalDescription"
				className="modal-component-content"
			>
				{splitModalElement && <>{splitModalElement}</>}

				<div className="w-100 d-flex flex-column flex-grow-1">
					{checkHasModalHeader(!!title, onClose) && (
						<S.ModalHeader
							$modalHasContent={!!children}
							$hasBorder={hasSeparators}
						>
							{!!title && (
								<S.ModalTitleContainer $modalHasContent={!!children}>
									{!onTitleClick && (
										<Title as="h1" size="large" lineHeight="medium">
											{title}
										</Title>
									)}

									{onTitleClick && (
										<LinkButton
											weight="regular"
											color="primary.text.hover"
											lineHeight="large"
											onClick={onTitleClick}
										>
											<Title
												as="h1"
												size="large"
												lineHeight="medium"
												color="primary.text.default"
												className="header-title-link"
											>
												{title}
											</Title>
										</LinkButton>
									)}

									{subtitle && (
										<Text
											size="small"
											lineHeight="large"
											color="system.text.medium"
										>
											{subtitle}
										</Text>
									)}

									{description && (
										<S.DescriptionContainer>
											{descriptionIcon && (
												<Icon
													iconName={descriptionIcon}
													color={'neutral.icon.default'}
												/>
											)}
											<Text
												size="small"
												lineHeight="large"
												color="system.text.medium"
											>
												{description}
											</Text>
										</S.DescriptionContainer>
									)}

									{headerLink && (
										<LinkButton
											weight="regular"
											color="primary.text.hover"
											lineHeight="large"
											href={headerLink.url}
											openInNewTab={headerLink?.openInNewTab}
										>
											{headerLink.text}
										</LinkButton>
									)}
								</S.ModalTitleContainer>
							)}

							{!!onClose && (
								<S.CloseIconContainer>
									<SquareIconButton
										ariaLabel="close"
										icon={customCloseIconName || 'close'}
										variant="neutral"
										size="xs"
										onClick={onClose}
										tabIndex={0}
										ref={closeIconRef}
									/>
								</S.CloseIconContainer>
							)}
						</S.ModalHeader>
					)}

					{children && (
						<S.ModalContent
							ref={contentDivRef}
							id={contentDivId}
							$hasContentPadding={hasContentPadding}
						>
							{children}
						</S.ModalContent>
					)}

					{isShowingFooter && (
						<S.ModalFooter
							$hasBorder={hasSeparators}
							$hasBackButton={!!backButton?.onClick}
							$hasCustomElement={!!customFooterElement}
							$smallFooter={smallFooter}
						>
							<Col>
								{!!backButton?.onClick && (
									<div className="back-button-container">
										<Button
											variant="ghost"
											disabled={backButton.disabled}
											onClick={backButton.onClick}
											size="large"
											icon="arrowLeft"
										>
											{backButton?.text || 'Back'}
										</Button>
									</div>
								)}
							</Col>

							<Col>
								<div className="mx-auto">
									{!!customFooterElement && customFooterElement}
								</div>
							</Col>

							<Col lg={!customFooterElement && !backButton ? 12 : 4}>
								<div className="buttons-container">
									{buttonsToRender?.map((button, index) => (
										<ButtonComponent key={index} button={button} />
									))}
								</div>
							</Col>
						</S.ModalFooter>
					)}
				</div>
			</S.ModalContainer>
		</S.ModalScreenCover>,
		document.body,
	);
}
