export enum DuplicateCampaignConfirmationModalText {
	TITLE = 'Do you want to duplicate this campaign?',
	DESCRIPTION = 'You will create a new draft campaign based on this campaign. Parts of your campaign such as audience and delivery channel configuration will not be be copied over.',
	DESCRIPTION_POST = 'You will create a new draft [campaignType] campaign based on this one. A part of your campaign such as the audience will not be copied over.',
}

export enum videoViewerEnum {
	VIDEO_PAGE = 'videoPage',
	VIDEO_PAGE_REELS = 'reels',
	WIDGET = 'widget',
}

export enum widgetPlacementFields {
	WIDGET_DOMAIN = 'widgetDomain',
	WIDGET_PATH = 'widgetPath',
	WIDGET_HIDE_ON_MOBILE = 'widgetHideOnMobile',
}

export enum campaignTemplateTypeEnum {
	sms = 'sms',
	email = 'email',
	whatsapp = 'whatsapp',
}

export const campaignTemplateName = {
	sms: 'sharedVideoSms',
	emailSubject: 'sharedVideoEmail',
	resendEmail: 'resendStoryEmail',
	whatsapp: 'sharedVideoWhatsapp',
	postcard: 'postcard',
};

export enum CampaignPersonalizationEnum {
	oneToOne = 'oneToOne',
	oneToAll = 'oneToAll',
	oneToWorld = 'oneToWorld',
	collectVideos = 'collectVideos',
	reels = 'reels',
	SMS = 'sms',
	post = 'post',
}

export enum CampaignTypeDisplayName {
	oneToOne = 'One to one',
	oneToAll = 'One to many',
	oneToWorld = 'Website',
	collectVideos = 'Collect videos',
	reels = 'Reels',
	SMS = 'Texting campaign',
	post = 'Post',
	postcard = 'Postcard',
	scanToWatch = 'Scan to watch',
	whatsapp = 'Whatsapp campaign',
	SmsMessage = 'Text message',
	whatsappMessage = 'Whatsapp message',
	VideoMessage = 'Video Message',
}

export const descriptionPreTexts = {
	post: 'Your Post campaign is processing and will start printing on the date selected.',
	collectVideos:
		'The videos will be available in your library as soon as they are recorded and uploaded.',
	oneToOne:
		'Your video messages will be delivered as soon as they are recorded and uploaded.',
	oneToManyVideoFromLibrary: 'Your video messages are being delivered.',
	oneToManyAssignNewVideo:
		'Your video message will be delivered as soon as it is recorded and uploaded.',
	oneToOneScheduled:
		'Once they are recorded and uploaded, your video messages will be delivered on',
	oneToManyVideoFromLibraryScheduled: 'Your video message will be delivered on',
	oneToManyAssignNewVideoScheduled:
		'After it is recorded and uploaded, your video message will be delivered on',
	reels: 'What we should put on reels launch description??',
	reelsScheduled:
		'What we should put on reels launch description when scheduled??',
};

export enum AudienceHandlingTypeEnum {
	ONE_TIME_SYNC = 'oneTimeSync',
	ADDITIVE = 'additive',
	MAPPED = 'mapped',
}

export enum DeliveryChannelEnum {
	SMS = 'sms',
	EMAIL = 'email',
	ALL = 'all',
}

export enum CampaignActionRequired {
	WAITING_VIDEO_SUBMISSIONS = 'waitingVideoSubmissions',
	APPROVE_VIDEOS = 'approveVideos',
	APPROVE_NEW_CONTACTS = 'approveNewContacts',
	SET_AS_RUNNING = 'setAsRunning',
}
