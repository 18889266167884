import { ToastMessage } from '@app/constants';
import { env } from '@app/env';
import { BadgePropsType } from '@common/design-system/components/atoms/Badge/Badge';
import { ActionCardProps } from '@common/design-system/components/organisms/ActionCard/ActionCard';

export const extractURL = (source: string, withProtocol: boolean): string => {
	const regex = /\((https?:\/\/[^\s]+)\)/;
	const match = source.match(regex);
	// Check if a match is found
	if (match && match[1]) {
		const parsedURL = new URL(match[1]);

		// Construct URL with protocol but without fragment
		const cleanURL = `${parsedURL.protocol}//${parsedURL.hostname}${
			parsedURL.pathname
		}${parsedURL.search || ''}`;

		// Return the desired result based on the withProtocol flag, removing trailing slash
		const result = withProtocol
			? cleanURL
			: `${parsedURL.hostname.replace(/^www\./, '')}${parsedURL.pathname}${
					parsedURL.search || ''
				}`;

		return result.replace(/\/$/, ''); // Remove trailing slash
	}

	// If no match is found, return the original source without fragment and trailing slash
	if (source.includes('https') || source.includes('http')) {
		const originalParsedURL = new URL(source);
		const originalUrlWithoutFragment = `${originalParsedURL?.hostname.replace(
			/^www\./,
			'',
		)}${originalParsedURL.pathname}${originalParsedURL.search || ''}`;

		return withProtocol
			? originalParsedURL.href.replace(/\/$/, '') // Remove trailing slash
			: originalUrlWithoutFragment.replace(/\/$/, '') ||
					source.replace(/\/$/, ''); // Remove trailing slash
	}
	return source;
};

export const getSourceDetails = (source: string) => {
	const textRegex = /\[([^\]]+)\]/; // Regular expression to match text inside square brackets
	const match = source.match(textRegex);

	// Check if there is a match and return the text inside brackets
	if (match && match.length > 1) {
		return match[1];
	}
};

export const checkIfUrlsAreEqual = (
	firstUrl: string,
	secondUrl: string,
): boolean => {
	// Remove trailing slashes and normalize URLs
	const normalizedFirstUrl = firstUrl.replace(/\/$/, '');
	const normalizedSecondUrl = secondUrl.replace(/\/$/, '');
	// Compare the normalized URLs for equality
	return normalizedFirstUrl === normalizedSecondUrl;
};

export const formatWithSign = (value: any) => {
	const numericValue = parseFloat(value);
	if (numericValue < 0) {
		return `${Math.floor(numericValue)}`;
	} else {
		return `+${Math.floor(numericValue)}`;
	}
};

export const checkIfValueIsNegative = (value: any) => {
	const numericValue = parseFloat(value);
	return numericValue < 0;
};

export function getChatbotTestUrl(
	aiBotId: string,
	aiBotApiKey: string,
): string {
	const isLocalDBConnection = env.API_URL?.includes('localhost');
	let domainWProtocol = 'https://widget.goodkind.com/bot-demos/';
	let demoPageName = '';

	if (isLocalDBConnection) {
		domainWProtocol = 'http://localhost:3000';
	}

	if (!isLocalDBConnection && env.NODE_ENV === 'prod') {
		demoPageName = 'prod.html';
	}

	if (!isLocalDBConnection && env.NODE_ENV === 'qa') {
		demoPageName = 'qa.html';
	}

	if (!isLocalDBConnection && demoPageName === '') {
		demoPageName = 'dev.html';
	}

	return `${domainWProtocol}${demoPageName}?id=${aiBotId}&apiKey=${aiBotApiKey}`;
}

export function getTopicsBadges(
	aiMessageTopics: string[],
	topics: any[],
	subTopics: any[],
) {
	const topicsBadges: {
		text: string;
		variant?: BadgePropsType['variant'];
	}[] = [];

	// backend enforces subtopics to appear first in topic list
	// so we need to reverse the order to display them correctly
	aiMessageTopics
		.slice()
		.reverse()
		.forEach((topicId) => {
			// Find in topics
			const topic = topics.find((t) => t.id === topicId);
			if (topic) {
				topicsBadges.push({
					text: topic.name,
					variant: 'info',
				});
			} else {
				// Find in subTopics if not found in topics
				const subTopic = subTopics.find((st) => st.id === topicId);
				if (subTopic) {
					topicsBadges.push({
						text: subTopic.name,
						variant: 'outline',
					});
				}
			}
		});

	return topicsBadges;
}

export function getSuggestionToDeleteActionInnerCardOptions(
	id: string | null,
	dismissSuggestedAnswer: (id: string) => void,
	useSuggestedUpdateAnswer: (id: string, successMessage: ToastMessage) => void,
	showButtons = true,
): ActionCardProps {
	return {
		title: 'Suggestion to delete',
		titleFontWeight: 'regular',
		titleIcon: 'warning',
		titleIconVariant: 'warning',
		titleIconWeight: 'regular',
		variant: 'warning',
		text: 'We recommend deleting this answer as the source is no longer accurate or has been removed from the website.',
		...(showButtons && {
			buttons: [
				{
					text: 'Dismiss',
					variant: 'tertiary',
					onClick: () => id && dismissSuggestedAnswer(id),
				},
				{
					text: 'Delete Response',
					variant: 'dangerWeak',
					onClick: () =>
						id && useSuggestedUpdateAnswer(id, ToastMessage.deleteAiDocSuccess),
				},
			],
		}),
	};
}

export function getSuggestedAnswerDismissOrUseInnerCardOptions(
	id: string | null,
	dismissSuggestedAnswer: (id: string) => void,
	useSuggestedUpdateAnswer: (id: string, successMessage: ToastMessage) => void,
	suggestedUpdateAnswer: string,
	suggestedUpdateSources: string[],
	showButtons = true,
): ActionCardProps {
	return {
		title: 'Response suggestion',
		titleIcon: 'sparkle',
		titleFontWeight: 'regular',
		text: suggestedUpdateAnswer,
		footerInfo: {
			textIcon:
				suggestedUpdateSources && suggestedUpdateSources.length > 0
					? 'link'
					: undefined,
			text:
				suggestedUpdateSources &&
				suggestedUpdateSources.length > 0 &&
				`Source: ${extractURL(suggestedUpdateSources[0], false)}`,
		},
		...(showButtons && {
			buttons: [
				{
					text: 'Dismiss',
					variant: 'tertiary',
					onClick: () => id && dismissSuggestedAnswer(id),
				},
				{
					text: 'Use Suggestion',
					onClick: () =>
						id && useSuggestedUpdateAnswer(id, ToastMessage.answerUpdated),
				},
			],
		}),
	};
}

export function topicTitleCase(topicName: string): string {
	// We want to keep this capitalized mainly because Bates college asked for it
	//https://goodkindhq.slack.com/archives/C05DMQ5ALTE/p1732204273344779
	// title case: small words "of" should not be capitalized
	// Words seperated by slashes should both be capitalized (e.g. "Pricing/Tuition")
	const smallWords = [
		'of',
		'and',
		'or',
		'for',
		'the',
		'a',
		'an',
		'in',
		'on',
		'at',
		'to',
		'with',
		'by',
		'as',
		'but',
		'nor',
		'yet',
		'so',
	];
	const words = topicName.split(' ');
	const titleCasedWords = words.map((word) => {
		if (word.includes('/')) {
			return word
				.split('/')
				.map((w) => {
					return w.charAt(0).toUpperCase() + w.slice(1).toLowerCase();
				})
				.join('/');
		}
		if (smallWords.includes(word.toLowerCase())) {
			return word.toLowerCase();
		}
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	});
	return titleCasedWords.join(' ');
}
