export enum campaignScreens {
	setup = 'setup',
	video = 'video',
	build = 'build',
	delivery = 'delivery',
	postcard = 'postcard',
	summary = 'summary',
	launched = 'launched',
}

export enum deliveryScreensEnum {
	delivery = 'delivery',
	sms = 'sms',
	email = 'email',
}

export enum buildScreensEnum {
	'video-page' = 'video-page',
	'widget' = 'widget',
}

export enum SummaryRouteEnum {
	COMPLETED = 'completed',
}

export type stepperCampaignType =
	| 'oneToOne'
	| 'oneToAll'
	| 'oneToAllNewStory'
	| 'oneToWorld'
	| 'oneToWorldNewStory'
	| 'collectVideos'
	| 'collectVideosSkipTemplates';

export enum campaignTypeEnum {
	oneToOne = 'oneToOne',
	oneToAll = 'oneToAll',
	oneToAllNewStory = 'oneToAllNewStory',
	oneToWorld = 'oneToWorld',
	oneToWorldNewStory = 'oneToWorldNewStory',
	collectVideos = 'collectVideos',
	reels = 'reels',
	post = 'post',
	postNoVideo = 'postNoVideo',
}

export enum PersonalizationEnum {
	oneToOne = 'oneToOne',
	oneToAll = 'oneToAll',
	oneToWorld = 'oneToWorld',
	collectVideos = 'collectVideos',
	reels = 'reels',
	SMS = 'sms',
	post = 'post',
}

export enum CreationMethod {
	createToDos = 'createToDos',
	videoFromLibrary = 'videoFromLibrary',
	collectVideos = 'collectVideos',
	noVideo = 'noVideo',
	mixed = 'mixed',
}

export type campaignCreationType = {
	personalization: PersonalizationEnum | null;
	creationMethod: CreationMethod | null;
};

/** Here is where the campaigns are created from the dropdown */

export const oneToOneCampaign: campaignCreationType = {
	personalization: PersonalizationEnum.oneToOne,
	creationMethod: CreationMethod.createToDos,
};

export const oneToManyCampaign: campaignCreationType = {
	personalization: PersonalizationEnum.oneToAll,
	creationMethod: CreationMethod.createToDos,
};

export const collectVideosCampaign: campaignCreationType = {
	personalization: null,
	creationMethod: CreationMethod.collectVideos,
};

export const reelsCampaign: campaignCreationType = {
	personalization: PersonalizationEnum.reels,
	creationMethod: CreationMethod.mixed,
};

export const scanToWatchCampaign: campaignCreationType = {
	personalization: PersonalizationEnum.post,
	creationMethod: CreationMethod.videoFromLibrary,
};

export const postcardCampaign: campaignCreationType = {
	personalization: PersonalizationEnum.post,
	creationMethod: CreationMethod.noVideo,
};

/** All the one to many campaigns*/

export const OneToManyCampaignTypes: string[] = [
	campaignTypeEnum.oneToAll,
	campaignTypeEnum.oneToAllNewStory,
];

export const OneToOneCampaignTypes: string[] = [campaignTypeEnum.oneToOne];

export const uploadAudienceHelpArticle =
	'https://intercom.help/goodkind/en/articles/5164336-uploading-contacts-with-a-file-into-goodkind';

export enum campaignResponseMessage {
	duplicatedName = 'One or several campaigns with this name already exist.',
	updatedSuccessfully = 'Campaign updated successfully',
}

export const UntitledCampaignName = 'Untitled Campaign';

export enum HotglueJobStatusEnum {
	STARTED = 'started',
	STOPPED = 'stopped',
	QUEUED = 'queued',
	COMPLETED = 'completed',
	CANCELLED = 'cancelled',
	PENDING_APPROVAL = 'pendingApproval',
	FAILED = 'failed',
	IN_PROGRESS = 'inProgress',
}

export const SelectDateAndTimeText =
	'Select a date and time to view it in your timezone.';
