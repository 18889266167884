import { Button } from '@common/design-system/components/atoms';
import React from 'react';
import { TableMapperType } from '../../types';
import { FloatingMenu } from '@common/design-system/components/molecules';

type PropsType = {
	item: any;
	mapperField: TableMapperType;
};

function IconButtonCell({ item, mapperField }: PropsType) {
	const iconButtonMapperField = mapperField.iconButton;

	const menuItems =
		typeof iconButtonMapperField?.menuItems === 'function'
			? iconButtonMapperField?.menuItems(item)
			: iconButtonMapperField?.menuItems;

	if (!menuItems) {
		return (
			<Button
				variant={iconButtonMapperField?.variant || 'outlined'}
				iconButton
				disabled={iconButtonMapperField?.disabled}
				size={iconButtonMapperField?.size || 'medium'}
				icon={iconButtonMapperField?.iconName || 'cube'}
				onClick={() => {
					if (!!iconButtonMapperField?.onIconButtonClick) {
						iconButtonMapperField?.onIconButtonClick(item);
					}
				}}
			/>
		);
	}

	return (
		<FloatingMenu
			menus={menuItems ? [{ menuItems }] : []}
			closeOnSelect
			disabled={iconButtonMapperField?.disabled}
			trigger={
				<Button
					variant={iconButtonMapperField?.variant || 'outlined'}
					iconButton
					disabled={iconButtonMapperField?.disabled}
					size={iconButtonMapperField?.size || 'medium'}
					icon={iconButtonMapperField?.iconName || 'cube'}
					onClick={() => {
						if (iconButtonMapperField?.onIconButtonClick) {
							iconButtonMapperField?.onIconButtonClick(item);
						}
					}}
				/>
			}
			itemCallbackData={item}
		/>
	);
}

export default IconButtonCell;
